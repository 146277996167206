/* eslint-disable react/jsx-key */
import React from 'react';
import { IValuation } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

interface Props {
  list: IValuation[];
  loading: boolean;
}

function ValuationTable({ list, loading }: Props) {
  const { t } = useTranslation();

  const columns: Column<IValuation>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Data',
        accessor: 'date',
      },
      {
        Header: 'Date',
        accessor: 'data',
      },
    ],
    [],
  );
  const initialState = {
    sortBy: [{ id: 'date', desc: false }],
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: list,
      initialState,
    },
    useSortBy,
  );
  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!list || list.length === 0) {
    return <p className="no-data">{t('daily:report.noData')}</p>;
  }

  return (
    <div className="valuation-table">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<IValuation>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={cx({ selected: column.isSorted })}
                >
                  <div className="th-wrapper">
                    <div className="header-name">{column.render('Header')}</div>
                    {column.isSorted ? (
                      <div className="arrow">
                        <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<IValuation>) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<IValuation>) => (
                  <td {...cell.getCellProps()} className={cell.column.isSorted ? 'sorted' : ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ValuationTable;
