import { createSlice } from '@reduxjs/toolkit';

/** **** INTERFACES */

export interface ISendEmailState {
  sent: boolean;
  isLoading: boolean;
  error: {
    detail: string;
    message: string;
  } | null;
}

/** **** INITIAL STATE */

const initialState: ISendEmailState = {
  sent: false,
  isLoading: false,
  error: null,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'sendEmail',
  initialState,
  reducers: {
    sendEmail: (state, payload) => {
      state.isLoading = true;
    },
    sendEmailSuccess: state => {
      state.sent = true;
      state.isLoading = false;
    },
    sendEmailFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
  },
});

export const { sendEmail, sendEmailSuccess, sendEmailFailed } = slice.actions;

export default slice.reducer;
