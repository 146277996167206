import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { FlashResponse } from '../../interfaces';
import { getFlash, getFlashFailed, getFlashSuccess } from './slice';

function* flash(flashPayload: PayloadAction<string>) {
  const requestURL = `/api/flash/${flashPayload.payload}`;
  try {
    const result: FlashResponse = yield call(request, requestURL, { method: 'GET' });
    yield put(getFlashSuccess(result));
  } catch (err) {
    console.error('err', err);
    yield put(getFlashFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(getFlash.type, flash)];
