import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTZHoursFromUTCHours } from 'utils/data';
import request from '../../../../utils/request';
import { IImportedBrokerNotes, IList } from './interfaces';

import {
  getImportedBrokerNotes,
  getImportedBrokerNotesSuccess,
  getImportedBrokerNotesFailed,
  getLists,
  getListsSuccess,
  getListsFailed,
} from './slice';

const US_EXCHANGE = ['NYS', 'NYQ', 'NXB'];

export interface IListPayload extends Omit<PayloadAction, 'payload'> {
  payload: string;
}

// Si on est entre 15h00 CET & 4h00 CET -> affichage des performances
// des pairs aux US, sinon 0,00%
function getDailyPerformance(exchange: string, originalPrice: string | undefined) {
  if (!US_EXCHANGE.includes(exchange)) return originalPrice;
  const [hour] = getTZHoursFromUTCHours(new Date().toUTCString().substring(17, 25)).split(':');
  if (parseInt(hour, 10) >= 15 || parseInt(hour, 10) < 4) return originalPrice;
  return '0,00%';
}

function* importedBrokerNotes() {
  const requestURL = '/api/imported-broker-note/my-sector';
  try {
    const result: IImportedBrokerNotes[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getImportedBrokerNotesSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getImportedBrokerNotesFailed({ error: err.responseJSON }));
  }
}

function* lists(timeIntrl: IListPayload) {
  let requestURL;
  timeIntrl.payload !== 'Today'
    ? (requestURL = `/api/indices/tickers?interval=${timeIntrl.payload}`)
    : (requestURL = `/api/indices/tickers`);

  try {
    const result: IList[] = yield call(request, requestURL, { method: 'GET' });

    const indicesLists = result.map(list => ({
      ...list,
      tickers: list.tickers.map(t => ({
        ...t,
        d: timeIntrl.payload === 'Today' ? getDailyPerformance(t.exchange, t.d) : t.d,
      })),
    }));
    yield put(getListsSuccess(indicesLists));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getListsFailed({ error: err.responseJSON }));
  }
}

export default [
  takeLatest(getImportedBrokerNotes.type, importedBrokerNotes),
  takeLatest(getLists.type, lists),
];
