import { createSlice } from '@reduxjs/toolkit';

/** **** INTERFACES */
export interface IGoBackState {
  show?: string;
}

/** **** INITIAL STATE */

const initialState: IGoBackState = {
  show: undefined,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'goback',
  initialState,
  reducers: {
    showButton: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { showButton } = slice.actions;

export default slice.reducer;
