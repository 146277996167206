import PortalLoader from 'components/Loader/PortalLoader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAnalysis } from '../core/interfaces';

interface Props {
  analysis: IAnalysis | null;
  isAnalysisLoading: boolean;
}

function Analysis({ analysis, isAnalysisLoading }: Props) {
  const { t } = useTranslation();
  if (isAnalysisLoading) {
    return <PortalLoader />;
  }

  return (
    <div className="analysis__container">
      <p className="analysis__title">{t('daily:report.title')}</p>
      {!analysis ? (
        <p className="no-data">{t('daily:report.noData')}</p>
      ) : (
        <>
          {analysis.analysis_value && (
            <div className="analysis__detail has-background">
              <h1>{t('daily:report.value.title')}</h1>
              <div
                className="analysis__detail-content"
                dangerouslySetInnerHTML={{
                  __html: analysis.analysis_value,
                }}
              />
            </div>
          )}
          {analysis.analysis_secteur && (
            <div className="analysis__detail">
              <h1>{t('daily:report.sector.title')}</h1>
              <div
                className="analysis__detail-content"
                dangerouslySetInnerHTML={{
                  __html: analysis.analysis_secteur,
                }}
              />
            </div>
          )}
          {analysis.analysis_market && (
            <div className="analysis__detail has-background">
              <h1>{t('daily:report.market.title')}</h1>
              <div
                className="analysis__detail-content"
                dangerouslySetInnerHTML={{
                  __html: analysis.analysis_market,
                }}
              />
            </div>
          )}
          {analysis.analysis_custom_content_1 && (
            <div className="analysis__detail has-background">
              <div
                className="analysis__detail-content"
                dangerouslySetInnerHTML={{
                  __html: analysis.analysis_custom_content_1,
                }}
              />
            </div>
          )}
          {analysis.analysis_custom_content_2 && (
            <div className="analysis__detail has-background">
              <div
                className="analysis__detail-content"
                dangerouslySetInnerHTML={{
                  __html: analysis.analysis_custom_content_2,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Analysis;
