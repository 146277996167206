const TOKEN_URL =
  process.env.REACT_APP_WS_TOKEN_URL ||
  'https://func-rmm-staging.azurewebsites.net/api/getrefinitivtoken';

export interface IRefinitivTokenResponse {
  CreateServiceToken_Response_1: {
    Expiration: string;
    Token: string;
  };
}

async function fetchAndSetToken() {
  try {
    const refinitivResponse = (await fetch(TOKEN_URL).then(res =>
      res.json(),
    )) as IRefinitivTokenResponse;
    localStorage.setItem('refinitivToken', refinitivResponse.CreateServiceToken_Response_1.Token);
    localStorage.setItem(
      'refinitivTokenExp',
      refinitivResponse.CreateServiceToken_Response_1.Expiration,
    );
    return refinitivResponse.CreateServiceToken_Response_1.Token;
  } catch (err) {
    console.error('err', err);
  }
}

export async function getRefinitivToken() {
  const lsToken = localStorage.getItem('refinitivToken');
  const expToken = localStorage.getItem('refinitivTokenExp');

  if (lsToken && expToken) {
    const now = new Date().getTime();
    const exp = new Date(expToken).getTime();
    if (now < exp) {
      return lsToken;
    }
    return fetchAndSetToken();
  }
  return fetchAndSetToken();
}
