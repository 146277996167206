/* eslint-disable react/jsx-key */
import React from 'react';
import { IActiveBrokerMapped, DetailBroker } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';

import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'components/CustomIcon';
import { numbersSpaceSeparator } from 'utils/data';
import { format, parseISO } from 'date-fns';

interface Props {
  brokers: IActiveBrokerMapped[];
  loading: boolean;
}

function MostActiveBrokers({ brokers, loading }: Props) {
  const { t } = useTranslation();

  const columns: Column<IActiveBrokerMapped>[] = React.useMemo(
    () => [
      {
        Header: t('stats:report.brokers.table.broker') as string,
        accessor: 'detail',
        sortType: (a: { original: IActiveBrokerMapped }, b: { original: IActiveBrokerMapped }) => {
          if (a.original.detail.name > b.original.detail.name) return 1;
          return -1;
        },
        Cell: ({ value }: { value: DetailBroker }) => (
          <div className="broker__container">
            {value.name}
            {value.comments && (
              <div>
                <CustomIcon type="comment" /> {value.comments}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('stats:report.brokers.table.volume') as string,
        accessor: 'volume',
        sortType: 'basic',
        Cell: (props: Cell) => numbersSpaceSeparator(props.value, false),
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: 'volume', desc: true }],
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: brokers,
      initialState,
    },
    useSortBy,
  );

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!brokers || brokers.length === 0) {
    return <p className="no-data">{t('daily:report.noData')}</p>;
  }

  const lastUpdatedAt = brokers && brokers[0] && brokers[0].updatedAt;

  return (
    <div className="indices-prices">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<IActiveBrokerMapped>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={cx({ selected: column.isSorted })}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<IActiveBrokerMapped>) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<IActiveBrokerMapped, unknown>) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pairs-legend">
        <i>
          {lastUpdatedAt && `Last update ${format(parseISO(lastUpdatedAt), 'do MMMM, K:mm b')}`}
        </i>
      </div>
    </div>
  );
}

export default MostActiveBrokers;
