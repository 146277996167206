import React, { useEffect } from 'react';
import { RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { clearSession } from 'utils/auth';
import { showButton } from '../../../components/GoBack/slice';
import { resetUser } from 'app/core/slice';
import './styles.scss';
import GoBack from 'components/GoBack';
import CustomIcon from 'components/CustomIcon';
import Loader from 'components/Loader';

interface UserContentProps {
  className: string;
  content?: string;
}

function UserContent({ className, content }: UserContentProps) {
  if (!content) return null;
  return <div className={className}>{content}</div>;
}

function Me() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.session.user.data);
  const isLoading = useSelector((state: RootState) => state.session.user.isLoading);

  useEffect(() => {
    dispatch(showButton('close'));
  }, []);

  function logout() {
    dispatch(resetUser());
    clearSession();
  }

  if (isLoading) {
    return (
      <div className="user__container modal">
        <Loader />
      </div>
    );
  }

  if (user) {
    return (
      <div className="app-container-wrapper background nooverflow">
        <div className="app-container user__container background">
          <div className="back__container">
            <GoBack />
          </div>
          <div className="logo__container">
            <CustomIcon type="logo" />
          </div>
          <div className="user">
            <UserContent className="user__name" content={`${user.firstname} ${user.lastname}`} />
            <UserContent className="user__company" content={user.jobFunction} />
            <hr />
            <UserContent className="user__email" content={user.email} />
            <UserContent className="user__phone" content={user.phone} />
          </div>
          <div className="logout__container">
            <button className="default" aria-label="Logout" type="button" onClick={logout}>
              Sign-out
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="error modal">
      <div className="error">Sorry, there's an error</div>
    </div>
  );
}

export default Me;
