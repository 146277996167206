import cx from 'classnames';
import React from 'react';
import '../assets/infos.scss';
import { IMarketCap, IOpen, IRange } from '../core/interfaces';
import { getCurrencySymbol } from 'utils/currency';
import { numbersSpaceSeparator } from 'utils/data';
import Loader from 'components/Loader';

interface Props {
  open: IOpen;
  prevClose: number;
  volEnx: number;
  marketCap: IMarketCap;
  dayRange: IRange;
  yearRange: IRange;
  loading: boolean;
}

interface PropsInfosItem {
  title: string;
  children: React.ReactNode;
  isBold?: boolean;
}

function InfosItem({ title, children, isBold = true }: PropsInfosItem) {
  return (
    <div className="infos__item">
      <h5>{title}</h5>
      <div
        className={cx('content', {
          bold: isBold,
        })}
      >
        {children}
      </div>
    </div>
  );
}

function Infos({ open, prevClose, volEnx, marketCap, dayRange, yearRange, loading }: Props) {
  if (loading || !(open && prevClose && volEnx && marketCap && yearRange && dayRange)) {
    return (
      <div className="infos">
        <Loader text="Loading..." />
      </div>
    );
  }

  const variation = open?.variation === 0 ? (open.current / prevClose - 1) * 100 : open?.variation;

  return (
    <div className="infos">
      <InfosItem isBold={false} title="OPEN">
        <b>{numbersSpaceSeparator(open.current)}</b> ({variation > 0 ? '+' : ''}
        {numbersSpaceSeparator(variation)}%)
      </InfosItem>
      <InfosItem title="PREV. CLOSE">{numbersSpaceSeparator(prevClose)}</InfosItem>
      <InfosItem title="VOL. EURONEXT">{numbersSpaceSeparator(volEnx, false)}</InfosItem>
      <InfosItem title="MARKET CAP.">
        {numbersSpaceSeparator(marketCap.value, false)} {marketCap.separator}
        {getCurrencySymbol(marketCap.currency)}
      </InfosItem>
      <InfosItem title="DAY RANGE">
        {numbersSpaceSeparator(dayRange.min)} - {numbersSpaceSeparator(dayRange.max)}
      </InfosItem>
      <InfosItem title="52 W RANGE">
        {numbersSpaceSeparator(yearRange.min)} - {numbersSpaceSeparator(yearRange.max)}
      </InfosItem>
    </div>
  );
}

export default Infos;
