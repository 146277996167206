// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import Footer from 'components/Footer';
import { showButton } from 'components/GoBack/slice';
import TopBar from 'components/TopBar';
import User from 'features/user';
import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { clearSession, isAuthenticated } from 'utils/auth';
import { isSessionOpened } from 'utils/data';
import BrokersNote from './features/brokers-note';
import DailyReport from './features/daily-report/index';
import Search from './features/search';
import Flashs from './features/flashs';
import Inbox from './features/inbox/Inbox';
import Short from './features/short';
import Login from './features/login/Login';
import Statistics from './features/mkt-data';
import ResetPassword from './features/reset-password';
import { RootState } from 'app/store';
import { AppContext } from 'contexts/AppContext';
import CustomIcon from 'components/CustomIcon';
import FooterDesktop from 'components/FooterDesktop';
import { getAppInsights } from 'utils/telemetry';
import TelemetryProvider from 'app/telemetry-provider';
import RequireAuth from 'app/RequireAuth';
import RequireNotAuth from 'app/RequireNotAuth';
import { getVersion } from './app/core/slice';
import { VersionModal } from 'components/VersionModal';
// import { NativeBiometric } from 'capacitor-native-biometric';
// import { login } from 'features/login/LoginSlice';
import { Link } from 'app/core/interfaces';

let appInsights = null;

const noFooterURLs = ['/me', '/me/password-change', '/me/cgv-cgu', '/reset-password', '/login'];
const baseURLs = [
  '/statistics',
  '/statistics/sector',
  '/inbox',
  '/flashs',
  '/brokers-note',
  '/brokers-note/notes',
  '/daily-report',
  '/short',
  '/login',
];

const isOtherDay = () => {
  const day = new Date().toISOString().split('T')[0];
  const lastTimeShowed = localStorage.getItem('lastTimeShowed');
  return day !== lastTimeShowed;
};

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const userData = useSelector((state: RootState) => state.session?.user?.data);
  const passwordChangeRequired = userData?.passwordChangeRequired;
  const authorities = userData?.authorities || [];
  const { forceUpdate, number } = useSelector((state: RootState) => state.session.version);
  const modalProps = { forceUpdate, close: () => setDisplayModal(false) };
  // const [isBiometricLogging, setIsBiometricLogging] = useState(false);

  useEffect(() => {
    // async function biometricAuth() {
    //   const result = await NativeBiometric.isAvailable();
    //   if (!result.isAvailable) {
    //     console.log('Not available for FACE ID');
    //     return;
    //   }
    //   // const isFaceID = result.biometryType === BiometryType.FACE_ID;
    //   // Mobile handle the biometricType to be used
    //   try {
    //     setIsBiometricLogging(true);
    //     await NativeBiometric.verifyIdentity({
    //       reason: 'Log in',
    //       title: 'Log in',
    //       subtitle: 'Please login to access the app',
    //       description: 'Please login to access the app',
    //       maxAttempts: 5,
    //       useFallback: true,
    //       negativeButtonText: 'Cancel',
    //     });
    //     const credentials = await NativeBiometric.getCredentials({
    //       server: 'www.example.com',
    //     });
    //     if (credentials) {
    //       const { username: email, password } = credentials;
    //       dispatch(login({ email, password }));
    //     }
    //   } catch (err) {
    //     console.log('err', err);
    //     clearSession();
    //   } finally {
    //     setIsBiometricLogging(false);
    //   }
    // }
    // if (localStorage.getItem('allowBiometricAuth') === 'true') {
    //   biometricAuth();
    // }
  }, []);

  useEffect(() => {
    // when diferent day then we ask if necessary to update
    // this dispatch could be moved to the effect when focus the mobile too!
    if ((number && isOtherDay()) || forceUpdate) {
      setDisplayModal(true);
    }
  }, [number]);

  useEffect(() => {
    // GET the version when already logged into the app (in order to have already token to call API)
    if (userData) {
      dispatch(getVersion());
    }
  }, [userData]);

  // force user_limited role in order to test it
  // authorities = [...authorities, 'USER_LIMITED'];
  const isLimitedRole = authorities.length === 0 || authorities?.includes('ROLE_EMITTER_LIMITED');
  const isMarketClose = !isSessionOpened();

  const allowStatistics = (isLimitedRole && isMarketClose) || !isLimitedRole;

  const { isTabletPortaitSize, isDesktopOrLaptop } = useContext(AppContext);
  const { data: userInfo } = useSelector((state: RootState) => state.session.user);

  const [newDaily, setNewDaily] = useState(false);
  const [newFlash, setNewFlash] = useState(false);

  const mainLinks: Link[] = [
    {
      link: '/statistics',
      icon: <CustomIcon type="market-data" />,
      iconWhite: <CustomIcon type="market-data-white" />,
      title: isDesktopOrLaptop ? 'Market data' : 'Mkt data',
      enabled: !isLimitedRole || (isLimitedRole && isMarketClose),
      onlyDesktop: false,
    },
    {
      link: '/flashs',
      icon: <CustomIcon type="flash" />,
      iconWhite: <CustomIcon type="flash-white" />,
      title: 'Flashs',
      enabled: !isLimitedRole,
      onlyDesktop: false,
    },
    {
      link: '/brokers-note',
      icon: <CustomIcon type="broker" />,
      iconWhite: <CustomIcon type="broker-white" />,
      title: 'Cons./Brokers',
      enabled: !isLimitedRole,
      onlyDesktop: false,
    },
    {
      link: '/daily-report',
      icon: <CustomIcon type="daily-report" />,
      iconWhite: <CustomIcon type="daily-report-white" />,
      title: 'Daily report',
      enabled: true,
      onlyDesktop: false,
    },
    {
      link: '/short',
      icon: <CustomIcon type="short" />,
      iconWhite: <CustomIcon type="short-white" />,
      title: 'Short',
      enabled: !isLimitedRole,
      onlyDesktop: false,
    },
    {
      link: '/search',
      icon: <CustomIcon type="search" />,
      iconWhite: <CustomIcon type="search-white" />,
      title: 'Search',
      onlyDesktop: true,
      enabled: !isLimitedRole,
    },
  ];

  useEffect(() => {
    const lastDaily = userInfo?.lastDailyDate;
    const lastDailyRead = userInfo?.lastDailyReadDate;
    const lastFlash = userInfo?.lastFlashDate;
    const lastFlashRead = userInfo?.lastFlashReadDate;
    if (
      lastFlash &&
      lastFlashRead &&
      Date.parse(lastFlash) > Date.parse(lastFlashRead) &&
      pathname !== '/flashs'
    ) {
      setNewFlash(true);
    }

    if (
      lastDaily &&
      lastDailyRead &&
      Date.parse(lastDaily) > Date.parse(lastDailyRead) &&
      pathname !== '/daily-report'
    ) {
      setNewDaily(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!baseURLs.includes(pathname)) {
      dispatch(showButton('back'));
    } else {
      dispatch(showButton(undefined));
    }

    if (pathname === '/flashs') {
      setNewFlash(false);
    }

    if (pathname === '/daily-report') {
      setNewDaily(false);
    }
  }, [pathname]);

  return (
    <>
      {/* {isBiometricLogging ? (
        <div className="biometric-loading">
          <div className="biometric-loading__container">
            <div className="biometric-loading__spinner" />
            <div className="biometric-loading__text">Logging in...</div>
          </div>
        </div>
      ) : (
        ''
      )} */}
      <TelemetryProvider
        instrumentationKey={process.env.REACT_APP_APPINSIGHTS_KEY}
        after={() => {
          appInsights = getAppInsights();
        }}
      >
        <TopBar
          showHeader={
            isAuthenticated() &&
            !passwordChangeRequired &&
            !(isDesktopOrLaptop && noFooterURLs.indexOf(pathname) !== -1)
          }
          onUserButtonClick={() => navigate('/me')}
          isMobile={isTabletPortaitSize}
          isFullSize={
            ['/statistics', '/daily-report'].includes(pathname) ||
            pathname.startsWith('/daily-report/')
          }
          isMidSize={[
            '/statistics/sector',
            '/statistics/valuations',
            '/brokers-note',
            '/brokers-note/notes',
            '/brokers-note/valorisation',
          ].includes(pathname)}
          mainLinks={mainLinks}
          newDaily={newDaily}
          newFlash={newFlash}
        />
        <Routes>
          {allowStatistics && (
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Navigate to="/statistics" />
                </RequireAuth>
              }
            />
          )}
          {allowStatistics && (
            <Route
              path="/statistics/*"
              element={
                <RequireAuth>
                  <Statistics />
                </RequireAuth>
              }
            />
          )}
          <Route
            path="/inbox/*"
            element={
              <RequireAuth>
                <Inbox />
              </RequireAuth>
            }
          />
          {!isLimitedRole && (
            <Route
              path="/flashs/*"
              element={
                <RequireAuth>
                  <Flashs />
                </RequireAuth>
              }
            />
          )}
          {!isLimitedRole && (
            <Route
              path="/brokers-note/*"
              element={
                <RequireAuth>
                  <BrokersNote />
                </RequireAuth>
              }
            />
          )}
          <Route
            path="/daily-report/*"
            element={
              <RequireAuth>
                <DailyReport />
              </RequireAuth>
            }
          />
          {!isLimitedRole && (
            <Route
              path="/search/*"
              element={
                <RequireAuth>
                  <Search />
                </RequireAuth>
              }
            />
          )}
          {!isLimitedRole && (
            <Route
              path="/short/*"
              element={
                <RequireAuth>
                  <Short />
                </RequireAuth>
              }
            />
          )}
          <Route
            path="/me/*"
            element={
              <RequireAuth>
                <User />
              </RequireAuth>
            }
          />
          <Route
            path="/login"
            element={
              <RequireNotAuth>
                <Login />
              </RequireNotAuth>
            }
          />
          <Route
            path="/reset-password/*"
            element={
              <RequireNotAuth>
                <ResetPassword />
              </RequireNotAuth>
            }
          />
          {/* default route when no matching */}
          <Route
            path="*"
            element={
              <RequireAuth>
                <Navigate to="/daily-report" />
              </RequireAuth>
            }
          />
        </Routes>
        <div id="loader-portal" />
        {/* {!isBiometricLogging ? ( */}
        <Footer
          mainLinks={mainLinks}
          newDaily={newDaily}
          newFlash={newFlash}
          show={
            !noFooterURLs.includes(pathname) &&
            !pathname.startsWith('/reset-password') &&
            isTabletPortaitSize
          }
        />
        {/* ) : null} */}
        {!noFooterURLs.includes(pathname) &&
        !pathname.startsWith('/reset-password') &&
        !isTabletPortaitSize ? (
          <FooterDesktop />
        ) : (
          ''
        )}
      </TelemetryProvider>
      {displayModal && isTabletPortaitSize && <VersionModal {...modalProps} />}
    </>
  );
}

export default App;
