import React from 'react';
import { LineChartData } from 'components/Charts/LineChart';

interface Props {
  data: LineChartData;
}

export function ShortPositionFooter({ data }: Props) {
  const { datasets } = data;
  const value = datasets[0]?.data?.pop();
  const color = String(datasets[0]?.backgroundColor);
  return (
    <div className="chart-footer">
      <div>
        <div className="chart-footer__rectangle" style={{ backgroundColor: color }}></div>
        <span>Short position (% float)</span>
        <span>{value || '0'}</span>%
      </div>
    </div>
  );
}
