/* eslint-disable react/jsx-key */
import React from 'react';
import { SellersProps, ISellersRequest } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import PortalLoader from 'components/Loader/PortalLoader';
import CustomIcon from 'components/CustomIcon';

function Sellers({ sellers, loading }: SellersProps) {
  const { t } = useTranslation();
  const columns: Column<ISellersRequest>[] = React.useMemo(
    () => [
      {
        Header: t('stats:short.sellers.funds') as string,
        accessor: 'funds',
        sortType: 'alphanumeric',
        Cell: ({ row }: { row: { original: ISellersRequest } }) => (
          <div className="broker__container">
            {row.original.funds}
            {row.original.comment && (
              <div>
                <CustomIcon type="comment" /> {row.original.comment}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('stats:short.sellers.pctPosition') as string,
        id: 'pctPosition',
        accessor: x => (x.pctPosition ? parseFloat(x.pctPosition) : x.pctPosition),
        sortType: 'basic',
        Cell: (props: Cell) => <span>{props.value} %</span>,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: 'pctPosition', desc: true }],
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: sellers,
      initialState,
    },
    useSortBy,
  );

  if (loading) {
    return <PortalLoader />;
  }

  if (!sellers) {
    return <p>{t('common:p.noData')}</p>;
  }

  return (
    <div className="short__sellers">
      <h2>{t('short:sellers.title')}</h2>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<ISellersRequest>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={cx({ selected: column.isSorted })}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<ISellersRequest>) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<ISellersRequest>) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Sellers;
