import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PullToRefresh from 'pulltorefreshjs';
import Loader from 'components/Loader';
import './styles.scss';

interface Props {
  action: () => void;
}

function RefreshPortal({ action }: Props) {
  useEffect(() => {
    PullToRefresh.init({
      mainElement: 'body',
      onRefresh() {
        if (action) {
          action();
        }
      },
      iconRefreshing: ReactDOMServer.renderToString(
        /* @ts-ignore */
        <div className="icon-refresh-pull">
          <Loader />,
        </div>,
      ),
    });
    return () => {
      PullToRefresh.destroyAll();
    };
  }, []);
  return <div />;
}

export default RefreshPortal;
