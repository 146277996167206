/* eslint-disable no-control-regex */
/* eslint-disable react/no-danger */
import { RootState } from 'app/store';
import Container from 'components/Container';
import { Attachments } from 'features/brokers-note/core/interfaces';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatting } from 'utils/data';
import './assets/styles.scss';
import { getFlash, IFlashState } from './core/slice';
import { Helmet } from 'react-helmet';
import PortalLoader from 'components/Loader/PortalLoader';
import snakeCase from 'lodash/snakeCase';
import CustomIcon from 'components/CustomIcon';
import { useParams } from 'react-router-dom';

interface IFlashDetailProps {
  firstFlash?: string;
  parentCallback?: (id: string) => void;
}

function Flash(props: IFlashDetailProps) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const flashState: IFlashState = useSelector((state: RootState) => state.flashDetail);
  const { flash } = flashState;
  const isLoading = useSelector((state: RootState) => state.flashDetail.isLoading);

  useEffect(() => {
    if (!id) {
      dispatch(getFlash(props.firstFlash));
      if (props.parentCallback && props.firstFlash) props.parentCallback(props.firstFlash);
    } else {
      dispatch(getFlash(id));
      if (props.parentCallback) props.parentCallback(id);
    }
  }, [id]);

  if (!isLoading && !flash) {
    return <div>Error...</div>;
  }

  if (!flash) {
    return <div>No flash here</div>;
  }

  const { company, action, title, description, attachments, links, createdAt: dateTime } = flash;

  const dateString = dateFormatting(dateTime);
  const bulletClass = snakeCase(action);

  let allAttachments;
  if (attachments !== null) {
    allAttachments = attachments.map((file: Attachments) => {
      const url = `${process.env.REACT_APP_API_URL}/api/attachment/${file.id}/content`;
      if (file.type?.startsWith('image')) {
        return (
          <div
            className="flash-card__attachment attachment__image"
            key={file.filename}
            role="button"
            tabIndex={-1}
            onKeyDown={() => window.open(url, '_blank')}
            onClick={() => window.open(url, '_blank')}
          >
            <img src={url} alt={file.filename} />
          </div>
        );
      }
      return (
        <div className="flash-card__attachment attachment__file" key={file.filename}>
          <CustomIcon width={24} type="attachment" />
          <a href={url} target="_blank" rel="noopener noreferrer">
            {file.filename}
          </a>
        </div>
      );
    });
  }

  return (
    <Container>
      <Helmet>
        <title>RMM - Flash - {title}</title>
      </Helmet>
      {isLoading ? <PortalLoader /> : ''}
      <div className="flash flash__detail">
        <div className="flash__header groupByData__header">
          <div className="flash__company groupByData__company">{company}</div>
          <div className="flash__date groupByData__date_card">{dateString}</div>
        </div>
        <div className="flash__content">
          <div className="flash__action action__bullets">
            <span className={bulletClass}>{action}</span>
          </div>
          <div className="flash__title">{title}</div>
          <div
            className="flash__description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <div className="flash__links">
            {links && links.length && links[0] !== '' ? (
              <div>
                <h4>Associated links: </h4>
                <ul>
                  {links.map((l: string) => (
                    <li key={l}>
                      <a target="_blank" rel="noopener noreferrer" href={l}>
                        {l}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="flash__attachments">{allAttachments}</div>
        </div>
      </div>
    </Container>
  );
}

export default Flash;
