import React from 'react';
import { ReactComponent as DailyReport } from './pictos/daily-report.svg';
import { ReactComponent as Broker } from './pictos/broker.svg';
import { ReactComponent as Flash } from './pictos/flash.svg';
import { ReactComponent as ArrowDown } from './pictos/ico-arrow-down.svg';
import { ReactComponent as ArrowLeft } from './pictos/ico-arrow-left.svg';
import { ReactComponent as ArrowRight } from './pictos/ico-arrow-right.svg';
import { ReactComponent as ArrowUp } from './pictos/ico-arrow-up.svg';
import { ReactComponent as BrokerChange } from './pictos/ico-broker-change.svg';
import { ReactComponent as BrokerInitiation } from './pictos/ico-broker-initiation.svg';
import { ReactComponent as BrokerSectornote } from './pictos/ico-broker-sectornote.svg';
import { ReactComponent as ContentAttachedfile } from './pictos/ico-content-attachedfile.svg';
import { ReactComponent as ContentHide } from './pictos/ico-content-hide.svg';
import { ReactComponent as ContentShow } from './pictos/ico-content-show.svg';
import { ReactComponent as MenuAdmin } from './pictos/ico-menu-admin.svg';
import { ReactComponent as User } from './pictos/ico-user.svg';
import { ReactComponent as MarketData } from './pictos/market-data.svg';
import { ReactComponent as LogoFull } from './pictos/logo-full.svg';
import { ReactComponent as Show } from './pictos/show.svg';
import { ReactComponent as Hide } from './pictos/hide.svg';
import { ReactComponent as Correct } from './pictos/correct.svg';
import { ReactComponent as Wrong } from './pictos/wrong.svg';
import { ReactComponent as Logo } from './pictos/brand-logo-light.svg';
import { ReactComponent as MarketDataWhite } from './pictos/market-data-white.svg';
import { ReactComponent as BrokerWhite } from './pictos/broker-white.svg';
import { ReactComponent as FlashWhite } from './pictos/flash-white.svg';
import { ReactComponent as DailyReportWhite } from './pictos/daily-report-white.svg';
import { ReactComponent as Comment } from './pictos/comment.svg';
import { ReactComponent as Short } from './pictos/short.svg';
import { ReactComponent as ShortWhite } from './pictos/short-white.svg';
import { ReactComponent as Date } from './pictos/date.svg';
import { ReactComponent as Contact } from './pictos/contact.svg';
import { ReactComponent as Up } from './pictos/up.svg';
import { ReactComponent as Down } from './pictos/down.svg';
import { ReactComponent as MarketOpen } from './pictos/market-open.svg';
import { ReactComponent as MarketClosed } from './pictos/market-closed.svg';
import { ReactComponent as Attachment } from './pictos/attachment.svg';
import { ReactComponent as Right } from './pictos/arrow-right.svg';
import { ReactComponent as Paused } from './pictos/paused.svg';
import { ReactComponent as Search } from './pictos/search.svg';
import { ReactComponent as SearchWhite } from './pictos/search-white.svg';
import { ReactComponent as EnlargeButton } from './pictos/enlarge-button.svg';

export type PictoTypesEnum =
  | 'paused'
  | 'right'
  | 'attachment'
  | 'market-closed'
  | 'market-open'
  | 'up'
  | 'down'
  | 'short-white'
  | 'contact'
  | 'date'
  | 'short'
  | 'daily-report-white'
  | 'flash-white'
  | 'broker-white'
  | 'market-data-white'
  | 'daily-report'
  | 'broker'
  | 'flash'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'broker-change'
  | 'broker-initiation'
  | 'broker-sectornote'
  | 'content-attachedfile'
  | 'content-hide'
  | 'content-show'
  | 'market-open'
  | 'menu-admin'
  | 'user'
  | 'market-data'
  | 'logo-full'
  | 'show'
  | 'hide'
  | 'correct'
  | 'wrong'
  | 'logo'
  | 'comment'
  | 'date'
  | 'search'
  | 'search-white'
  | 'enlarge-button';

const pictosTypes = {
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'broker-change': BrokerChange,
  'broker-white': BrokerWhite,
  'broker-initiation': BrokerInitiation,
  'broker-sectornote': BrokerSectornote,
  'content-attachedfile': ContentAttachedfile,
  'content-hide': ContentHide,
  'content-show': ContentShow,
  'daily-report': DailyReport,
  'daily-report-white': DailyReportWhite,
  'flash-white': FlashWhite,
  'logo-full': LogoFull,
  'market-data': MarketData,
  'market-data-white': MarketDataWhite,
  'menu-admin': MenuAdmin,
  broker: Broker,
  correct: Correct,
  flash: Flash,
  hide: Hide,
  show: Show,
  user: User,
  wrong: Wrong,
  logo: Logo,
  comment: Comment,
  short: Short,
  date: Date,
  contact: Contact,
  'short-white': ShortWhite,
  up: Up,
  down: Down,
  right: Right,
  'market-open': MarketOpen,
  'market-closed': MarketClosed,
  attachment: Attachment,
  paused: Paused,
  search: Search,
  'search-white': SearchWhite,
  'enlarge-button': EnlargeButton,
};

interface CustomIconProps {
  type: PictoTypesEnum;
  size?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
}

function CustomIcon({ type, size, color, width, height, className }: CustomIconProps) {
  if (!pictosTypes[type]) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        `Attention, l'icône ${type} is not referenced in the pictosTypes object, in the file component/CustomIcon/index.tsx`,
      );
    }
  }
  const Element = pictosTypes[type];
  const style: React.CSSProperties = {};
  if (size) style.fontSize = size;
  if (color) style.color = color;
  if (width) style.width = width;
  if (height) style.height = height;

  return <Element className={className} style={style} />;
}

export default CustomIcon;
