import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import { IRecommendationsState } from './interfaces';

/** **** INITIAL STATE */

const initialState: IRecommendationsState = {
  recommendations: [],
  recoTpBrokers: {
    isLoading: false,
    error: null,
    data: null,
  },
  isLoading: false,
  error: null,
  isLastPage: false,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    getRecommendations: (state, action) => {
      state.isLoading = true;
    },
    getRecommendationsSuccess: (state, action) => {
      const prevRecommendations = JSON.parse(JSON.stringify(state.recommendations));
      if (!action.payload.length) state.isLastPage = true;
      state.recommendations = uniqBy([...prevRecommendations, ...action.payload], 'id');
      state.isLoading = false;
    },
    getRecommendationsFailed: (state, action) => {
      state.error = action.payload.error;
      state.isLoading = false;
    },

    getRecoTpBrokers: state => {
      state.recoTpBrokers.isLoading = true;
    },
    getRecoTpBrokersSuccess: (state, action) => {
      state.recoTpBrokers.data = action.payload;
      state.recoTpBrokers.isLoading = false;
    },
    getRecoTpBrokersFailed: (state, action) => {
      state.error = action.payload.error;
      state.recoTpBrokers.isLoading = false;
    },

    clearRecommendations: state => {
      state.recommendations = [];
      state.isLastPage = false;
    },
  },
});

export const {
  getRecommendations,
  getRecommendationsSuccess,
  getRecommendationsFailed,
  clearRecommendations,
  getRecoTpBrokers,
  getRecoTpBrokersSuccess,
  getRecoTpBrokersFailed,
} = slice.actions;

export default slice.reducer;
