import React from 'react';
import cx from 'classnames';
import { PropBtnLegend } from './interfaces';

export default function BtnLegend({ onClick, selected, label }: PropBtnLegend) {
  return (
    <div
      role="button"
      tabIndex={-1}
      onKeyDown={onClick}
      onClick={onClick}
      className={cx('stockchart__legend-btn', { selected })}
    >
      {label}
    </div>
  );
}
