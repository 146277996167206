import React from 'react';
import { ReactComponent as Logo } from './logo-light.svg';
import './styles.scss';

interface Props {
  text?: string;
}

function Loader({ text }: Props) {
  return (
    <div className="loader">
      <Logo className="loader-svg" />
      {text ? <p className="loader-text">{text}</p> : ''}
    </div>
  );
}

export default Loader;
