import { call, put, takeLatest } from 'redux-saga/effects';
import { getValorisation, getValorisationSuccess, getValorisationFailed } from './slice';
import { Response, getPeRatioTimeSeriesDataForList } from 'utils/factset';
import request from 'utils/request';
import { PayloadAction } from '@reduxjs/toolkit';
import { BloomData } from './types';

function* peRatio(action: PayloadAction<{ emitterName: string }>) {
  const emitterName = action.payload.emitterName;
  try {
    const valorisationUrl = '/api/factset';
    const backdata: BloomData = yield call(request, valorisationUrl);
    const allRatiosRaw: Response = yield call(getPeRatioTimeSeriesDataForList, backdata);

    const allRatios = allRatiosRaw.map(ratio => ({
      ...ratio,
      graphKey: backdata.multiples.find(m => m.key === ratio.graphKey)?.label || ratio.graphKey,
      graphData: ratio.graphData.map(data => ({
        ...data,
        id: backdata.peersBloomIds.find(p => p.factsetId === data.id)?.factsetName || emitterName,
        originalId: data.id,
      })),
    }));

    yield put(
      getValorisationSuccess({
        ratios: allRatios,
        backdata,
      }),
    );
  } catch (err) {
    console.error('err', err);
    yield put(getValorisationFailed({ error: err.responseJSON }));
  }
}

export default [takeLatest(getValorisation.type, peRatio)];
