import React, { useContext, useEffect, useState } from 'react';
import ImportedBrokerNotes from './components/ImportedBrokerNotes';
import { useDispatch, useSelector } from 'react-redux';
import { getImportedBrokerNotes, getLists } from './core/slice';
import { RootState } from 'app/store';
import List from './components/List';
import { Helmet } from 'react-helmet';
import { IList } from './core/interfaces';
import cx from 'classnames';

import './assets/styles.scss';
import { AppContext } from 'contexts/AppContext';
import PortalLoader from 'components/Loader/PortalLoader';
import RefreshPortal from 'components/RefreshPortal';
import BtnLegend from 'components/StockChart/BtnLegend';

const legend = [
  { title: 'Today' },
  { title: '7D' },
  { title: '1M' },
  { title: '6M' },
  { title: 'YTD' },
];

function Sector() {
  const dispatch = useDispatch();
  const [snapshot, setSnapshot] = useState<string>('');
  const [timeIntrvl, setTimeIntvl] = useState<string>(legend[0].title);

  const { isDesktopOrLaptop } = useContext(AppContext);
  const { data: user } = useSelector((state: RootState) => state.session.user);
  const { items: notes, isLoading: isNotesLoading } = useSelector(
    (state: RootState) => state.sector.brokerNotes,
  );
  const { items: lists, isLoading: areListsLoading } = useSelector(
    (state: RootState) => state.sector.lists,
  );

  useEffect(() => {
    dispatch(getImportedBrokerNotes());
    dispatch(getLists('Today'));
  }, []);

  useEffect(() => {
    if (lists?.length) {
      const snapshotDate = lists.reduce(
        (p1, curr) => p1 || curr.tickers.find(p => p.isSnapshot === true)?.createdAt || '',
        '',
      );
      setSnapshot(snapshotDate);
    }
  }, [lists]);

  function onInnerLegendClick(legendTitle: string) {
    setTimeIntvl(legendTitle);
    dispatch(getLists(legendTitle));
  }

  if (areListsLoading || isNotesLoading) {
    return <PortalLoader />;
  }

  return (
    <div>
      <Helmet>
        <title>RMM - Sector/Indices</title>
      </Helmet>
      <RefreshPortal
        action={() => {
          dispatch(getImportedBrokerNotes());
          dispatch(getLists('Today'));
        }}
      />
      <div className="style_legend">
        {legend.map(l => (
          <BtnLegend
            key={l.title}
            label={l.title}
            selected={timeIntrvl === l.title}
            onClick={() => onInnerLegendClick(l.title)}
          />
        ))}
      </div>
      <div
        className={cx('sector-content', {
          'is-desktop': isDesktopOrLaptop,
        })}
      >
        <div className="lists">
          {lists
            ?.filter(item => item.name !== 'x_daily_report_x')
            .map((list: IList, index) => (
              <List
                list={list}
                isLast={index === lists.length - 1}
                loading={areListsLoading}
                key={list.id}
                snapshot={snapshot}
                emitterName={user?.emitter?.name}
                timeIntrvl={timeIntrvl}
                isMobile={!isDesktopOrLaptop}
              />
            ))}
        </div>
        <ImportedBrokerNotes notes={notes} loading={isNotesLoading} />
      </div>
    </div>
  );
}

export default Sector;
