import React from 'react';
import { IConsensusPeriod, TConsensusTableRow } from '../core/interfaces';
import { useTable, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { sortBy } from 'lodash';

interface Props {
  list: TConsensusTableRow[];
  periods: IConsensusPeriod[];
  currentTickerName: string;
}

function getPeriodOrder() {
  const currentYear = new Date().getFullYear();
  const yearBefore = new Date().getFullYear() - 1;
  const yearBefore2 = new Date().getFullYear() - 2;
  const yearAfter = new Date().getFullYear() + 1;
  const yearAfter2 = new Date().getFullYear() + 2;
  const yearAfter3 = new Date().getFullYear() + 2;
  const yearAfter4 = new Date().getFullYear() + 3;
  const periodOrder = [
    'FY-' + yearBefore2,
    'FY-' + yearBefore,
    'FY-' + currentYear,
    '1HFY-' + currentYear,
    '2HFY-' + currentYear,
    'FY-' + yearAfter,
    '1HFY-' + yearAfter,
    '2HFY-' + yearAfter,
    'FY-' + yearAfter2,
    '1HFY-' + yearAfter2,
    '2HFY-' + yearAfter2,
    'FY-' + yearAfter3,
    '1HFY-' + yearAfter3,
    '2HFY-' + yearAfter3,
    'FY-' + yearAfter4,
    '1HFY-' + yearAfter4,
    '2HFY-' + yearAfter4,
  ];

  return periodOrder;
}

function ConsensusTable({ list, periods, currentTickerName }: Props) {
  const PERIOD_ORDER_2 = getPeriodOrder();
  const periodSorted = sortBy(periods, i => PERIOD_ORDER_2.indexOf(i.name));
  const lineItemSorted = sortBy(list, 'order');
  const columns: Column<TConsensusTableRow>[] = React.useMemo(
    () => [
      {
        Header: 'Line item',
        accessor: 'lineItem',
      },
      ...periodSorted.map(p => ({
        Header: `${p.name}`,
        accessor: p.name,
        Cell: ({ value }: Cell<TConsensusTableRow, string>) => {
          return !value ? '-' : value;
        },
      })),
    ],
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: lineItemSorted,
  });

  return (
    <div className="consensus-table">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<TConsensusTableRow>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className={cx({
                    fixed: column.Header === 'Line item',
                    headerFixed: column.Header === 'Line item',
                    period: column.Header !== 'Line item',
                  })}
                >
                  <div className="th-wrapper">
                    <div className="header-name">{column.render('Header')}</div>
                    {column.isSorted ? (
                      <div className="arrow">
                        <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<TConsensusTableRow>) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={cx({
                  highlighted:
                    row.original.lineItem.toLowerCase() === currentTickerName.toLowerCase(),
                })}
              >
                {row.cells.map((cell: Cell<TConsensusTableRow>) => (
                  <td
                    className={cx({
                      fixed: cell.column.Header === 'Line item',
                      sorted: cell.column.isSorted,
                      period: cell.column.Header !== 'Line item',
                    })}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ConsensusTable;
