import { createSlice } from '@reduxjs/toolkit';
import { IQuote } from 'features/mkt-data/stock/core/interfaces';
import {
  IAnalysis,
  ITradedVolume,
  IEconomicStatistic,
  IAgenda,
  IActiveBrokerMapped,
} from './interfaces';

/** **** INTERFACES */

export interface IReportsState {
  quotes: {
    isLoading: boolean;
    data: IQuote | null;
    error?: Object;
  };
  analysis: {
    data: IAnalysis | null;
    isLoading: boolean;
    error?: Object | null;
  };
  tradedVolumes: {
    data: [] | ITradedVolume[];
    isLoading: boolean;
    error?: Object | null;
  };
  mostActiveBrokers: {
    data: [] | IActiveBrokerMapped[];
    isLoading: boolean;
    error?: Object | null;
  };
  economicStatistics: {
    data: [] | IEconomicStatistic[];
    isLoading: boolean;
    error?: Object | null;
  };
  agenda: {
    data: [] | IAgenda[];
    isLoading: boolean;
    error?: Object | null;
  };
}

/** **** INITIAL STATE */

const initialState: IReportsState = {
  quotes: {
    isLoading: true,
    error: undefined,
    data: null,
  },
  analysis: {
    data: null,
    isLoading: false,
    error: null,
  },
  tradedVolumes: {
    data: [],
    isLoading: false,
    error: null,
  },
  mostActiveBrokers: {
    data: [],
    isLoading: false,
    error: null,
  },
  economicStatistics: {
    data: [],
    isLoading: false,
    error: null,
  },
  agenda: {
    data: [],
    isLoading: false,
    error: null,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getQuotes: (state, action) => {
      state.quotes.isLoading = true;
    },
    getQuotesSuccess: (state, action) => {
      state.quotes.data = action.payload;
      state.quotes.isLoading = false;
    },
    getQuotesFailed: (state, action) => {
      state.quotes.error = action.payload.error;
      state.quotes.isLoading = false;
    },
    getAnalysis: (state, action) => {
      state.analysis.isLoading = true;
    },
    getAnalysisSuccess: (state, action) => {
      state.analysis.data = action.payload;
      state.analysis.isLoading = false;
    },
    getAnalysisFailed: (state, action) => {
      state.analysis.error = action.payload.error;
      state.analysis.isLoading = false;
      state.analysis = initialState.analysis;
    },
    getTradedVolumes: (state, action) => {
      state.tradedVolumes.isLoading = true;
    },
    getTradedVolumesSuccess: (state, action) => {
      state.tradedVolumes.data = action.payload;
      state.tradedVolumes.isLoading = false;
    },
    getTradedVolumesFailed: (state, action) => {
      state.tradedVolumes.error = action.payload.error;
      state.tradedVolumes.isLoading = false;
    },
    getMostActiveBrokers: (state, action) => {
      state.mostActiveBrokers.isLoading = true;
    },
    getMostActiveBrokersSuccess: (state, action) => {
      state.mostActiveBrokers.data = action.payload;
      state.mostActiveBrokers.isLoading = false;
    },
    getMostActiveBrokersFailed: (state, action) => {
      state.mostActiveBrokers.error = action.payload.error;
      state.mostActiveBrokers.isLoading = false;
    },
    getEconomicStatistics: (state, action) => {
      state.economicStatistics.isLoading = true;
    },
    getEconomicStatisticsSuccess: (state, action) => {
      state.economicStatistics.data = action.payload;
      state.economicStatistics.isLoading = false;
    },
    getEconomicStatisticsFailed: (state, action) => {
      state.economicStatistics.error = action.payload.error;
      state.economicStatistics.isLoading = false;
    },
    getAgenda: (state, action) => {
      state.agenda.isLoading = true;
    },
    getAgendaSuccess: (state, action) => {
      state.agenda.data = action.payload;
      state.agenda.isLoading = false;
    },
    getAgendaFailed: (state, action) => {
      state.agenda.error = action.payload.error;
      state.agenda.isLoading = false;
    },
    clearDataOnDateChange(state) {
      state.analysis = initialState.analysis;
    },
  },
});

export const {
  getQuotes,
  getQuotesSuccess,
  getQuotesFailed,
  getAnalysis,
  getAnalysisSuccess,
  getAnalysisFailed,
  getTradedVolumes,
  getTradedVolumesSuccess,
  getTradedVolumesFailed,
  getMostActiveBrokers,
  getMostActiveBrokersSuccess,
  getMostActiveBrokersFailed,
  getEconomicStatistics,
  getEconomicStatisticsSuccess,
  getEconomicStatisticsFailed,
  getAgenda,
  getAgendaSuccess,
  getAgendaFailed,
  clearDataOnDateChange,
} = slice.actions;

export default slice.reducer;
