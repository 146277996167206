import { createSlice } from '@reduxjs/toolkit';

/** **** INTERFACES */

export interface IPasswordState {
  updated: boolean;
  clearForm: boolean;
  isLoading: boolean;
  error?: {
    status: number;
    message: string;
  } | null;
}

/** **** INITIAL STATE */

const initialState: IPasswordState = {
  updated: false,
  clearForm: false,
  isLoading: false,
  error: null,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'cgvcgu',
  initialState,
  reducers: {
    setCgvCgu: state => {
      state.isLoading = true;
      state.error = null;
      state.updated = false;
      state.clearForm = false;
    },
    setCgvCguSuccess: state => {
      state.updated = true;
      state.isLoading = false;
      state.clearForm = true;
    },
    setCgvCguFailed: (state, action) => {
      state.error = action.payload.error;
      state.isLoading = false;
    },
  },
});

export const { setCgvCgu, setCgvCguSuccess, setCgvCguFailed } = slice.actions;

export default slice.reducer;
