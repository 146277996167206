import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getLendings, getStock, getSellers } from './core/slice';
import { Helmet } from 'react-helmet';
import { getLastDate } from 'utils/data';
import PortalLoader from 'components/Loader/PortalLoader';
import Container from 'components/Container';
import LendingChart from './components/LendingChart';
import ShortStockChart from './components/ShortStockChart';
import Sellers from './components/Sellers';
import './styles.scss';
import { format, parseISO } from 'date-fns';
import RefreshPortal from 'components/RefreshPortal';
import FullScreenChart from 'components/FullScreenChart';

interface UpdatedType {
  date: Date;
}

function Short() {
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState({ lendingZoom: false, shortZoom: false });
  const {
    lendables,
    shares,
    isLoading: isLoadingLending,
  } = useSelector((state: RootState) => state.short.lending);
  const { stocks, isLoading: isLoadingStock } = useSelector(
    (state: RootState) => state.short.stock,
  );
  const { sellers, isLoading: isLoadingSellers } = useSelector(
    (state: RootState) => state.short.sellers,
  );
  const [lastShortUpdated, setLastShortUpdate] = useState<Date>();
  const [lastStocksUpdated, setLastStockUpdate] = useState<Date>();

  function fetchDatas() {
    dispatch(getLendings());
    dispatch(getStock());
    dispatch(getSellers());
  }

  function closeAction() {
    setZoom({ lendingZoom: false, shortZoom: false });
  }

  useEffect(() => {
    fetchDatas();
  }, []);

  useEffect(() => {
    if (lendables?.data) setLastShortUpdate(getLastDate(lendables.data, 'createdAt'));
    if (stocks?.data) setLastStockUpdate(getLastDate(stocks.data, 'createdAt'));
  }, [lendables, stocks]);

  const LendingComponent = (
    <LendingChart loading={isLoadingLending} lendableData={lendables} sharesData={shares} />
  );

  const ShortComponent = <ShortStockChart loading={isLoadingLending} stockData={stocks} />;

  const LastUpdated = ({ date }: UpdatedType) => (
    <i className="last-updated">{`Last update ${format(date, 'do MMMM, K:mm b')}`}</i>
  );

  return (
    <Container title="Short" icon="short-white">
      <Helmet>
        <title>RMM - Short</title>
      </Helmet>
      <RefreshPortal action={() => fetchDatas()} />
      <div className="short__container">
        <div className="short__container-lending">
          {!zoom.lendingZoom && LendingComponent}
          {lastShortUpdated && <LastUpdated date={lastShortUpdated} />}
        </div>
        <div className="short__container-short">
          {!zoom.shortZoom && ShortComponent}
          {lastStocksUpdated && <LastUpdated date={lastStocksUpdated} />}
        </div>
        <div className="short__container-sellers">
          <Sellers loading={isLoadingSellers} sellers={sellers} />
          {sellers && sellers.length && <LastUpdated date={parseISO(sellers[0].createdAt)} />}
        </div>
      </div>

      {zoom.lendingZoom && (
        <FullScreenChart closeAction={closeAction}>{LendingComponent}</FullScreenChart>
      )}
      {zoom.shortZoom && (
        <FullScreenChart closeAction={closeAction}>{ShortComponent}</FullScreenChart>
      )}

      {isLoadingLending || isLoadingStock || isLoadingSellers ? <PortalLoader /> : ''}
    </Container>
  );
}

export default Short;
