import React from 'react';
import './styles.scss';

interface ErrorsProps {
  error: {
    detail: string;
    message: string;
  } | null;
}

function ErrorsContent({ error }: ErrorsProps) {
  if (!error) return null;
  let message;
  if (error.detail) {
    message = error.detail;
  } else {
    message = error.message;
  }
  return (
    <div className="form__errors">
      <div className="error">{message}</div>
    </div>
  );
}

export default ErrorsContent;
