import { createSlice } from '@reduxjs/toolkit';
import { WSSearch } from '../interfaces';
import uniqBy from 'lodash/uniqBy';

/** **** INTERFACES */

export interface ISearchState {
  word: string;
  type: string;
  search: WSSearch[];
  isLoading: boolean;
  error: Object | null;
  isLastPage: boolean;
  badge: {
    flash: number;
    report: number;
  };
}

/** **** INITIAL STATE */

const initialState: ISearchState = {
  word: '',
  type: 'ALL',
  search: [],
  isLoading: false,
  error: null,
  isLastPage: false,
  badge: {
    flash: 0,
    report: 0,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'flashs',
  initialState,
  reducers: {
    getSearch: (state, action) => {
      state.isLoading = true;
    },
    getSearchSuccess: (state, action) => {
      const prevSearch = JSON.parse(JSON.stringify(state.search));
      if (!action.payload.length) state.isLastPage = true;
      state.search = uniqBy([...prevSearch, ...action.payload], 'id');
      state.isLoading = false;
      if (state.type === 'ALL') {
        state.badge.flash = state.search.filter(item => item.type === 'FLASH').length;
        state.badge.report = state.search.filter(item => item.type === 'ANALYSIS').length;
      }
    },
    getSearchFailed: (state, action) => {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    clearSearch: state => {
      state.search = [];
      state.isLastPage = false;
    },
    setWord: (state, action) => {
      state.word = action.payload.word;
    },
    setType: (state, action) => {
      state.type = action.payload.type;
    },
  },
});

export const { getSearch, getSearchSuccess, getSearchFailed, clearSearch, setWord, setType } =
  slice.actions;

export default slice.reducer;
