import RequireAuth from 'app/RequireAuth';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CgvCgu from './cgv-cgu';
import Me from './me';
import PasswordChange from './password-change';
import './styles.scss';

function User() {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <RequireAuth>
            <Me />
          </RequireAuth>
        }
      />
      <Route
        path={`/cgv-cgu`}
        element={
          <RequireAuth>
            <CgvCgu />
          </RequireAuth>
        }
      />
      <Route
        path={`/password-change`}
        element={
          <RequireAuth>
            <PasswordChange />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default User;
