import React, { useContext, useEffect } from 'react';
import { ITicker, IPairInfo } from 'features/mkt-data/sector/core/interfaces';
import _ from 'lodash';
import cx from 'classnames';
import { Button } from 'components/Button';
import { RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'contexts/AppContext';
import {
  selectPairFilter,
  unselectPairFilter,
  savePairFilter,
  initializePairs,
} from '../core/slice';

const rowFactor = 35;

interface Props {
  pairInfo: IPairInfo[];
  onHide: Function;
  getChartPair: Function;
  fullScreen: boolean;
  resetFilter: Function;
}

export const FilterList = ({ pairInfo, onHide, getChartPair, fullScreen, resetFilter }: Props) => {
  const dispatch = useDispatch();
  const { data: user } = useSelector((state: RootState) => state.session.user);
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { tempPair: tempPairFilters, pair } = useSelector((state: RootState) => state.stock.filter);
  // have filters actived and are the same
  const noChanged = !!tempPairFilters.length && !!pair.length && _.isEqual(pair, tempPairFilters);

  function handleSelectFilter(tickerRic: string, target: any, isInput: boolean) {
    const checked = isInput ? target.checked : !target?.querySelector('input').checked;
    if (!checked) {
      // after unchecking it
      dispatch(unselectPairFilter({ pair: tickerRic }));
      return;
    }
    // after checking it
    var newTickerArr = _.xor(tempPairFilters, [tickerRic]);
    if (newTickerArr.length <= 6) {
      dispatch(selectPairFilter(newTickerArr));
    }
  }

  function saveFilterData() {
    // if no filter active then reset and leave
    if (!tempPairFilters.length) {
      resetFilter();
      onHide();
      return;
    }
    dispatch(savePairFilter());
    getChartPair(tempPairFilters);
    onHide(false);
  }

  const checkboxValue = (tickerRic: string) => {
    return tempPairFilters.includes(tickerRic);
  };

  const maxSelectedPair = () => {
    return tempPairFilters.length >= 6;
  };

  const numTickers = pairInfo.reduce((acc, item) => {
    return acc + item.tickers.length;
  }, 0);

  // When MOBILE & FULLSCREEN then add the height (it's rotated)
  const totalHeight =
    !isDesktopOrLaptop && fullScreen ? (numTickers + pairInfo.length) * rowFactor : 'auto';

  useEffect(() => {
    dispatch(initializePairs());
  }, []);

  return (
    <div className="container-filter" style={{ height: totalHeight }}>
      {pairInfo
        ?.filter(item => item.name !== 'x_daily_report_x')
        .map(item => (
          <div key={item.id}>
            <div className="stock-filter-title">{item.name}</div>
            <div className="stock-filter-item">
              {!item ? (
                <div className="stock-filter-ticker-item no-result">There is no result</div>
              ) : (
                item.tickers
                  .filter(item => {
                    return user?.emitter.name !== item.name;
                  })
                  .map((ticker: ITicker) => {
                    if (ticker?.ric) {
                      return (
                        <div
                          key={ticker.name}
                          role="presentation"
                          className={cx('stock-filter-ticker-item', {
                            'disable-checkbox': checkboxValue(ticker.ric),
                          })}
                          onClick={e => handleSelectFilter(ticker.ric, e.currentTarget, false)}
                        >
                          <input
                            type="checkbox"
                            className="checkbox"
                            disabled={maxSelectedPair()}
                            checked={checkboxValue(ticker.ric)}
                            onChange={e => handleSelectFilter(ticker.ric, e.currentTarget, true)}
                            readOnly
                          />
                          <div>
                            <p>
                              {ticker.name} <span className="exchange">({ticker.exchange})</span>
                            </p>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
              )}
            </div>
          </div>
        ))}
      {noChanged && (
        <div>
          <p className="txt-max-reached">Filters didn't change</p>
        </div>
      )}
      <div className="stock-filter__toolbar">
        <Button disabled={noChanged} onClick={saveFilterData}>
          Apply
        </Button>
      </div>
    </div>
  );
};
