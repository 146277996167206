import { takeEvery, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { login as loginAction, ILoginAction, loginSuccess, loginFailed } from './LoginSlice';
import request from '../../utils/request';
import { getAppInsights } from 'utils/telemetry';
import { getSession } from 'app/core/slice';
// import { AvailableResult, NativeBiometric } from 'capacitor-native-biometric';

interface LoginResult {
  accessToken: string;
  iat: number;
  expiration: number;
}

// const setBiometrics = async (
//   loginPayload: PayloadAction<ILoginAction>,
//   resultBiometrics: AvailableResult,
// ) => {
//   if (!resultBiometrics.isAvailable) {
//     console.log('Not available for FACE ID');
//   }
//   // set the credentials into Biometric when correct
//   NativeBiometric.setCredentials({
//     username: loginPayload.payload.email,
//     password: loginPayload.payload.password,
//     server: 'www.example.com',
//   });
//   localStorage.setItem('allowBiometricAuth', 'true');
// };

// const handleBiometrics = async (
//   loginPayload: PayloadAction<ILoginAction>,
//   resultBiometrics: AvailableResult,
// ) => {
//   const allowBiometrics = localStorage.getItem('allowBiometricAuth');
//   if (allowBiometrics !== 'true') {
//     const text = 'Do you want to enable biometric auth ?';
//     if (window.confirm(text) === true) {
//       await setBiometrics(loginPayload, resultBiometrics);
//     }
//   }
// };

function* login(loginPayload: PayloadAction<ILoginAction>) {
  const requestURL = '/api/auth/login';
  const platform = window.cordova ? 'mobile' : 'desktop';
  const body = { ...loginPayload.payload, platform, rememberMe: true };
  try {
    const result: LoginResult = yield call(request, requestURL, { method: 'POST', body });
    const { accessToken, expiration } = result;
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('exp', `${expiration}`);

    // try {
    //   const resultBiometrics: AvailableResult = yield call(NativeBiometric.isAvailable);
    //   yield handleBiometrics(loginPayload, resultBiometrics);
    // } catch (err) {
    //   if (err instanceof Error && err.message === 'Method not implemented.') {
    //     localStorage.setItem('allowBiometricAuth', 'false');
    //   } else {
    //     console.log('err', err, typeof err);
    //   }
    // }

    yield put(getSession());
    yield put(loginSuccess());
    const appInsights = getAppInsights();
    if (appInsights) {
      appInsights.trackEvent({ name: 'login' });
      const validatedId = loginPayload.payload.email.replace(/[,;=| ]+/g, '_');
      appInsights.setAuthenticatedUserContext(validatedId);
    }
    yield put(push('/'));
  } catch (err) {
    console.error(err);
    yield put(loginFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(loginAction.type, login)];
