import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValorisation } from './core/slice';
import { Helmet } from 'react-helmet';
import './assets/styles.scss';
import { RootState } from 'app/store';
import { AppContext } from 'contexts/AppContext';
import cx from 'classnames';
import Loader from 'components/Loader';
import ValorisationChart from './components/ValorisationChart';
import ValorisationLogo from './assets/factsetLogo.png';

export default function Valorisation() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.session.user.data?.emitter);
  const ratios = useSelector((state: RootState) => state.valorisation.valorisation);
  const { isDesktopOrLaptop } = useContext(AppContext);

  useEffect(() => {
    dispatch(getValorisation({ emitterName: user?.name || '' }));
  }, []);

  return (
    <div className="valorisation">
      <Helmet>
        <title>RMM - Valuation</title>
      </Helmet>
      <div className="valorisation-logo">
        <p>powered by</p>
        <img className="valorisation-logo-va" src={ValorisationLogo} alt="visible alpha logo" />
      </div>
      <div
        className={cx('charts', {
          desktop: isDesktopOrLaptop,
        })}
      >
        {ratios.isLoading && <Loader text="Loading..." />}
        {!ratios.isLoading && ratios.items === null && (
          <p>No valorisation available for your account</p>
        )}
        {!ratios.isLoading &&
        ratios.items !== null &&
        ratios.items.backdata &&
        ratios.items.backdata.peersBloomIds
          ? ratios.items.ratios.map(r => (
              <ValorisationChart ratio={r} peers={ratios.items!.backdata.peersBloomIds} />
            ))
          : null}
      </div>
    </div>
  );
}
