import React, { useContext, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import cx from 'classnames';
import './styles.scss';
import { AppContext } from 'contexts/AppContext';
interface Props {
  children?: React.ReactNode;
  closeAction: () => void;
  rotate?: boolean;
}
type ManageProps = 'hidden' | 'auto';

function manageScroll(value: ManageProps) {
  // enable/disable scroll when mode FullScreen
  document.body.style.overflow = value;
}
function FullScreenChart({ children, closeAction, rotate }: Props): JSX.Element {
  const icon = <IoMdClose size={32} />;
  const { innerWidth: width, innerHeight: height } = window;
  const { isDesktopOrLaptop } = useContext(AppContext);
  const screen = { width, height };
  const newChildren = React.cloneElement(children as React.ReactElement, {
    fullScreen: true,
    screen,
  });
  useEffect(() => {
    manageScroll('hidden');
    return () => manageScroll('auto');
  }, []);
  return (
    <div
      className={cx('fullscreen-chart', {
        desktop: isDesktopOrLaptop,
        mobile: !isDesktopOrLaptop,
      })}
    >
      <button
        aria-label="Close Chart"
        className="btn__rm-styles close-fullscreen"
        type="button"
        onClick={closeAction}
      >
        {icon}
      </button>
      <div className="fullscreen-chart-content">{newChildren}</div>
      <div className="fullscreen-chart-shadow" onClick={closeAction} role="presentation" />
    </div>
  );
}
export default FullScreenChart;
