import React from 'react';
import './styles.scss';

interface Props {
  componentElement: React.ElementType<any>;
  componentData: Array<any>;
  componentClassName?: string;
  selectedId?: string;
}

function SimpleList({
  componentElement: Component,
  componentData,
  componentClassName,
  selectedId,
}: Props) {
  return (
    <>
      <div className={`groupByData ${componentClassName}`}>
        {componentData.map(data => (
          <Component key={data.id} {...data} selectedId={selectedId} filename="Download report" />
        ))}
      </div>
    </>
  );
}

export default SimpleList;
