import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
} from 'chart.js';
import { ChartProps, Line } from 'react-chartjs-2';

import React from 'react';
import { ScreenProps } from 'components/FullScreenChart/interfaces';

const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart: any) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return (this.height += 20);
    };
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
  legendMargin,
);

export const defaultOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  elements: {
    point: {
      radius: 0.1,
    },
  },
  hover: {
    mode: 'point',
  },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 0,
      bottom: 10,
    },
  },
};

export type LineChartData = ChartData<'line'>;
export type LineChartOptions = ChartOptions<'line'>;

interface LineChartProps extends Omit<ChartProps, 'type'> {
  options?: LineChartOptions;
  data: LineChartData;
  className?: string;
  measures?: ScreenProps;
}

function LineChart({ options, data, className, measures, ...rest }: LineChartProps) {
  if (!data) return null;
  const optionsMerged = { ...defaultOptions, ...options };
  return <Line {...rest} className={className} options={optionsMerged} data={data} {...measures} />;
}
export default LineChart;
