/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-key */
import React from 'react';
import { IList, ITicker } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import QuoteValue from 'components/QuoteValue';
import { isExchangeClosed } from 'utils/data';

interface Props {
  list: IList;
  loading: boolean;
  isLast: boolean;
  snapshot: string;
  emitterName?: string;
  timeIntrvl?: string;
  isMobile?: boolean;
}

function List({ list, loading, isLast, snapshot, emitterName, timeIntrvl, isMobile }: Props) {
  const columns: Column<ITicker>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: 'alphanumeric',
        Cell: ({ row, value }: { row: { original: ITicker }; value: string }) => {
          let stars = '';
          if (row.original.isSnapshot) {
            stars = ' *';
          }
          return (
            <span className={cx({ me: row.original.isMe })}>
              {value}
              {stars}
            </span>
          );
        },
      },
      {
        Header: 'Price',
        accessor: x => (x.price ? parseFloat(x.price) : x.price),
        sortType: 'basic',
        Cell: (props: Cell) => {
          if (!props.value) {
            return <div style={{ fontWeight: 'bold' }}>{t('stats:sector.lists.noData')}</div>;
          }
          return <div>{parseFloat(props.value).toFixed(2)}</div>;
        },
      },
      {
        Header: 'Perf',
        accessor: x => (x.intervalPerf ? parseFloat(x.intervalPerf) : x.intervalPerf),
        sortType: 'basic',
        Cell: (props: Cell) => <QuoteValue type="%perf" value={`${props.value}%`} hasArrow />,
      },
      {
        Header: 'D %',
        id: 'dailyRepartition',
        accessor: x => (x.d ? parseFloat(x.d) : x.d),
        sortType: 'basic',
        Cell: (props: { row: { original: ITicker }; value: string }) => {
          if (isExchangeClosed(props.row.original.exchange)) {
            return <div>0%</div>;
          } else {
            return <QuoteValue type="daily" value={`${props.value}`} hasArrow />;
          }
        },
      },
      {
        Header: 'YTD %',
        id: 'yearlyRepartition',
        accessor: x => (x.ytd ? parseFloat(x.ytd) : x.ytd),
        sortType: 'basic',
        Cell: (props: Cell) => <QuoteValue type="ytd" value={`${props.value}`} hasArrow />,
      },
      {
        Header: 'M. CAP',
        id: 'marketCapitalisation',
        accessor: x => (x.mktCap ? parseInt(x.mktCap) : x.mktCap),
        sortType: 'basic',
        Cell: (props: Cell) => <QuoteValue type="mktCap" value={`${props.value}`} />,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: 'dailyRepartition', desc: true }],
    hiddenColumns: true ? ['marketCapitalisation'] : [],
  };

  const { t } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: list.tickers,
      initialState,
    },
    useSortBy,
  );

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (list.tickers.length === 0) {
    return <></>;
  }

  return (
    <div className="list pairs">
      <h4>{list.name}</h4>
      <div
        className={cx('sector-content', {
          'is-mobile': isMobile,
        })}
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<ITicker>) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  if (
                    timeIntrvl !== 'Today' &&
                    column.Header !== 'Price' &&
                    column.Header !== 'Name' &&
                    column.Header !== 'Perf'
                  ) {
                    return;
                  }
                  if (timeIntrvl === 'Today' && column.Header === 'Perf') {
                    return;
                  }
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={cx({
                        selected: column.isSorted,
                        fixed: column.Header === 'Name',
                        headerFixed: column.Header === 'Name',
                        'price-cell': column.Header === 'Price',
                      })}
                    >
                      {column.render('Header')}
                      {column.isSorted && (
                        <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<ITicker>, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={cx({
                    'emitter-highlight': emitterName === row.original.name.trim(),
                  })}
                >
                  {row.cells.map((cell: Cell<ITicker>) => {
                    if (
                      timeIntrvl !== 'Today' &&
                      cell.column.Header !== 'Price' &&
                      cell.column.Header !== 'Name' &&
                      cell.column.Header !== 'Perf'
                    ) {
                      return;
                    }
                    if (timeIntrvl === 'Today' && cell.column.Header === 'Perf') {
                      return;
                    }
                    return (
                      <td
                        className={cx({
                          fixed: cell.column.Header === 'Name',
                          'price-cell': cell.column.Header === 'Price',
                        })}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLast && snapshot && (
          <div className="pairs-legend">
            <p>* Snapshot {format(parseISO(snapshot), 'do MMMM, K:mm b')}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default List;
