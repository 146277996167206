import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

interface Props {
  logo: React.ReactNode;
  title: string;
  to: string;
  logoWhite: React.ReactNode;
  newDaily: boolean;
  newFlash: boolean;
  disabled?: boolean;
}

function Item({ logo, title, to, logoWhite, newDaily, newFlash, disabled }: Props) {
  const location = useLocation();
  const selected = location.pathname.startsWith(to);
  return (
    <Link
      to={to}
      className={cx('footer-item', {
        active: selected,
        disabled,
      })}
    >
      <div>
        {((newDaily && title === 'Daily report') || (newFlash && title === 'Flashs')) && (
          <div className="red-circle" />
        )}
      </div>
      <div>{selected ? logoWhite : logo}</div>
      <span className="inline-block">{title}</span>
    </Link>
  );
}

export default Item;
