import React, { useContext, useEffect, useState } from 'react';
import LineChart, { LineChartData, LineChartOptions } from 'components/Charts/LineChart';
import { useTranslation } from 'react-i18next';
import { getMeasures } from 'features/short/actions';
import { AppContext } from 'contexts/AppContext';
import { ITimeSeriesDataResponse } from 'utils/factset';
import { VscFilter, VscFilterFilled } from 'react-icons/vsc';
import Modal from 'components/Modal';
import { FactsetPeer } from '../core/types';
import { Button } from 'components/Button';
const COLORS = [
  '#001E41',
  '#ffcd00',
  '#ff6979',
  '#57ffbf',
  '#ffcacf',
  '#ff9e80',
  '#6F98A8',
  '#B24C63',
  '#FF4343',
  '#3B7EA1',
  '#F7D08A',
  '#EF7C8E',
];

const DEFAULT_OPTIONS: LineChartOptions = {
  scales: {
    x: {
      type: 'timeseries',
      time: {
        tooltipFormat: 'dd/MM/yyyy',
      },
      grid: {
        borderDash: [3, 1],
        display: true,
      },
    },
    y: {
      ticks: {
        padding: 10,
        showLabelBackdrop: true,
        backdropColor: 'white',
        backdropPadding: 5,
        color: '#011e41',
        font: {
          weight: 'bold',
          size: 11,
        },
      },
      grid: {
        borderDash: [3, 8],
        drawBorder: false,
        drawTicks: false,
      },
      position: 'left',
      title: {
        display: true,
        padding: {
          bottom: 10,
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: tooltipItem => `${tooltipItem.dataset.label} ${tooltipItem.raw}`,
      },
    },
    legend: {
      labels: {
        boxWidth: 30,
      },
    },
  },
};

interface ValorisationChartProps {
  ratio: ITimeSeriesDataResponse;
  peers: FactsetPeer[];
}

function ValorisationChart({ ratio, peers }: ValorisationChartProps) {
  const [dataPeRatio, setDataPeRatio] = useState<LineChartData>({ datasets: [] });
  const [pairFilters, setPairFilters] = useState<string[]>(peers?.map(p => p.factsetId)); // ['AAPL', 'MSFT'
  const [previousPairFilters, setPreviousPairFilters] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<LineChartOptions>({
    ...DEFAULT_OPTIONS,
  } as LineChartOptions);
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { measures } = getMeasures(isDesktopOrLaptop, false, 30);

  const openModal = () => {
    setPreviousPairFilters(pairFilters);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPairFilters(previousPairFilters);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const newdatasets: LineChartData = {
      labels: ratio.graphData[0].dates,
      datasets: ratio.graphData
        .filter(d => pairFilters.includes(d.originalId))
        .map((d, i) => ({
          data: d.values.map(v => (v ? parseFloat(v?.toFixed(1)) : null)),
          fill: false,
          borderColor: COLORS[i],
          backgroundColor: COLORS[i],
          label: d.id,
          borderWidth: 2,
          tension: 0,
        })),
    };
    const newOptions = {
      ...options,
      scales: {
        ...options.scales,
        y: {
          ...options.scales?.y,
          title: {
            ...options.scales?.y?.title,
            text: ratio.graphKey,
          },
        },
      },
    };
    setOptions(newOptions);
    setDataPeRatio(newdatasets);
  }, [ratio, pairFilters]);

  return (
    <div className="peratio">
      <div className="title">
        <h2>{ratio.graphKey}</h2>
        <button onClick={openModal} className="button-opener" type="button">
          <p className="text-filter">Peers</p>
          {!pairFilters.length ? (
            <VscFilter className="icon-filter" />
          ) : (
            <VscFilterFilled className="icon-filter" />
          )}
        </button>
      </div>
      <div className="stockchart" style={{ ...measures }}>
        {!ratio ? (
          <div className="stockchart" style={{ height: '300px' }}>
            <div className="no-data">{t('short:stock.noData')}</div>
          </div>
        ) : (
          <LineChart
            className="stockchart-linechart"
            options={options}
            data={dataPeRatio}
            id="lending-chart-short"
            measures={measures}
          />
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="peers-filter-title">
          <span className="peers-filter-cancel" role="button" onClick={closeModal}>
            Cancel
          </span>
          <h2>Peers</h2>
        </div>
        <div className="pair-modal">
          {peers?.map(p => (
            <div className="peer">
              <input
                type="checkbox"
                id={p.factsetId}
                name={p.factsetId}
                value={p.factsetId}
                checked={pairFilters.includes(p.factsetId)}
                onChange={e => {
                  if (e.target.checked) {
                    setPairFilters([...pairFilters, e.target.value]);
                  } else {
                    setPairFilters(pairFilters.filter(f => f !== e.target.value));
                  }
                }}
              />
              <label htmlFor={p.factsetId}>{p.factsetName}</label>
            </div>
          ))}
        </div>
        <div className="peers-footer">
          <Button onClick={() => setIsModalOpen(false)}>Apply</Button>
        </div>
      </Modal>
    </div>
  );
}

export default React.memo(ValorisationChart);
