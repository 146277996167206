import { createSlice } from '@reduxjs/toolkit';
import { IChart, IVolumesComparision } from 'components/StockChart/interfaces';
import { IMonitoringBlock, IQuote } from './interfaces';
import { ITradedVolume, IActiveBrokerMapped } from 'features/daily-report/core/interfaces';
import { IPairInfo } from 'features/mkt-data/sector/core/interfaces';
import union from 'lodash/union';

/** **** INTERFACES */

export interface IStockState {
  filter: {
    date: {
      dateStart: null;
      dateEnd: null;
    };
    pair: Array<string>;
    tempPair: Array<string>;
    range: string;
  };
  quote: {
    isLoading: boolean;
    data: IQuote | null;
    error?: Record<string, unknown>;
  };
  chart: {
    isLoading: boolean;
    data: IChart | null;
    error?: Record<string, unknown>;
  };
  volumesComparision: {
    isLoading: boolean;
    data: IVolumesComparision[] | null;
    error?: Record<string, unknown>;
  };
  indices: {
    isLoading: boolean;
    items: [] | IChart[] | null;
    error?: Record<string, unknown>;
  };
  tradedVolumes: {
    data: [] | ITradedVolume[];
    isLoading: boolean;
    error?: Record<string, unknown> | null;
  };
  monitoringBlocks: {
    data: [] | IMonitoringBlock[];
    isLoading: boolean;
    error?: Record<string, unknown> | null;
  };
  mostActiveBrokers: {
    data: [] | IActiveBrokerMapped[];
    isLoading: boolean;
    error?: Record<string, unknown> | null;
  };
  pairInfo: {
    data: [] | IPairInfo[];
    isLoading: boolean;
    error?: Record<string, unknown> | null;
  };
}

/** **** INITIAL STATE */

const initialState: IStockState = {
  filter: {
    date: {
      dateStart: null,
      dateEnd: null,
    },
    pair: [],
    tempPair: [],
    range: '1D',
  },
  quote: {
    isLoading: true,
    error: undefined,
    data: null,
  },
  chart: {
    isLoading: true,
    error: undefined,
    data: null,
  },
  volumesComparision: {
    isLoading: true,
    error: undefined,
    data: null,
  },
  indices: {
    isLoading: true,
    error: undefined,
    items: null,
  },
  tradedVolumes: {
    data: [],
    isLoading: false,
    error: null,
  },
  monitoringBlocks: {
    data: [],
    isLoading: false,
    error: null,
  },
  mostActiveBrokers: {
    data: [],
    isLoading: false,
    error: null,
  },
  pairInfo: {
    data: [],
    isLoading: false,
    error: null,
  },
};

/** **** SLICE */
export const slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    getQuotes: state => {
      state.quote.isLoading = true;
    },
    getQuotesSuccess: (state, action) => {
      state.quote.data = action.payload;
      state.quote.isLoading = false;
    },
    getQuotesFailed: (state, action) => {
      state.quote.error = action.payload.error;
      state.quote.isLoading = false;
    },
    getChart: (state, payload) => {
      state.chart.isLoading = true;
    },
    getChartSuccess: (state, action) => {
      state.chart.data = action.payload;
      state.chart.isLoading = false;
    },
    getChartFailed: (state, action) => {
      state.chart.error = action.payload.error;
      state.chart.isLoading = false;
    },
    getVolumesComparision: (state, payload) => {
      state.volumesComparision.isLoading = true;
    },
    getVolumesComparisionSuccess: (state, action) => {
      state.volumesComparision.data = action.payload;
      state.volumesComparision.isLoading = false;
    },
    getVolumesComparisionFailed: (state, action) => {
      state.volumesComparision.error = action.payload.error;
      state.volumesComparision.isLoading = false;
    },
    getIndicesChart: (state, payload) => {
      state.indices.isLoading = true;
    },
    getIndicesChartSuccess: (state, action) => {
      state.indices.items = action.payload;
      state.indices.isLoading = false;
    },
    getIndicesChartFailed: (state, action) => {
      state.indices.error = action.payload.error;
      state.indices.isLoading = false;
    },
    clearState: state => {
      state.chart = initialState.chart;
      state.indices = initialState.indices;
    },
    getTradedVolumes: state => {
      state.tradedVolumes.isLoading = true;
    },
    getTradedVolumesSuccess: (state, action) => {
      state.tradedVolumes.data = action.payload;
      state.tradedVolumes.isLoading = false;
    },
    getTradedVolumesFailed: (state, action) => {
      state.tradedVolumes.error = action.payload.error;
      state.tradedVolumes.isLoading = false;
    },
    getMonitoringBlocks: state => {
      state.monitoringBlocks.isLoading = true;
    },
    getMonitoringBlocksSuccess: (state, action) => {
      state.monitoringBlocks.data = action.payload;
      state.monitoringBlocks.isLoading = false;
    },
    getMonitoringBlocksFailed: (state, action) => {
      state.monitoringBlocks.error = action.payload.error;
      state.monitoringBlocks.isLoading = false;
    },
    getMostActiveBrokers: state => {
      state.mostActiveBrokers.isLoading = true;
    },
    getMostActiveBrokersSuccess: (state, action) => {
      state.mostActiveBrokers.data = action.payload;
      state.mostActiveBrokers.isLoading = false;
    },
    getMostActiveBrokersFailed: (state, action) => {
      state.mostActiveBrokers.error = action.payload.error;
      state.mostActiveBrokers.isLoading = false;
    },
    getPairInfo: state => {
      state.pairInfo.isLoading = true;
    },
    getPairInfoSuccess: (state, action) => {
      state.pairInfo.data = action.payload;
      state.pairInfo.isLoading = false;
    },
    getPairInfoFailed: (state, action) => {
      state.pairInfo.error = action.payload.error;
      state.pairInfo.isLoading = false;
    },

    initializePairs: state => {
      // take all pairs into temporary pairs
      state.filter.tempPair = JSON.parse(JSON.stringify(state.filter.pair));
    },
    selectPairFilter: (state, action) => {
      const tempPair = JSON.parse(JSON.stringify(state.filter.tempPair));
      state.filter.tempPair = union([...tempPair, ...action.payload]);
    },
    unselectPairFilter: (state, action) => {
      // remove single pair filter
      const { pair } = action.payload;
      const tempPairs = JSON.parse(JSON.stringify(state.filter.tempPair));
      const newPairs = tempPairs.filter((value: string) => value !== pair);
      state.filter.tempPair = newPairs;
    },
    savePairFilter: state => {
      // after save button store the new temp pairs
      const tempPair = JSON.parse(JSON.stringify(state.filter.tempPair));
      state.filter.pair = tempPair; // swap the data
      state.filter.tempPair = []; // reset the temporal ones
    },
    resetPairFilter: (state): void => {
      // reset all pair filters temps and normal ones
      state.filter.pair = [];
      state.filter.tempPair = [];
    },
    cancelPairFilter: (state): void => {
      // cancel all pair filters temps and normal ones
      state.filter.tempPair = [];
    },
    addDateFilter: (state, action) => {
      const { day, type } = action.payload;
      state.filter.date[type] = day;
    },
    clearDateFilter: (state): void => {
      state.filter.date = {
        dateStart: null,
        dateEnd: null,
      };
    },
    setRangeFilter: (state, action) => {
      const { range } = action.payload;
      state.filter.range = range;
    },
  },
});

export const {
  getQuotes,
  getQuotesSuccess,
  getQuotesFailed,
  getChart,
  getChartSuccess,
  getChartFailed,
  getVolumesComparision,
  getVolumesComparisionSuccess,
  getVolumesComparisionFailed,
  getIndicesChart,
  getIndicesChartSuccess,
  getIndicesChartFailed,
  clearState,
  getTradedVolumes,
  getTradedVolumesSuccess,
  getTradedVolumesFailed,
  getMonitoringBlocks,
  getMonitoringBlocksSuccess,
  getMonitoringBlocksFailed,
  getMostActiveBrokers,
  getMostActiveBrokersSuccess,
  getMostActiveBrokersFailed,
  getPairInfo,
  getPairInfoSuccess,
  getPairInfoFailed,
  selectPairFilter,
  resetPairFilter,
  addDateFilter,
  clearDateFilter,
  setRangeFilter,
  unselectPairFilter,
  savePairFilter,
  cancelPairFilter,
  initializePairs,
} = slice.actions;

export default slice.reducer;
