import { createSlice } from '@reduxjs/toolkit';
import { WSFlash } from '../../interfaces';
import uniqBy from 'lodash/uniqBy';

/** **** INTERFACES */

export interface IFlashsState {
  flashs: WSFlash[];
  isLoading: boolean;
  error: Object | null;
  isLastPage: boolean;
}

/** **** INITIAL STATE */

const initialState: IFlashsState = {
  flashs: [],
  isLoading: false,
  error: null,
  isLastPage: false,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'flashs',
  initialState,
  reducers: {
    getFlashs: (state, action) => {
      state.isLoading = true;
    },
    getFlashsSuccess: (state, action) => {
      const prevFlashes = JSON.parse(JSON.stringify(state.flashs));
      if (!action.payload.length) state.isLastPage = true;
      state.flashs = uniqBy([...prevFlashes, ...action.payload], 'id');
      state.isLoading = false;
    },
    getFlashsFailed: (state, action) => {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    clearFlashs: state => {
      state.flashs = [];
      state.isLastPage = false;
    },
  },
});

export const { getFlashs, getFlashsSuccess, getFlashsFailed, clearFlashs } = slice.actions;

export default slice.reducer;
