import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Flash from './detail';
import Home from './home';
import { AppContext } from 'contexts/AppContext';
import './styles.scss';
import { WSFlash } from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getFlashs } from './home/core/slice';
import RequireAuth from 'app/RequireAuth';

function Flashs() {
  const dispatch = useDispatch();
  const { isDesktopOrLaptop, isPortrait } = useContext(AppContext);
  const flashs: WSFlash[] = useSelector((state: RootState) => state.flashs.flashs);

  useEffect(() => {
    if (!flashs) dispatch(getFlashs({ page: 0 }));
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        {!isDesktopOrLaptop || isPortrait ? (
          <Route
            path={`:id`}
            element={
              <RequireAuth>
                <Flash />
              </RequireAuth>
            }
          />
        ) : null}
      </Routes>
    </>
  );
}

export default Flashs;
