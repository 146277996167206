import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';

interface IRequireNotAuthProps {
  children: JSX.Element;
}

function RequireNotAuth({ children }: IRequireNotAuthProps) {
  if (isAuthenticated()) {
    return <Navigate to="/statistics" />;
  }
  return children;
}

export default RequireNotAuth;
