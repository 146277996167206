import { call, put, takeEvery } from 'redux-saga/effects';
import request from '../../../utils/request';
import { parseISO } from 'date-fns'; // eslint-disable-line
import { WSSearch } from '../interfaces';
import { getSearch, getSearchFailed, getSearchSuccess } from './slice';

interface Payload {
  page: number;
  word: string;
  type: 'flash' | 'daily';
}
interface Props {
  payload: Payload;
  type: string;
}

function sortByDate(chartData: WSSearch[]) {
  const datesSorted = chartData.sort((a, b) => {
    const dateA = +parseISO(a.createdAt);
    const dateB = +parseISO(b.createdAt);
    return dateA + dateB;
  });
  return datesSorted;
}

function* search(props: Props) {
  const { word, page, type } = props.payload;
  const requestURL = `/api/search?text=${word}&type=${type}&page=${page}`;
  try {
    const result: WSSearch[] = yield call(request, requestURL, { method: 'GET' });
    const searchsByDate = sortByDate(result);
    yield put(getSearchSuccess(searchsByDate));
  } catch (err) {
    console.error('err', err);
    yield put(getSearchFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(getSearch.type, search)];
