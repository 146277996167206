import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/request';
import {
  getQuotes as getQuotesAction,
  getQuotesSuccess,
  getQuotesFailed,
  getAnalysis as getAnalysisAction,
  getAnalysisSuccess,
  getAnalysisFailed,
  getTradedVolumes as getTradedVolumesAction,
  getTradedVolumesSuccess,
  getTradedVolumesFailed,
  getMostActiveBrokers as getMostActiveBrokersAction,
  getMostActiveBrokersSuccess,
  getMostActiveBrokersFailed,
  getEconomicStatistics as getEconomicStatisticsAction,
  getEconomicStatisticsSuccess,
  getEconomicStatisticsFailed,
  getAgenda as getAgendaAction,
  getAgendaSuccess,
  getAgendaFailed,
} from './slice';
import {
  IAnalysis,
  ITradedVolume,
  IActiveBroker,
  IEconomicStatistic,
  IAgenda,
  IAgendaBackend,
  IActiveBrokerMapped,
} from './interfaces';
import { IQuote } from 'features/mkt-data/stock/core/interfaces';
import { parseISO, format, isBefore, isAfter } from 'date-fns';

function* getQuotes(quotesPayload: PayloadAction) {
  const requestURL = `/api/reports/quotes?date=${
    quotesPayload.payload !== null ? quotesPayload.payload : format(new Date(), 'yyyy-MM-dd')
  }`;
  try {
    const result: IQuote = yield call(request, requestURL, { method: 'GET' });
    yield put(getQuotesSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getQuotesFailed({ error: err.responseJSON }));
  }
}

function* getAnalysis(analysisPayload: PayloadAction) {
  const requestURL = `/api/reports/analysis${
    analysisPayload.payload !== null ? `?date=${analysisPayload.payload}` : ''
  }`;
  try {
    const result: IAnalysis = yield call(request, requestURL, { method: 'GET' });
    yield put(getAnalysisSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getAnalysisFailed({ error: err.responseJSON }));
  }
}

function* getTradedVolumes(volumesPayload: PayloadAction) {
  const requestURL = `/api/reports/volumes/closed-session${
    volumesPayload.payload !== null ? `?date=${volumesPayload.payload}` : ''
  }`;
  try {
    const result: Array<ITradedVolume> = yield call(request, requestURL, { method: 'GET' });
    yield put(getTradedVolumesSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getTradedVolumesFailed({ error: err.responseJSON }));
  }
}

function* getMostActiveBrokers(brokersPayload: PayloadAction) {
  const requestURL = `/api/reports/statistics/brokers/closed-session${
    brokersPayload.payload !== null ? `?date=${brokersPayload.payload}` : ''
  }`;
  try {
    const result: Array<IActiveBroker> = yield call(request, requestURL, { method: 'GET' });
    const mappedResult: Array<IActiveBrokerMapped> = result.map((item: IActiveBroker) => ({
      detail: { name: item.name, comments: item.comments },
      volume: item.volume,
      updatedAt: item.updatedAt,
    }));
    yield put(getMostActiveBrokersSuccess(mappedResult));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getMostActiveBrokersFailed({ error: err.responseJSON }));
  }
}

function* getEconomicStatistics(economicStatisticPayload: PayloadAction) {
  let requestURL = '/api/reports/statistics/economic';
  if (economicStatisticPayload.payload !== null) {
    requestURL += `?date=${economicStatisticPayload.payload}`;
  }
  try {
    const result: Array<IEconomicStatistic> = yield call(request, requestURL, { method: 'GET' });
    yield put(getEconomicStatisticsSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getEconomicStatisticsFailed({ error: err.responseJSON }));
  }
}

function* getAgenda(agendaPayload: PayloadAction) {
  let requestURL = '/api/reports/agenda';
  if (agendaPayload.payload !== null) {
    requestURL += `?date=${agendaPayload.payload}`;
  }
  try {
    const result: IAgendaBackend[] = yield call(request, requestURL, { method: 'GET' });
    const agenda: IAgenda[] = result
      .map(r => ({
        data: r.objet,
        name: r.pair,
        date: format(parseISO(r.date), 'dd/MM/yyyy'),
        dateRaw: r.date,
        createdAt: r.createdAt,
      }))
      .sort((a, b) => {
        const aDate = parseISO(a.dateRaw);
        const bDate = parseISO(b.dateRaw);
        if (isBefore(aDate, bDate)) return -1;
        if (isAfter(aDate, bDate)) return 1;
        return 0;
      });
    yield put(getAgendaSuccess(agenda));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getAgendaFailed({ error: err.responseJSON }));
  }
}

export default [
  takeEvery(getQuotesAction.type, getQuotes),
  takeEvery(getAnalysisAction.type, getAnalysis),
  takeEvery(getTradedVolumesAction.type, getTradedVolumes),
  takeEvery(getMostActiveBrokersAction.type, getMostActiveBrokers),
  takeEvery(getEconomicStatisticsAction.type, getEconomicStatistics),
  takeEvery(getAgendaAction.type, getAgenda),
];
