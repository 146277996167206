import { RootState } from 'app/store';
import Container from 'components/Container';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WSFlash } from '../interfaces';
import './assets/styles.scss';
import { getFlashs, clearFlashs } from './core/slice';
import SimpleList from 'components/SimpleList';
import FlashListCard from './FlashCard';
import { AppContext } from 'contexts/AppContext';
import cx from 'classnames';
import { Routes, Route } from 'react-router-dom';
import Flash from '../detail';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import RefreshPortal from 'components/RefreshPortal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/Loader';

function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktopOrLaptop, isPortrait } = useContext(AppContext);
  const flashs: WSFlash[] = useSelector((state: RootState) => state.flashs.flashs);
  const isLastPage = useSelector((state: RootState) => state.flashs.isLastPage);
  const isLoading = useSelector((state: RootState) => state.flashs.isLoading);
  const [flashId, setFlashId] = useState<string>();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const page = useRef(0);
  const firstFlash = flashs?.[0]?.id;
  const loader = !isLastPage && isScrolled ? <Loader text="Loading..." /> : null;

  useEffect((): (() => void) => {
    dispatch(getFlashs({ page: 0 }));
    // clear all slices properties
    return () => dispatch(clearFlashs());
  }, []);

  function getCardId(cardId: string): void {
    setFlashId(cardId);
  }

  const fetchMoreData = () => {
    !isLastPage && dispatch(getFlashs({ page: ++page.current }));
    setIsScrolled(true);
  };

  return (
    <Container title="Flash" icon="flash-white">
      <RefreshPortal action={() => dispatch(getFlashs({}))} />
      <Helmet>
        <title>RMM - Flashs</title>
      </Helmet>
      <>
        {/* we avoid the switch in order to not mount/unmont the list, because it produces jumps */}
        {!isLoading && flashs.length === 0 && (
          <div className="no-results__message">{t('flashs:p.noData')}</div>
        )}
        <div
          className={cx('flashs__content', {
            flashs__desktop: isDesktopOrLaptop,
          })}
        >
          <div className="flashs__byDate">
            <InfiniteScroll
              dataLength={flashs.length}
              next={fetchMoreData}
              hasMore={true}
              loader={loader}
            >
              <SimpleList
                componentElement={FlashListCard}
                componentData={flashs}
                componentClassName="flashs"
                selectedId={flashId}
              />
            </InfiniteScroll>
          </div>
          <Routes>
            {isDesktopOrLaptop && !isPortrait ? (
              <Route path=":id" element={<Flash parentCallback={getCardId} />} />
            ) : null}
            {isDesktopOrLaptop && !isPortrait && firstFlash ? (
              <Route
                path="./"
                element={<Flash firstFlash={firstFlash} parentCallback={getCardId} />}
              />
            ) : null}
          </Routes>
        </div>
      </>
    </Container>
  );
}

export default Home;
