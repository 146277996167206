import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCgvCgu } from './core/slice';
import cx from 'classnames';
import './styles.scss';
import CustomIcon from 'components/CustomIcon';
import { AppContext } from 'contexts/AppContext';
import Loader from 'components/Loader';
import { CgvData } from './core/interfaces';

interface ResultsProps {
  updated: boolean;
  error?: {
    status: number;
    message: string;
  } | null;
}

function ResultContent({ updated, error }: ResultsProps) {
  if (updated === false && !error) return null;
  let message;
  if (updated) {
    message = 'Updated';
  } else if (error && error.status === 400) {
    message = error.message;
  }
  return (
    <div
      className={cx('password-change__result', {
        result__error: error,
        result__ok: updated,
      })}
    >
      {message}
    </div>
  );
}

function CgvCgu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { register, handleSubmit, formState, reset } = useForm<CgvData>({
    mode: 'onChange',
  });
  const { updated, clearForm, isLoading, error } = useSelector((state: RootState) => state.cgvcgu);
  const cgvState = useSelector((state: RootState) => state.session.user.data?.cgu);

  useEffect(() => {
    if (clearForm) reset();
  }, [clearForm]);

  function onSubmit() {
    dispatch(setCgvCgu());
  }

  return (
    <div className="app-container-wrapper background nooverflow">
      <div className="app-container app-container__login app-container__cgv">
        <div className="logo-full__container">
          <CustomIcon type="logo-full" />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={cx('login__container', {
            desktop: isDesktopOrLaptop,
          })}
        >
          {cgvState ? (
            <div className="notice__message">
              Welcome! Please approve the CGV and CGU after the first login.
            </div>
          ) : (
            ''
          )}
          <div className="login__input-container cgv__checkbox">
            <input id="cgu" type="checkbox" value="true" {...register('cgu', { required: true })} />
            <span>
              {t('cgv:cgv.text')}{' '}
              <a href={process.env.REACT_APP_CGU_URL} target="_blank" rel="noopener noreferrer">
                {t('cgv:cgu.textUrl')}
              </a>
            </span>
          </div>
          {isLoading ? (
            <div className="isLoading">
              <Loader />
            </div>
          ) : (
            ''
          )}
          <ResultContent updated={updated} error={error} />
          <div className="password__button-container">
            <button disabled={!formState.isValid} type="submit">
              Accept
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CgvCgu;
