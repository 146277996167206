import { isWeekend, isWithinInterval, formatRelative, parseISO, isEqual } from 'date-fns'; // eslint-disable-line
import { enGB } from 'date-fns/locale'; // eslint-disable-line
import Holidays from 'date-holidays';
import { groupBy as _groupBy } from 'lodash';
import { ArrayOfDates } from './interfaces';
import { IChart, IChartPrice } from 'components/StockChart/interfaces';
import { format, utcToZonedTime } from 'date-fns-tz';
import { marketsHolidays } from './marketHolidays';

export const holidayRules = [
  '01-01',
  'easter -2',
  'easter 1',
  '05-01',
  '12-24 14:30 PT9H30M',
  '12-25',
  '12-31 14:30 PT9H30M',
];

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLastDate(data: any[], field: string) {
  return new Date(Math.max(...data.map(e => new Date(parseISO(e[field])).getTime())));
}

export function dateFormatting(dateString: string) {
  const date = formatRelative(parseISO(dateString), new Date(), { locale: enGB });

  return date;
}

export function groupAndOrderByDate(arrayOfDates: ArrayOfDates[]) {
  const propToEvaluate = arrayOfDates[0].createdAt ? 'createdAt' : 'dateTime';
  const datesSorted = arrayOfDates.sort((a, b) => {
    const dateA = +parseISO(a[propToEvaluate]);
    const dateB = +parseISO(b[propToEvaluate]);
    return dateB - dateA;
  });
  const groupingByDate = _groupBy(datesSorted, element => element[propToEvaluate].substring(0, 10));
  const formattedGroupByDate = Object.entries(groupingByDate).map(obj => ({
    date: obj[0],
    data: obj[1],
  }));
  return formattedGroupByDate;
}

export function sortByDate(chartData: IChartPrice[]) {
  const datesSorted = chartData.sort((a, b) => {
    const dateA = +parseISO(a.timestamp);
    const dateB = +parseISO(b.timestamp);
    return dateA - dateB;
  });
  return datesSorted;
}

export function numbersSpaceSeparator(num: number | string, fixed = true) {
  let number = num;
  if (typeof number === 'string') {
    number = number.replace(/_([^_]*)$/, '.$1');
    if (number.includes(',')) number = number.replace(',', '.');
    number = parseFloat(number);
  }
  const roundedNum = Math.round(number * 100) / 100;
  let roundedNumTxt = roundedNum.toString();
  if (fixed && num < 1000) roundedNumTxt = roundedNum.toFixed(2);
  return roundedNumTxt.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function shouldShowGraph(range: string, chartData: IChart[]) {
  const [hour, minutes] = getTZHoursFromUTCHoursNow().split(':');
  const isAfterNine =
    parseInt(hour, 10) > 9 || (parseInt(hour, 10) === 9 && parseInt(minutes, 10) >= 5);
  const now = new Date();
  return (
    range !== '1D' || // Toujours afficher les graph différents de 1D
    (chartData.length > 0 && !isWeekend(now) && isAfterNine)
  ); // Ne pas afficher les graphs avant 9h et les weekends, ou s'il n'y a pas de resultat
}

export function changeTimezone(date: Date, ianatz: string) {
  // so 12:00 in Toronto is 17:00 UTC
  return new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );
}

export function getMarketHolidays(year: number) {
  const hd = new Holidays('FR');
  hd.setHoliday('easter -2', { name: { fr: 'Vendredi saint' }, type: 'public' });
  hd.setHoliday('12-31 14:30 PT9H30M', { name: { fr: 'Veille du nouvel an' }, type: 'bank' });
  hd.setHoliday('12-24 14:30 PT9H30M', { name: { fr: 'Veille de noël' }, type: 'bank' });
  const yearHolidays = hd.getHolidays(year);
  const marketHolidays = yearHolidays.filter(h => holidayRules.indexOf(h.rule) !== -1);
  return marketHolidays;
}

function isMarketsHolidays(market: string, rules: string[], weekdays: string[]) {
  let weekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date());
  const year = new Date().getFullYear();
  const today = parseISO(format(new Date(), 'yyyy-MM-dd'));
  const hd = new Holidays(market);
  const yearHolidays = hd.getHolidays(year);
  const marketHolidays = yearHolidays.filter(h => rules?.indexOf(h.rule) !== -1);
  let result = false;
  marketHolidays.forEach(holiday => {
    const date = parseISO(holiday.date);
    if (isEqual(date, today) || weekdays.indexOf(weekDay) === -1) {
      result = true;
    }
  });
  return result;
}

export function isExchangeClosed(exchange: string) {
  let result = false;
  marketsHolidays.forEach(market => {
    market.name.forEach(marketName => {
      if (marketName === exchange) {
        result = isMarketsHolidays(market.country_code, market.holiday_rules, market.weekdays);
      }
    });
  });
  return result;
}

export function isSessionOpened(isFive?: boolean) {
  const now = new Date();
  // const now = new Date(2020, 7, 2, 7, 0, 0);
  // Concerning the day
  // Weekend -> off
  if (isWeekend(now)) return false;

  // Special holidays -> off
  const marketHolidays = getMarketHolidays(now.getFullYear());
  let isHoliday = false;
  let i = 0;
  while (i < marketHolidays.length && isHoliday === false) {
    try {
      const inInterval = isWithinInterval(now, {
        start: marketHolidays[i].start,
        end: marketHolidays[i].end,
      });
      if (inInterval) isHoliday = true;
    } catch (err) {
      console.error(err);
    }
    i += 1;
  }
  if (isHoliday) return false;

  // before 9 & after 17h30 -> off
  const hoursNow = changeTimezone(now, 'Europe/Paris').getHours();
  const minutesNow = changeTimezone(now, 'Europe/Paris').getMinutes();
  if (hoursNow < 9) return false;
  if (isFive && hoursNow === 9 && minutesNow < 5) return false;
  if (hoursNow >= 17 && minutesNow > 30) return false;
  return true;
}

export type TypeEnum = 'ytd' | 'daily' | 'mktCap' | '%perf';

export function getLevel(variation: number, type: TypeEnum) {
  if (type === 'daily' || type === 'ytd' || type === '%perf') {
    if (variation >= 0) return '--color-positive-1';
    return '--color-negative-1';
  }
  return '';
}

export function getRecommendationType(type: string | undefined) {
  let typeName = 'neutral';
  switch (type?.toLowerCase()) {
    case 'acheter':
    case 'buy':
    case 'accumulate':
    case 'buy cl':
    case 'overweight':
    case 'outperform':
    case 'renforcer':
      typeName = 'buy';
      break;
    case 'alléger':
    case 'sell':
    case 'vente':
    case 'reduce':
    case 'underperform':
    case 'vendre':
    case 'underweight':
      typeName = 'sell';
      break;
  }
  return typeName;
}

export function getTZHoursFromUTCHoursNow() {
  const time = new Date().toUTCString().substring(17, 25);
  const date = new Date();
  const [hours, minute, second] = time.split(':');
  date.setUTCHours(parseInt(hours, 10));
  date.setUTCMinutes(parseInt(minute, 10));
  date.setUTCSeconds(parseInt(second, 10));
  const dateUTC = utcToZonedTime(date, 'Europe/Paris');
  const dateFormatted = `${format(dateUTC, 'HH:mm')}`;
  return dateFormatted;
}

export function getTZHoursFromUTCHours(time: string) {
  if (!time) {
    return '-';
  }
  const date = new Date();
  const [hours, minute, second] = time.split(':');
  date.setUTCHours(parseInt(hours, 10));
  date.setUTCMinutes(parseInt(minute, 10));
  date.setUTCSeconds(parseInt(second, 10));
  const dateUTC = utcToZonedTime(date, 'Europe/Paris');
  const dateFormatted = `${format(dateUTC, 'HH:mm')}`;
  return dateFormatted;
}
