import { combineReducers, configureStore } from '@reduxjs/toolkit';
import goBackReducer from 'components/GoBack/slice';
import detailReducer from 'features/flashs/detail/core/slice';
import flashsReducer from 'features/flashs/home/core/slice';
import loginReducer from 'features/login/LoginSlice';
import passwordReducer from 'features/user/password-change/core/slice';
import cgvReducer from 'features/user/cgv-cgu/core/slice';
import appReducer from './core/slice';
import stockReducer from 'features/mkt-data/stock/core/slice';
import shortReducer from 'features/short/core/slice';
import sendEmailReducer from 'features/reset-password/send-email/core/slice';
import sendPasswordReducer from 'features/reset-password/send-password/core/slice';
import sectorReducer from 'features/mkt-data/sector/core/slice';
import valuationReducer from 'features/mkt-data/valuations/core/slice';
import consensusReducer from 'features/brokers-note/consensus/core/slice';
import brokersReducer from 'features/brokers-note/core/slice';
import valorisationReducer from 'features/brokers-note/valorisation/core/slice';
import reportReducer from 'features/daily-report/core/slice';
import searchReducer from 'features/search/core/slice';
import { createBrowserHistory, History } from 'history';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import saga from './root-saga';
import { createReduxHistoryContext } from 'redux-first-history';

const devMode = process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const middleware = [sagaMiddleware, routerMiddleware];

if (devMode && process.env.REACT_APP_REDUX_LOGGER !== 'false') {
  middleware.push(logger);
}

const rootReducer = combineReducers({
  router: routerReducer,
  session: appReducer,
  login: loginReducer,
  goback: goBackReducer,
  flashs: flashsReducer,
  flashDetail: detailReducer,
  password: passwordReducer,
  cgvcgu: cgvReducer,
  stock: stockReducer,
  short: shortReducer,
  sendEmail: sendEmailReducer,
  sendPassword: sendPasswordReducer,
  sector: sectorReducer,
  valuation: valuationReducer,
  brokers: brokersReducer,
  consensus: consensusReducer,
  report: reportReducer,
  valorisation: valorisationReducer,
  search: searchReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: devMode,
  middleware,
});

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;
export const history: History = createReduxHistory(store);
