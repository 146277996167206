import cx from 'classnames';
import React, { useContext } from 'react';
import { IIndice } from '../core/interfaces';
import { AppContext } from 'contexts/AppContext';
import QuoteValue from 'components/QuoteValue';
import { numbersSpaceSeparator, getLevel } from 'utils/data';
import CustomIcon from 'components/CustomIcon';

import '../assets/indices.scss';
import Loader from 'components/Loader';

/* interfaces */
interface Props {
  indices?: IIndice[];
  closed?: boolean;
  loading?: boolean;
}

interface PropsIndiceItem {
  name: string;
  variation: number;
  closed?: boolean;
}

/* interfaces */

function IndiceItem({ name, variation, closed }: PropsIndiceItem) {
  const direction = variation > 0 ? 'up' : 'down';
  const style = getLevel(+variation, 'daily');
  const value = closed ? `${variation}` : `${numbersSpaceSeparator(variation)}%`;
  const Icon = (
    <CustomIcon
      width={22}
      height={22}
      className="indice-item-arrow"
      color={`var(${style})`}
      type={variation > 0 ? 'up' : 'down'}
    />
  );
  return (
    <div className={cx('indices__item', direction, { closed })}>
      <span className="indices__item-name">{name}</span>
      <div className="variation-wrapper">
        {!closed && value !== '0.00%' ? Icon : null}
        <QuoteValue isVariation={!closed} value={value} type="daily" />
      </div>
    </div>
  );
}

function Indices({ indices, closed, loading }: Props) {
  const { isDesktopOrLaptop } = useContext(AppContext);
  return (
    <div
      className={cx('indices', {
        desktop: isDesktopOrLaptop,
      })}
    >
      {indices && !loading ? (
        indices.map((i: IIndice) => (
          <IndiceItem
            key={i.name}
            name={i.name}
            closed={closed}
            variation={closed ? i.current || i.previouslyClose : i.variation}
          />
        ))
      ) : (
        <div className={cx('indices__item')}>
          <span className="indices__item-name" />
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Indices;
