import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import packageJson from './../../package.json';
const VERSION = packageJson.version;

let reactPlugin: ReactPlugin;
let appInsights: IAppInsigths;

interface IAppInsigths extends IApplicationInsights {
  loadAppInsights: () => void;
  setAuthenticatedUserContext: (id: string) => void;
}

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (instrumentationKey: string, browserHistory: Object) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
    }

    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        sdkExtension: VERSION,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });
    appInsights.loadAppInsights();
  };

  const clearAISession = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
    }
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        sessionExpirationMs: 1,
      },
    });
    appInsights.loadAppInsights();
  };

  return {
    reactPlugin,
    appInsights,
    initialize,
    clearAISession,
  };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
export const clearAISession = (instrumentationKey: string) => ai.clearAISession(instrumentationKey);
