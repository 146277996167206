import React, { useEffect } from 'react';
import './styles.scss';
import TopBarMobile from './mobile';
import TopBarDesktop from './desktop';
import { TopBarProps } from './interfaces';

function TopBar(props: TopBarProps) {
  useEffect(() => {
    window.addEventListener('load', setHeaderHeight);
    return () => {
      window.removeEventListener('load', setHeaderHeight);
    };
  }, []);

  function setHeaderHeight() {
    const root = document.documentElement;
    const headerHeight = document.querySelector('header')?.getBoundingClientRect().height;
    if (headerHeight) root.style.setProperty('--header-height', `${headerHeight}px`);

    // detect notch reading css safe-space
    const safeSpace = getComputedStyle(document.documentElement).getPropertyValue(
      '--env-safe-area-top',
    );
    if (safeSpace && safeSpace !== ' 0px') {
      document.querySelector('header')?.classList.add('mobile-header--notch');
    }
  }
  if (props.isMobile) {
    return <TopBarMobile {...props} />;
  }
  return <TopBarDesktop {...props} />;
}

export default TopBar;
