export const getMeasures = (isDesktopOrLaptop, fullScreen, padding = 0) => {
  let measures = {};
  let mainHeight;
  //if DESKTOP
  if (isDesktopOrLaptop) {
    mainHeight = window.innerWidth / 3 - 50;
    measures = {
      height: mainHeight,
      width: mainHeight,
      'max-width': 424,
      'max-height': 424,
    };
  } else {
    mainHeight = window.innerWidth - padding;
    measures = {
      height: mainHeight,
      width: mainHeight,
    };
  }
  return { measures };
};
