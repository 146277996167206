/* eslint-disable react/no-danger */
import { WSFlash } from 'features/flashs/interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import { truncateString, dateFormatting } from 'utils/data';
import CardAttachment from 'components/CardAttachment';
import cx from 'classnames';
import snakeCase from 'lodash/snakeCase';

import './assets/flash-card.scss';

interface Props extends WSFlash {
  selectedId?: string;
}

function FlashListCard(props: Props) {
  const dateString = dateFormatting(props.createdAt);
  const bulletClass = snakeCase(props.action);
  let attachments;

  if (props.attachments !== null) {
    const cssClasses = ['flash-card__attachments'];
    attachments = props.attachments!.map(attachment => (
      <CardAttachment
        key={attachment.filename}
        {...attachment}
        cssClasses={cssClasses}
        page="flash"
      />
    ));
  }
  return (
    <div
      className={cx(`groupByData__card flash ${bulletClass}`, {
        'flash--active': props.selectedId === props.id,
      })}
    >
      <Link to={`/flashs/${props.id}`}>
        <div className="flash__header groupByData__header">
          <div className="flash__company groupByData__company">{props.company}</div>
          <div className="flash__date groupByData__date_card">{dateString}</div>
        </div>
        <div className="flash__content">
          <div className="flash__action action__bullets">
            <span className={bulletClass}>{props.action}</span>
          </div>
          <div className="flash__title">{props.title}</div>
          <div
            className="flash__description"
            dangerouslySetInnerHTML={{
              __html: truncateString(props.description.replace(/\\n/g, '<br />'), 120),
            }}
          />
          {attachments}
        </div>
      </Link>
    </div>
  );
}

export default FlashListCard;
