/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../utils/telemetry';
import { history } from './store';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { initialized } = prevState;
    const AppInsightsInstrumentationKey = nextProps.instrumentationKey; // PUT YOUR KEY HERE
    if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      prevState.initialized = true;
      nextProps.after();
    }
    return prevState;
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
