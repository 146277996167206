import React from 'react';
import Item from './Item';
import './styles.scss';
import { Link } from 'app/core/interfaces';

interface Props {
  show: boolean;
  mainLinks: Link[];
  newDaily: boolean;
  newFlash: boolean;
}

function Footer({ show, mainLinks, newDaily, newFlash }: Props) {
  if (!show) return <></>;
  return (
    <footer>
      {mainLinks
        .filter(l => !l.onlyDesktop)
        .map(l => (
          <Item
            key={l.link}
            to={l.link}
            logoWhite={l.iconWhite}
            logo={l.icon}
            title={l.title}
            disabled={!l.enabled}
            newDaily={newDaily}
            newFlash={newFlash}
          />
        ))}
    </footer>
  );
}

export default Footer;
