import Loader from 'components/Loader';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import { RootState } from './store';
interface IRequireAuthProps {
  children: JSX.Element;
}
export default function RequireAuth({ children }: IRequireAuthProps) {
  const location = useLocation();
  const passwordChangeRequired = useSelector(
    (state: RootState) => state.session.user.data?.passwordChangeRequired,
  );
  const { isLoading } = useSelector((state: RootState) => state.session.user);
  const cgu = useSelector((state: RootState) => state.session.user.data?.cgu);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (cgu === null && location.pathname !== '/me/cgv-cgu') {
    return <Navigate to="/me/cgv-cgu" />;
  }

  if (cgu && passwordChangeRequired && location.pathname !== '/me/password-change') {
    return <Navigate to="/me/password-change" />;
  }

  if (
    cgu &&
    !passwordChangeRequired &&
    (location.pathname === '/me/password-change' || location.pathname === '/me/cgv-cgu')
  ) {
    return <Navigate to="/" />;
  }

  return children;
}
