import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import {
  sendPassword as sendPasswordAction,
  sendPasswordSuccess,
  sendPasswordFailed,
  checkKey as checkKeyAction,
  checkKeySuccess,
  checkKeyFailed,
} from './slice';

interface SendPasswordPayload {
  resetKey: string;
  newPassword: string;
}

interface CheckKeyPayload {
  resetKey: string;
}

function* sendPassword(sendPasswordPayload: PayloadAction<SendPasswordPayload>) {
  const requestURL = `/api/auth/reset-password/${sendPasswordPayload.payload.resetKey}`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
      body: { newPassword: sendPasswordPayload.payload.newPassword },
    });
    yield put(sendPasswordSuccess());
  } catch (err) {
    console.error('err', err);
    yield put(sendPasswordFailed({ error: err.responseJSON }));
  }
}

function* checkKey(checkKeyPayload: PayloadAction<CheckKeyPayload>) {
  const requestURL = `/api/auth/reset-password/check/${checkKeyPayload.payload.resetKey}`;
  try {
    yield call(request, requestURL, { method: 'GET' });
    yield put(checkKeySuccess());
  } catch (err) {
    console.error('err', err);
    yield put(checkKeyFailed({ error: err.responseJSON }));
  }
}

export default [
  takeEvery(sendPasswordAction.type, sendPassword),
  takeEvery(checkKeyAction.type, checkKey),
];
