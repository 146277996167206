import LineChart, { LineChartData, LineChartOptions } from 'components/Charts/LineChart';
import { LendingFooter } from './LendingFooter';
import React, { useContext, useEffect, useState } from 'react';
import { LendableProps } from '../core/interfaces';
import PortalLoader from 'components/Loader/PortalLoader';
import { useTranslation } from 'react-i18next';
import { getMeasures } from '../actions';
import { AppContext } from 'contexts/AppContext';

const colors = ['#001E41', '#ffcd00', '#ff6979', '#57ffbf', '#ffcacf'];

const options: LineChartOptions = {
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      type: 'timeseries',
      time: {
        tooltipFormat: 'dd/MM/yyyy',
      },
    },
    y: {
      ticks: {
        callback: val => {
          let valTemp = val;
          if (typeof val === 'number') {
            valTemp = val.toFixed(2);
          }
          return `${valTemp}%`;
        },
        padding: 0,
      },
      position: 'left',
      title: {
        display: true,
        text: 'Lendable as %',
        padding: {
          bottom: 10,
        },
      },
    },
    y1: {
      ticks: {
        callback: val => {
          let valTemp = val;
          if (typeof val === 'number') {
            valTemp = val.toFixed(2);
          }
          return `${valTemp}%`;
        },
        padding: 0,
      },
      title: {
        display: true,
        text: '% Shared Outstanding On Loan',
        padding: {
          bottom: 10,
        },
      },
      position: 'right',
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: tooltipItem => `${tooltipItem.dataset.label} ${tooltipItem.raw}%`,
      },
    },
    legend: {
      labels: {
        boxWidth: 30,
      },
    },
  },
};

function LendingChart({
  lendableData,
  sharesData,
  loading,
  id,
  withoutTabs,
  fullScreen,
}: LendableProps) {
  const { t } = useTranslation();
  const [datasets, setDatasets] = useState<LineChartData>({ datasets: [] });
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { measures } = getMeasures(isDesktopOrLaptop, fullScreen);

  useEffect(() => {
    if (lendableData && lendableData.data.length && sharesData && sharesData.data.length) {
      const newdatasets: LineChartData = {
        labels: lendableData.data.map(d => d.date),
        datasets: [
          {
            data: lendableData.data.map(d => +d.value),
            fill: false,
            borderWidth: 1.5,
            borderColor: colors[0],
            backgroundColor: colors[0],
            label: lendableData.name,
            tension: 0,
            yAxisID: 'y',
          },
          {
            data: sharesData.data.map(d => +d.value),
            yAxisID: 'y1',
            borderWidth: 1.5,
            borderColor: colors[1],
            backgroundColor: colors[1],
            label: sharesData.name,
            tension: 0,
          },
        ],
      };
      setDatasets(newdatasets);
    }
  }, [lendableData, sharesData]);

  if (loading) {
    return <PortalLoader />;
  }

  return (
    <>
      <div className="short__lending">
        <h1>{t('short:lending.title')}</h1>
        <div className="stockchart" style={{ ...measures }}>
          {!lendableData || lendableData.data.length === 0 ? (
            <p>{t('common:p.noData')}</p>
          ) : (
            <>
              <LineChart
                className="stockchart-linechart"
                options={options}
                data={datasets}
                id="lending-chart-short"
                measures={measures}
              />
            </>
          )}
        </div>
      </div>
      <LendingFooter data={datasets} />
    </>
  );
}

export default React.memo(LendingChart);
