/* eslint-disable react/jsx-key */
import React from 'react';
import { ITradedVolume } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';

import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { numbersSpaceSeparator } from 'utils/data';
import { format, parseISO } from 'date-fns';

interface Props {
  volumes: ITradedVolume[];
  loading: boolean;
}

function TradedVolumes({ volumes, loading }: Props) {
  const { t } = useTranslation();
  const columns: Column<ITradedVolume>[] = React.useMemo(
    () => [
      {
        Header: t('stats:report.volumes.table.platform') as string,
        accessor: 'platform',
        sortType: 'alphanumeric',
      },
      {
        Header: t('stats:report.volumes.table.dvol') as string,
        accessor: 'dailyVolume',
        sortType: 'basic',
        Cell: (props: Cell) => numbersSpaceSeparator(props.value, false),
      },
      {
        Header: t('stats:report.volumes.table.d') as string,
        id: 'dailyRepartition',
        accessor: x =>
          x.dailyRepartition && typeof x.dailyRepartition === 'string'
            ? parseFloat(x.dailyRepartition)
            : x.dailyRepartition,
        sortType: 'basic',
        Cell: (props: Cell) => <span>{props.value}%</span>,
      },
      {
        Header: t('stats:report.volumes.table.y') as string,
        id: 'yearlyRepartition',
        accessor: x =>
          x.yearlyRepartition && typeof x.yearlyRepartition === 'string'
            ? parseFloat(x.yearlyRepartition)
            : x.yearlyRepartition,
        sortType: 'basic',
        Cell: (props: Cell) => <span>{props.value}%</span>,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: 'platform', desc: false }],
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: volumes,
      initialState,
    },
    useSortBy,
  );

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!volumes || volumes.length === 0) {
    return <p className="no-data">{t('daily:report.noData')}</p>;
  }

  const lastUpdatedAt = volumes && volumes[0] && volumes[0].updatedAt;

  return (
    <div className="indices-prices">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<ITradedVolume>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={cx({ selected: column.isSorted })}
                >
                  <div className="th-wrapper">
                    <div className="header-name">{column.render('Header')}</div>
                    {column.isSorted ? (
                      <div className="arrow">
                        <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<ITradedVolume>) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<ITradedVolume>) => (
                  <td {...cell.getCellProps()} className={cell.column.isSorted ? 'sorted' : ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pairs-legend">
        <i>
          {lastUpdatedAt && `Last update ${format(parseISO(lastUpdatedAt), 'do MMMM, K:mm b')}`}
        </i>
      </div>
    </div>
  );
}

export default TradedVolumes;
