import { createSlice } from '@reduxjs/toolkit';

/** **** INTERFACES */
export interface ILoginState {
  login: {
    isLoading: boolean;
    forceUpdate: boolean;
    error: {
      detail: string;
      message: string;
    } | null;
  };
}

export interface ILoginAction {
  email: string;
  password: string;
}
/** **** INITIAL STATE */

const initialState: ILoginState = {
  login: {
    forceUpdate: false,
    isLoading: false,
    error: null,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    login: (state, payload) => {
      state.login.isLoading = true;
      state.login.error = null;
    },
    loginSuccess: state => {
      state.login.isLoading = false;
    },
    loginFailed: (state, action) => {
      state.login.isLoading = false;
      state.login.error = action.payload.error;
    },
  },
});

export const { login, loginSuccess, loginFailed } = slice.actions;

export default slice.reducer;
