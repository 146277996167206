import { createSlice } from '@reduxjs/toolkit';
import { User } from './interfaces';

/** **** INTERFACES */

export interface IUserState {
  user: {
    data: User | null;
    isLoading: boolean;
    error: unknown;
  };
  version: {
    number: string;
    forceUpdate: false;
  };
}

/** **** INITIAL STATE */

const initialState: IUserState = {
  user: {
    data: null,
    isLoading: false,
    error: null,
  },
  version: {
    number: '',
    forceUpdate: false,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getVersion: () => {},
    getUser: state => {
      state.user.isLoading = true;
    },
    getUserSuccess: (state, action) => {
      state.user.data = action.payload;
      state.user.isLoading = false;
    },
    getUserFailed: (state, action) => {
      state.user.error = action.payload.error;
      state.user.isLoading = false;
    },
    getSession: state => {},
    resetUser: state => {
      state.user.data = initialState.user.data;
      state.user.isLoading = initialState.user.isLoading;
      state.user.error = initialState.user.error;
    },
    doForceUpdate: (state, action) => {
      state.version.forceUpdate = action.payload.forceUpdate;
      state.version.number = action.payload.number;
    },
  },
});

export const {
  getUser,
  getUserSuccess,
  getUserFailed,
  getSession,
  resetUser,
  doForceUpdate,
  getVersion,
} = slice.actions;

export default slice.reducer;
