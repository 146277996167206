import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getLists } from './core/slice';
import { Helmet } from 'react-helmet';
import ConsensusTable from './components/ConsensusTable';
import cx from 'classnames';
import './assets/styles.scss';
import { AppContext } from 'contexts/AppContext';
import Loader from 'components/Loader';
import VALogo from './assets/va.png';

function Consensus() {
  const dispatch = useDispatch();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { items, isLoading } = useSelector((state: RootState) => state.consensus.lists);
  const user = useSelector((state: RootState) => state.session.user.data?.emitter);

  useEffect(() => {
    dispatch(getLists());
  }, []);

  return (
    <div>
      <Helmet>
        <title>RMM - Consensus</title>
      </Helmet>
      <div className="consensus-logo">
        <img className="consensus-logo-va" src={VALogo} alt="visible alpha logo" />
      </div>
      {isLoading ? (
        <Loader text="Loading your consensus data..." />
      ) : items?.emitter && items.pairs && user && items.emitter.length ? (
        <>
          <div className={cx('consensus-content', { 'is-desktop': isDesktopOrLaptop })}>
            <h1>{user.name}</h1>
            <div className="consensus-table">
              <ConsensusTable
                currentTickerName={user.name}
                periods={items.periods}
                list={items.emitter}
              />
            </div>

            <h1>Peers</h1>
            {Object.keys(items.pairs).map(key => (
              <div key={key}>
                <h2>{key}</h2>
                <ConsensusTable
                  currentTickerName={user.name}
                  periods={items.periods}
                  list={items.pairs[key]}
                />
              </div>
            ))}

            <p className="va-text">
              Visible Alpha Insights captures the forecasts, assumptions and logic from full working
              sell-side models of over 160 contributing brokers and integrates them into comparable
              views across analysts, companies and peer groups. This deep consensus data provides a
              quick understanding of the sell-side view on a company or industry at a level of
              granularity, timeliness and interactivity that has never before been possible.
            </p>
          </div>
        </>
      ) : (
        <>
          <p>You are not entitled to view this page. Please contact CMS to subscribe.</p>
          <p className="va-text">
            Visible Alpha Insights captures the forecasts, assumptions and logic from full working
            sell-side models of over 160 contributing brokers and integrates them into comparable
            views across analysts, companies and peer groups. This deep consensus data provides a
            quick understanding of the sell-side view on a company or industry at a level of
            granularity, timeliness and interactivity that has never before been possible.
          </p>
        </>
      )}
    </div>
  );
}

export default Consensus;
