/* eslint-disable import/no-duplicates */
import React from 'react';
import '../assets/price.scss';
import { IPrice } from '../core/interfaces';
import QuoteValue from 'components/QuoteValue';
import CustomIcon from 'components/CustomIcon';
import { getCurrencySymbol } from 'utils/currency';
import { numbersSpaceSeparator, isSessionOpened, getTZHoursFromUTCHours } from 'utils/data';
import { format } from 'date-fns-tz';
import { isToday, parseISO } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import Loader from 'components/Loader';

interface Props {
  price?: IPrice;
  name: string;
  updatedAt?: string;
  closed?: boolean;
  currency?: string;
  previousClose?: number;
  date?: Date;
  loading?: boolean;
}

function Price({ price, name, updatedAt, closed, currency, previousClose, date, loading }: Props) {
  const isPositive = price ? price.variation.percent > 0 : null;

  let finalDate = date;

  let finalPrice;
  if (closed && date && updatedAt && isToday(parseISO(updatedAt))) {
    finalPrice = price && price.current;
    finalDate = new Date();
  } else if (closed && previousClose) {
    finalPrice = previousClose;
  } else if (price && price.current) {
    finalPrice = price.current;
  }
  const finalCurrency = closed && currency ? currency : price?.currency || 'EUR';
  return (
    <div className="price">
      <div className="price-item price__name">{name}</div>
      {!loading ? (
        <>
          <div className="price-item price__session">
            {!closed ? (
              <CustomIcon type={isSessionOpened() ? 'market-open' : 'market-closed'} />
            ) : (
              ''
            )}
            {closed ? <CustomIcon type="paused" /> : ''}
            {!closed && price ? (
              <div className="price__session-time">{getTZHoursFromUTCHours(price.time)} CET</div>
            ) : (
              ''
            )}
            {updatedAt && finalDate ? (
              <div className="price__session-time">
                {format(finalDate, 'do MMMM, h:mm a', { locale: enGB })}
              </div>
            ) : null}
          </div>
          <div className="price-item price__price">
            <span className="current">{finalPrice ? numbersSpaceSeparator(finalPrice) : ''}</span>
            <span className="currency">{getCurrencySymbol(finalCurrency)}</span>
          </div>
          <div className="price-item price__variation">
            {!closed ? (
              <>
                <CustomIcon
                  width={22}
                  height={22}
                  className={`arrow ${isPositive ? 'up' : 'down'}`}
                  type={isPositive ? 'up' : 'down'}
                />
                {price && price.variation ? (
                  <QuoteValue
                    className="quote"
                    value={`${price.variation.percent}`}
                    type="daily"
                    extraText={`(${isPositive ? '+' : ''}${numbersSpaceSeparator(
                      price.variation.volume,
                    )})`}
                  />
                ) : null}
              </>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Price;
