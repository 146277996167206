import { RootState } from 'app/store';
import PortalLoader from 'components/Loader/PortalLoader';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAgenda } from '../core/interfaces';

function Agenda() {
  const agenda: IAgenda[] = useSelector((state: RootState) => state.report.agenda.data);
  const isAgendaLoading = useSelector((state: RootState) => state.report.agenda.isLoading);
  const { t } = useTranslation();

  if (isAgendaLoading) {
    return <PortalLoader />;
  }
  if (!agenda || agenda.length === 0) {
    return (
      <div className="agenda__container">
        <h4>{t('stats:report.agenda.title')}</h4>
        <p className="no-data">{t('daily:report.noAgenda')}</p>
      </div>
    );
  }
  const agendaUpdated = agenda[0].createdAt;

  return (
    <div className="agenda__container">
      <h4>{t('stats:report.agenda.title')}</h4>
      {agenda.map((event, i: number) => (
        <div
          key={`${event.name}_${Math.random().toString(36).substr(2, 9)}`}
          className={`agenda__container-event ${i % 2 === 0 ? 'even' : 'odd'}`}
        >
          <span className="name">{event.name}</span>
          <div>
            <span className="date">{event.date}</span>
            <span className="data">{event.data}</span>
          </div>
        </div>
      ))}
      {agendaUpdated ? (
        <p className="agenda__container-updated">
          Last update {format(parseISO(agendaUpdated), 'do MMMM, K:mm b')}{' '}
        </p>
      ) : (
        ''
      )}
    </div>
  );
}

export default Agenda;
