import React, { useState } from 'react';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import logo from './logo.jpg';
import './styles.scss';
import { TopBarProps } from './interfaces';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Link as ILink } from 'app/core/interfaces';
import CustomIcon from 'components/CustomIcon';
import UserModale from './UserModale';
import { clearSession } from 'utils/auth';

function DestopItem({ icon: Icon, link, title, iconWhite, newDaily, newFlash }: ILink) {
  const location = useLocation();
  const selected = location.pathname.startsWith(link);
  return (
    <Link
      to={link}
      className={cx('desktop-header__items-item', {
        active: selected,
      })}
    >
      {((newDaily && title === 'Daily report') || (newFlash && title === 'Flashs')) && (
        <div className="red-circle-top-bar" />
      )}
      {selected ? iconWhite : Icon}
      <span className="inline-block">{title}</span>
    </Link>
  );
}

function TopBarDesktop({ showHeader, mainLinks, newDaily, newFlash }: TopBarProps) {
  const user = useSelector((state: RootState) => state.session.user.data);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const headerLinks = mainLinks
    .filter(l => l.enabled)
    .map(l => <DestopItem key={l.link} {...l} newDaily={newDaily} newFlash={newFlash} />);
  if (!showHeader) {
    return <></>;
  }
  function onSignOut() {
    clearSession();
    navigate('/');
    setShowDropdown(false);
  }

  return (
    <>
      {showDropdown ? (
        <UserModale onSignOut={onSignOut} user={user} onHide={() => setShowDropdown(false)} />
      ) : (
        ''
      )}
      <header className="desktop-header">
        <div className="desktop-header__logo">
          <img src={logo} alt="rmm logo" />
        </div>
        <div className="desktop-header__items">{user ? headerLinks : ''}</div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setShowDropdown(true)}
          onKeyDown={() => setShowDropdown(true)}
          className={cx('desktop-header__user', {
            none: !showHeader,
          })}
        >
          <CustomIcon type="user" />
          {user ? (
            <span className="desktop-header__user-name">
              {user.firstname} {user.lastname}
            </span>
          ) : (
            ''
          )}
          <div className="arrow-down" />
        </div>
      </header>
    </>
  );
}

export default TopBarDesktop;
