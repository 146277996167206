import { createSlice } from '@reduxjs/toolkit';

/** **** INTERFACES */

export interface ISendPasswordState {
  validKey: boolean | null;
  success: boolean;
  isLoading: boolean;
  error: {
    detail: string;
    message: string;
  } | null;
}

/** **** INITIAL STATE */

const initialState: ISendPasswordState = {
  validKey: null,
  success: false,
  isLoading: false,
  error: null,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'sendPassword',
  initialState,
  reducers: {
    sendPassword: (state, payload) => {
      state.isLoading = true;
    },
    sendPasswordSuccess: state => {
      state.isLoading = false;
      state.success = true;
    },
    sendPasswordFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    checkKey: (state, payload) => {
      state.isLoading = true;
    },
    checkKeySuccess: state => {
      state.isLoading = false;
      state.validKey = true;
    },
    checkKeyFailed: (state, action) => {
      state.isLoading = false;
      state.validKey = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  sendPassword,
  sendPasswordSuccess,
  sendPasswordFailed,
  checkKey,
  checkKeySuccess,
  checkKeyFailed,
} = slice.actions;

export default slice.reducer;
