import React from 'react';
import cx from 'classnames';

const typeValues = ['ALL', 'FLASH', 'ANALYSIS'];

const typeTable = (type: string) => {
  switch (type) {
    case 'ALL':
      return 'Tous';
    case 'FLASH':
      return 'Flashs';
    case 'ANALYSIS':
      return 'Reports';
    default:
      return '';
  }
};
interface Props {
  callback: Function;
  selected: string;
  badge: {
    flash: number;
    report: number;
  };
}

export const Filters = ({ callback, selected, badge }: Props) => (
  <div className="search-filters">
    {typeValues.map(type => {
      const num = type === 'FLASH' ? badge.flash : badge.report;
      return (
        <div
          className={cx(`search-filter`, { selected: type === selected })}
          onClick={() => callback({ type })}
          key={type}
        >
          {typeTable(type)}
          {type !== 'ALL' && ` (${num})`}
        </div>
      );
    })}
  </div>
);
