import React from 'react';
import './styles.scss';
import CustomModal from 'components/CustomModal';
import { Capacitor } from '@capacitor/core';

interface Props {
  forceUpdate: boolean;
  close: () => void;
}

export const VersionModal = ({ forceUpdate, close }: Props) => {
  const text = 'You should update the app';
  const isIOS = Capacitor.getPlatform() === 'ios';
  let link = isIOS
    ? 'https://apps.apple.com/lu/app/mycms-rothschild-martin-maurel/id1534535354'
    : 'https://play.google.com/store/apps/details?id=com.rothschildandco.panoptes&hl=ln';

  const handleAction = () => {
    localStorage.setItem('lastTimeShowed', new Date().toISOString().split('T')[0]);
    if (forceUpdate) {
      // clearSession();
    } else {
      close();
    }
  };
  return (
    <div className="version-control-modal">
      <CustomModal onHide={() => {}}>
        <>
          <label>Warning</label>
          <section>{text}</section>
          <div>
            <a id="myAnchor" href={link}>
              Click this link to update
            </a>
          </div>
          {!forceUpdate && <button onClick={handleAction}>Dismiss</button>}
        </>
      </CustomModal>
    </div>
  );
};
