/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { history, store } from './app/store';
import initI18n from './i18n/i18n';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from 'contexts/AppContext';
import Loader from 'components/Loader';
import AppUrlListener, { registerNotification } from 'utils/push';
import InsightsListener from 'utils/insightsListener';
import { App as AppPlugin } from '@capacitor/app';
import { UrbanAirShip } from '@awesome-cordova-plugins/urbanairship';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

AppPlugin.addListener('appStateChange', async state => {
  if (state.isActive) {
    await UrbanAirShip.setBadgeNumber(0);
  }
});

initI18n().then(() => {
  registerNotification();
  ReactDOM.render(
    /* @ts-ignore  */
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <AppProvider>
              <InsightsListener />
              <AppUrlListener />
              <App />
            </AppProvider>
          </Suspense>
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
