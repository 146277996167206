import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Device } from '@capacitor/device';

import brokersEn from './en/brokers.json';
import cgvEn from './en/cgv.json';
import commonEn from './en/common.json';
import dailyEn from './en/daily.json';
import datesEn from './en/dates.json';
import emailsEn from './en/emails.json';
import errorsEn from './en/errors.json';
import flashsEn from './en/flashs.json';
import inboxEn from './en/inbox.json';
import shortEn from './en/short.json';
import statsEn from './en/stats.json';
import monitoringBlocksEn from './en/monitoringBlocks.json';
import recoTpBrokersEn from './en/recoTpBrokers.json';

import brokersFr from './fr/brokers.json';
import cgvFr from './fr/cgv.json';
import commonFr from './fr/common.json';
import dailyFr from './fr/daily.json';
import datesFr from './fr/dates.json';
import emailsFr from './fr/emails.json';
import errorsFr from './fr/errors.json';
import flashsFr from './fr/flashs.json';
import inboxFr from './fr/inbox.json';
import shortFr from './fr/short.json';
import statsFr from './fr/stats.json';
import monitoringBlocksFr from './fr/monitoringBlocks.json';
import recoTpBrokersFr from './fr/recoTpBrokers.json';

export const resources = {
  en: {
    brokers: brokersEn,
    cgv: cgvEn,
    common: commonEn,
    daily: dailyEn,
    dates: datesEn,
    emails: emailsEn,
    errors: errorsEn,
    flashs: flashsEn,
    inbox: inboxEn,
    short: shortEn,
    stats: statsEn,
    monitoringBlocks: monitoringBlocksEn,
    recoTpBrokers: recoTpBrokersEn,
  },
  fr: {
    brokers: brokersFr,
    cgv: cgvFr,
    common: commonFr,
    daily: dailyFr,
    dates: datesFr,
    emails: emailsFr,
    errors: errorsFr,
    flashs: flashsFr,
    inbox: inboxFr,
    short: shortFr,
    monitoringBlocks: monitoringBlocksFr,
    recoTpBrokers: recoTpBrokersFr,
    stats: statsFr,
  },
} as const;

async function initI18n() {
  const language = await Device.getLanguageCode();

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ns: [
        'common',
        'inbox',
        'brokers',
        'stats',
        'daily',
        'flashs',
        'dates',
        'emails',
        'errors',
        'cgv',
        'monitoringBlocks',
        'short',
      ],
      defaultNS: 'common',
      debug: false, // process.env.NODE_ENV === 'development',
      fallbackLng: 'en',
      lng: language.value,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      load: 'languageOnly',
      resources,
    });
}

export default initI18n;
