import React from 'react';
import { useMediaQuery } from 'react-responsive';

type ContextProps = {
  isDesktopOrLaptop: boolean;
  isBigScreen: boolean;
  isTabletOrMobile: boolean;
  isTabletOrMobileDevice: boolean;
  isPortrait: boolean;
  isLandscape: boolean;
  isRetina: boolean;
  isTabletPortaitSize: boolean;
};
const AppContext = React.createContext<ContextProps>({
  isDesktopOrLaptop: false,
  isBigScreen: false,
  isTabletOrMobile: false,
  isTabletOrMobileDevice: false,
  isPortrait: false,
  isLandscape: false,
  isRetina: false,
  isTabletPortaitSize: false,
});

const AppProvider = (props: { children: React.ReactNode }) => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const isRetina = useMediaQuery({ minResolution: '2dppx' });
  const isTabletPortraitSize = useMediaQuery({ maxWidth: 1024 });

  return (
    <AppContext.Provider
      value={{
        isDesktopOrLaptop,
        isBigScreen,
        isTabletOrMobile,
        isTabletOrMobileDevice,
        isPortrait,
        isLandscape,
        isRetina,
        isTabletPortaitSize: isTabletPortraitSize,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
