import { utcToZonedTime } from 'date-fns-tz'; // eslint-disable-line
import { clearAISession, getAppInsights } from './telemetry';

export function isAuthenticated() {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) return false;
  return true;
}

export function clearSession() {
  const appInsights = getAppInsights();
  if (appInsights !== undefined) {
    appInsights.flush();
    clearAISession(process.env.REACT_APP_APPINSIGHTS_KEY || '');
  }
  localStorage.removeItem('access_token');
  localStorage.removeItem('exp');
  localStorage.removeItem('pushNotificationToken');
  localStorage.removeItem('allowBiometricAuth');
  window.location.reload();
}
