import { call, put, takeEvery } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'redux-first-history';
import request from 'utils/request';
import { User } from './interfaces';
import { getUserSuccess, getUserFailed, getUser, doForceUpdate, getVersion } from './slice';
import { clearSession } from 'utils/auth';
import { UrbanAirShip } from '@awesome-cordova-plugins/urbanairship';
import { getAppInsights } from 'utils/telemetry';
import { getSession as getSessionAction } from './slice';
const { version } = require('../../../package.json');

interface IVersionResult {
  forceUpdate: boolean;
  version: string | null;
}

function* getSession() {
  const requestURL = '/api/user/self';
  try {
    if (localStorage.getItem('access_token')) {
      yield put(getUser());
      const result: User = yield call(request, requestURL, { method: 'GET' });
      if (!result) {
        // yield clearSession();
        yield put(push('/'));
      }
      if (window.cordova) {
        const channelId: string = yield UrbanAirShip.getChannelID();
        yield call(request, '/api/user/self/token', { method: 'PUT', body: { token: channelId } });
      }
      if (result.email) {
        const validatedId = result.email.replace(/[,;=| ]+/g, '_');
        const appInsights = getAppInsights();
        if (appInsights) {
          appInsights.setAuthenticatedUserContext(validatedId);
        }
      }
      yield put(getUserSuccess(result));
    }
  } catch (err) {
    console.error(err);
    if (
      err &&
      err.responseJSON &&
      (err.responseJSON.status === 401 || err.responseJSON.status === 403)
    ) {
      yield clearSession();
      yield put(push('/'));
    } else if (err && !err.responseJSON) {
      console.error(err);
    } else {
      yield put(getUserFailed({ error: err.responseJSON }));
    }
  }
}

function* getVersionBackend() {
  const requestVersionURL = `/api/app-version/${version}`;
  try {
    const resultVersion: IVersionResult = yield call(request, requestVersionURL, { method: 'GET' });
    yield put(
      doForceUpdate({ forceUpdate: resultVersion.forceUpdate, number: resultVersion.version }),
    );
  } catch (err) {
    console.error(err);
    yield put(doForceUpdate({ forceUpdate: false, number: null }));
  }
}

export default [
  takeEvery(LOCATION_CHANGE, getSession),
  takeEvery(getSessionAction.type, getSession),
  takeEvery(getVersion.type, getVersionBackend),
];
