// @ts-nocheck
import { cloneDeep } from 'lodash';
import { LineChartOptions } from 'components/Charts/LineChart';
import { BarChartOptions } from 'components/Charts/BarChart';

const WIDTH_Y_AXE = 45;
const ROW_SIZE = 35;
const labelFormat: { [index: string]: string } = {
  '1D': 'HH:mm',
  '1M': 'MMM do',
  '1Y': 'LLLL yyyy',
  YTD: 'MMM do',
  '5Y': 'LLLL',
  DAYS: 'MMM do',
  WEEKS: 'MMM do',
  MONTHS: 'LLLL yyyy',
  '3MONTHS': 'LLLL yyyy',
};

export const getChartOptions = (
  currentLegend: string,
  defaultLineOptions: LineChartOptions | BarChartOptions,
) => {
  const options = cloneDeep(defaultLineOptions);
  // CASE OF DATE SEARH
  if (currentLegend === 'DAYS') {
    options.scales.x.time.unit = 'day';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 1;
  }
  if (currentLegend === 'WEEKS') {
    options.scales.x.time.unit = 'day';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 7;
  }
  if (currentLegend === 'MONTHS') {
    options.scales.x.time.unit = 'month';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 1;
  }
  if (currentLegend === '3MONTHS') {
    options.scales.x.time.unit = 'month';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 3;
  }

  // PREDIFINED LEGENDS

  if (currentLegend === '1D') {
    options.scales.x.time.unit = 'hour';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 2;
  }
  if (currentLegend === '1M') {
    options.scales.x.time.unit = 'day';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 1;
  }
  if (currentLegend === 'YTD') {
    options.scales.x.time.unit = 'day';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 1;
  }
  if (currentLegend === '1Y') {
    options.scales.x.time.unit = 'month';
    options.scales.x.time.tooltipFormat = labelFormat[currentLegend];
    options.scales.x.time.stepSize = 1;
  }
  if (currentLegend === '5Y') {
    options.scales.x.time.unit = 'week';
    options.scales.x.time.tooltipFormat = 'dd-MM-yyyy';
  }
  if (
    currentLegend &&
    ['1D', '1M', '1Y', 'YTD', '5Y', 'DAYS', 'WEEKS', 'MONTHS', '3MONTHS'].indexOf(currentLegend) ===
      -1
  ) {
    options.scales.x.time.unit = 'day';
    options.scales.x.time.tooltipFormat = labelFormat['1M'];
  }
  return options;
};

export const defaultLineOptions: LineChartOptions = {
  layout: {
    padding: {
      top: 0,
      left: 10,
      right: 10,
      bottom: 0,
    },
  },
  scales: {
    x: {
      type: 'time',
      offset: true,
      display: false,
      time: {
        unit: 'hour',
        stepSize: 2,
        tooltipFormat: 'HH:mm',
      },
      grid: {
        borderDash: [3, 1],
        display: true,
      },
      ticks: {
        autoSkip: false,
        font: {
          size: 0, // force keep grids and not show ticks
          family: 'Montserrat',
        },
        tickColor: 'red',
        color: '#1d3041',
        backdropColor: 'white',
      },
    },
    y: {
      type: 'linear',
      grid: {
        borderDash: [3, 8],
        drawBorder: false,
        drawTicks: false,
      },
      ticks: {
        showLabelBackdrop: true,
        backdropColor: 'white',
        backdropPadding: 5,
        color: '#011e41',
        font: {
          weight: 'bold',
          size: 11,
        },
        // dont show the first one
        callback: (val, index) => (index === 0 ? null : `${parseFloat(val).toFixed(2)}€`),
      },
      position: 'left',
      afterDataLimits(axis) {
        if (axis.max && axis.min) {
          const axisYIncrease = (axis.max - axis.min) / 4;
          axis.max += axisYIncrease;
          axis.min -= axisYIncrease;
        }
      },
      afterFit(scaleInstance) {
        scaleInstance.width = WIDTH_Y_AXE;
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      fullSize: true,
      position: 'top',
      labels: {
        filter: legendItem => legendItem.text !== 'Volumes',
        boxWidth: 12,
        boxHeight: 12,
        font: {
          family: 'Montserrat',
        },
        color: '#1d3041',
        align: 'start',
      },
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => `${(tooltipItem.raw as { yOriginal: number }).yOriginal}€`,
      },
    },
  },
};

export const defaultBarOptions: BarChartOptions = {
  layout: {
    padding: {
      top: 0,
      left: 10,
      right: 10,
      bottom: 0,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        stepSize: 2,
      },
      offset: true,
      ticks: {
        font: {
          family: 'Montserrat',
        },
        autoSkip: false,
        color: '#1d3041',
        backdropColor: 'white',
      },
      grid: {
        borderDash: [3, 1],
        offset: false,
      },
    },
    y: {
      type: 'linear',
      grid: {
        borderDash: [3, 8],
        drawBorder: false,
        drawTicks: false,
      },
      afterFit(scaleInstance) {
        scaleInstance.width = WIDTH_Y_AXE;
      },
      ticks: {
        showLabelBackdrop: true,
        backdropColor: '#f2f5fa',
        backdropPadding: 3,
        color: '#011e41',
        font: {
          weight: 'bold',
          size: 11,
        },
        // dont show the first one
        callback: (val, index, arr) => (index === arr.length - 1 ? null : `${+val / 1000}K`),
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const rotateChart = () => {
  // Adding the rotating class
  const node = document.querySelector('.fullscreen-chart .stockchart');
  node && node.classList.add('rotate-chart');
};

export const getMeasuresVolumes = (isDesktopOrLaptop, fullScreen) => {
  let measures = {};
  let mainHeight;
  //if DESKTOP
  if (isDesktopOrLaptop) {
    mainHeight = window.innerWidth / 3 - 50;
    measures = {
      height: mainHeight,
      width: mainHeight,
      'max-width': 424,
      'max-height': 424,
    };
  } else {
    mainHeight = window.innerWidth;
    measures = {
      height: mainHeight,
      width: mainHeight,
    };
  }
  return { measures };
};

export const getMeasures = (screen, isDesktopOrLaptop, fullScreen) => {
  let measuresLine, measuresBar;
  const { width, height } = screen;
  const legendSize = 6; // Rows max number
  const legendHeight = ROW_SIZE * legendSize;
  if (fullScreen) {
    if (!isDesktopOrLaptop) {
      // When MOBILE fullscreen the measures
      // will be swapped due to display portrait
      const mainWidth = Number(width) - 80;
      measuresLine = {
        width: height,
        height: mainWidth * 0.7,
        maxWidth: height,
        maxHeight: mainWidth * 0.7,
      };
      measuresBar = {
        width: height,
        height: mainWidth * 0.3,
        maxWidth: height,
        maxHeight: mainWidth * 0.3,
      };
    } else {
      // if DESKTOP
      const mainHeight = Number(height) - legendHeight - 150;
      measuresLine = {
        width: width,
        height: mainHeight * 0.7,
      };
      measuresBar = {
        width: width,
        height: mainHeight * 0.3,
      };
    }
  } else {
    // if not FULLSCREEN
    measuresLine = { height: 300 };
    measuresBar = { height: 200 };
  }
  return { measuresLine, measuresBar };
};
