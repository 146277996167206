import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getLists } from './core/slice';
import ValuationTable from './components/ValuationTable';
import { RootState } from 'app/store';
import './assets/styles.scss';
import cx from 'classnames';
import { AppContext } from 'contexts/AppContext';
import RefreshPortal from 'components/RefreshPortal';

function Valuations() {
  const dispatch = useDispatch();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { items, isLoading } = useSelector((state: RootState) => state.valuation.lists);

  function dispatchAll() {
    dispatch(getLists());
  }
  useEffect(() => {
    dispatchAll();
    dispatch(getLists());
  }, []);

  return (
    <div>
      <RefreshPortal action={dispatchAll} />
      <Helmet>
        <title>RMM - Valuations</title>
      </Helmet>
      <div className={cx('valuation-content', { 'is-desktop': isDesktopOrLaptop })}>
        {items && <ValuationTable list={items} loading={isLoading} />}
      </div>
    </div>
  );
}

export default Valuations;
