import { RootState } from 'app/store';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sendEmail } from './core/slice';
import ErrorsContent from 'components/FormErrors';
import './styles.scss';
import CustomIcon from 'components/CustomIcon';
import { Link } from 'react-router-dom';
import React from 'react';

interface IForm {
  email: string;
}

function SendEmail() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const emailInput = watch('email');
  const { sent, isLoading, error } = useSelector((state: RootState) => state.sendEmail);
  const dispatch = useDispatch();

  function onSubmit(data: IForm) {
    dispatch(sendEmail({ email: data.email }));
  }

  if (sent) {
    return (
      <div className="app-container-wrapper background nooverflow">
        <div className="app-container app-container__login">
          <div className="success">
            {t('emails:email.success')} <CustomIcon type="correct" />
          </div>
          <div className="login__links">
            <Link to="/">Return Home</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container-wrapper background nooverflow">
      <div className="app-container app-container__login">
        <form onSubmit={handleSubmit(onSubmit)} className="login__container" noValidate>
          <div className="title__container">Forgot password ?</div>
          <div className="login__input-container field forgot ">
            <label htmlFor="email">
              <input
                className={errors.email ? 'error' : ''}
                id="email"
                type="email"
                placeholder=" "
                {...register('email', {
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address',
                  },
                })}
              />
              <span>Email address</span>
            </label>
            {emailInput !== '' && (
              <div className="icon-input">
                <CustomIcon type={error || errors.email ? 'wrong' : 'correct'} />
              </div>
            )}
          </div>
          <ErrorsContent error={error} />
          <div className="login__button-container">
            <button disabled={!isValid} type="submit">
              {' '}
              {isLoading ? 'Loading...' : 'Send email'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SendEmail;
