import React, { useContext } from 'react';
import './styles.scss';
import { ReactComponent as Logo } from 'assets/logo-full.svg';
import { AppContext } from 'contexts/AppContext';
interface Props {
  onHide: () => void;
  children: React.ReactNode;
}

function CustomModal({ onHide, children }: Props) {
  const newChildren = React.cloneElement(children as React.ReactElement, {
    onHide,
  });
  const { isDesktopOrLaptop } = useContext(AppContext);
  return (
    <div className="custom-modal">
      <div className="custom-modal-shadow" onClick={onHide} role="presentation">
        {/* When mobile then display the logo */}
        {!isDesktopOrLaptop && <Logo className="mobile-logo" />}
      </div>
      <div className="custom-modal-content">{newChildren}</div>
    </div>
  );
}

export default CustomModal;
