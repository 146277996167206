import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

export default function GetStoreData() {
  const { data: quotesData, isLoading: isQuoteDataLoading } = useSelector(
    (state: RootState) => state.stock.quote,
  );
  const { data: user } = useSelector((state: RootState) => state.session.user);
  const { data: emitterChartData, isLoading: isEmitterChartDataLoading } = useSelector(
    (state: RootState) => state.stock.chart,
  );
  const { items: indicesChartData, isLoading: areIndicesChartDataLoading } = useSelector(
    (state: RootState) => state.stock.indices,
  );
  const { data: tradedVolumes, isLoading: isTradedVolumesLoading } = useSelector(
    (state: RootState) => state.stock.tradedVolumes,
  );
  const { data: mostActiveBrokers, isLoading: isMostActiveBrokersLoading } = useSelector(
    (state: RootState) => state.stock.mostActiveBrokers,
  );
  const { data: volumesComparision, isLoading: isVolumesComparisionDataLoading } = useSelector(
    (state: RootState) => state.stock.volumesComparision,
  );

  return {
    quotesData,
    isQuoteDataLoading,
    user,
    emitterChartData,
    isEmitterChartDataLoading,
    indicesChartData,
    areIndicesChartDataLoading,
    tradedVolumes,
    isTradedVolumesLoading,
    mostActiveBrokers,
    isMostActiveBrokersLoading,
    volumesComparision,
    isVolumesComparisionDataLoading,
  };
}
