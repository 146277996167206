/* eslint-disable no-plusplus */
import React, { useEffect, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getRecommendations, clearRecommendations, getRecoTpBrokers } from '../core/slice';
import { RecommendationsByDate } from '../core/interfaces';
import SimpleList from 'components/SimpleList';
import RecommendationCard from '../components/RecommendationCard';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../assets/styles.scss';
import { AppContext } from 'contexts/AppContext';
import RefreshPortal from 'components/RefreshPortal';
import Loader from 'components/Loader';

function Note() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isTabletOrMobileDevice } = useContext(AppContext);
  const recommendations: RecommendationsByDate[] = useSelector(
    (state: RootState) => state.brokers.recommendations,
  );

  const page = useRef(0);
  const isLoading = useSelector((state: RootState) => state.brokers.isLoading);
  const isLastPage = useSelector((state: RootState) => state.brokers.isLastPage);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const loader = !isLastPage && isScrolled ? <Loader text="Loading..." /> : null;

  useEffect((): (() => void) => {
    dispatch(getRecommendations({ page: 0 }));
    dispatch(getRecoTpBrokers());
    return () => dispatch(clearRecommendations());
  }, []);

  const fetchMoreData = () => {
    !isLastPage && dispatch(getRecommendations({ page: ++page.current }));
    setIsScrolled(true);
  };

  return (
    <div>
      <Helmet>
        <title>RMM - Broker notes</title>
      </Helmet>
      <RefreshPortal action={() => dispatch(getRecommendations({}))} />
      <>
        {/* we avoid the switch in order to not mount/unmont the list, because it produces jumps */}
        {!isLoading && recommendations.length === 0 && (
          <div className="no-results__message">{t('brokers:p.noData')}</div>
        )}
        <div
          className={
            isTabletOrMobileDevice ? 'brokers__content mobile' : 'brokers__content desktop'
          }
        >
          {/* 
            <h2>{t('recoTpBrokers:title')}</h2>
            {recoTpLoading && <Loader text="Loading recommandation TP / Brokers" />}
            {!recoTpLoading && recoTpBrokers && recoTpBrokers?.length > 0 ? (
              <RecoTpBrokers recoTpBrokers={recoTpBrokers} />
            ) : null}
            */}

          <h2>{t('brokers:title')}</h2>
          <br />
          <InfiniteScroll
            dataLength={recommendations.length}
            next={fetchMoreData}
            hasMore={true}
            loader={loader}
          >
            <SimpleList
              componentElement={RecommendationCard}
              componentData={recommendations}
              componentClassName="recommendations"
            />
          </InfiniteScroll>
        </div>
      </>
    </div>
  );
}

export default Note;
