import React from 'react';
import CustomIcon from 'components/CustomIcon';

interface ISearchInputProps {
  callback: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  value: string;
}
export const SearchInput = ({ callback, onKeyDown, value }: ISearchInputProps) => {
  return (
    <div className="search-input">
      <CustomIcon type="search" width={24} />
      <input
        type="text"
        placeholder="Enter a word / sentence"
        onChange={e => callback(e.target.value)}
        onKeyDown={onKeyDown}
        value={value}
      />
    </div>
  );
};
