import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import {
  getLendings,
  getLendingsSuccess,
  getLendingsFailed,
  getStock,
  getStockSuccess,
  getStockFailed,
  getSellers,
  getSellersSuccess,
  getSellersFailed,
} from './slice';
import { ILendingRequest, IStockRequest, ISellersRequest } from './interfaces';
import { parseISO } from 'date-fns';

function* lendings() {
  const requestURL = '/api/stocks/lending';
  try {
    const result: ILendingRequest[] = yield call(request, requestURL, { method: 'GET' });
    const lendingsFiltered = result.filter(i => i.emitter !== null);

    if (lendingsFiltered && lendingsFiltered.length > 0) {
      const lendablesFilter = lendingsFiltered.filter(i => i.name === 'Lendable as %');
      const sharesFilter = lendingsFiltered.filter(i => i.name === '% Shares Outstanding On Loan');

      const lendablesArray = lendablesFilter
        .map(lendable => {
          const dateSplit = lendable.date.split('+');
          const date = new Date(dateSplit[0]);
          return {
            value: lendable.value.replace('%', ''),
            date: date.toISOString(),
            createdAt: lendable.createdAt,
          };
        })
        .sort((a, b) => {
          const dateA = +parseISO(a.date);
          const dateB = +parseISO(b.date);
          return dateA - dateB;
        });

      const sharesArray = sharesFilter
        .map(share => {
          const dateSplit = share.date.split('+');
          const date = new Date(dateSplit[0]);
          return {
            value: share.value.replace('%', ''),
            date: date.toISOString(),
          };
        })
        .sort((a, b) => {
          const dateA = +parseISO(a.date);
          const dateB = +parseISO(b.date);
          return dateA - dateB;
        });

      const lendables = {
        emitter: lendablesFilter[0].emitter,
        bbg_code: lendablesFilter[0].bbgCode,
        ric_code: lendablesFilter[0].ricCode,
        name: lendablesFilter[0].name,
        data: lendablesArray,
      };

      const shares = {
        emitter: sharesFilter[0].emitter,
        bbg_code: sharesFilter[0].bbgCode,
        ric_code: sharesFilter[0].ricCode,
        name: sharesFilter[0].name,
        data: sharesArray,
      };
      yield put(getLendingsSuccess({ lendables, shares }));
    } else {
      yield put(getLendingsSuccess({}));
    }
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getLendingsFailed({ error: err.responseJSON }));
  }
}

function* stock() {
  const requestURL = '/api/stocks/stock';
  try {
    const result: IStockRequest[] = yield call(request, requestURL, { method: 'GET' });
    const stockFiltered = result.filter(i => i.emitter !== null);

    if (stockFiltered && stockFiltered.length > 0) {
      const stockArray = stockFiltered
        .map(each => {
          const dateSplit = each.date.split('+');
          const date = new Date(dateSplit[0]);
          return {
            value: each.value.replace('%', ''),
            date: date.toISOString(),
            createdAt: each.createdAt,
          };
        })
        .sort((a, b) => {
          const dateA = +parseISO(a.date);
          const dateB = +parseISO(b.date);
          return dateA - dateB;
        });

      const stocks = {
        emitter: stockFiltered[0].emitter,
        bbgCode: stockFiltered[0].bbgCode,
        ricCode: stockFiltered[0].ricCode,
        data: stockArray,
      };

      yield put(getStockSuccess(stocks));
    } else {
      yield put(getStockSuccess({}));
    }
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getStockFailed({ error: err.responseJSON }));
  }
}

function* sellers() {
  const requestURL = '/api/stocks/sellers';
  try {
    const result: ISellersRequest[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getSellersSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getSellersFailed({ error: err.responseJSON }));
  }
}

export default [
  takeEvery(getLendings.type, lendings),
  takeEvery(getStock.type, stock),
  takeEvery(getSellers.type, sellers),
];
