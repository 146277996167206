import React from 'react';
import { LineChartData } from 'components/Charts/LineChart';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import cx from 'classnames';
import GetStoreData from 'features/mkt-data/stock/tools/getStoreData';

interface Props {
  data: LineChartData;
  range: string;
}

interface Point {
  yOriginal?: number;
  yOriginalClose?: number;
  ticker_code: string;
}

interface Item {
  label: string;
  backgroundColor: string;
  data: Point[];
}

export default function MktFooter({ data, range }: Props) {
  const { quotesData, user } = GetStoreData();
  const { datasets } = data;
  return (
    <div className="mkt-footer">
      {
        // if there is no label then we don't display the item
        datasets
          .filter(elem => elem.label)
          .map(item => {
            const { label, backgroundColor, data } = item as unknown as Item;
            const endValue = Number(data[0]?.yOriginal);
            let startValue = Number(data[data.length - 1]?.yOriginalClose);
            if (data[0]?.ticker_code === user?.emitter.ric && range === 'YTD') {
              startValue = quotesData?.price.current ?? 0;
            }
            const value = (startValue / endValue - 1) * 100;

            // if there is no color, we'll display lightgrey by default
            const background = backgroundColor || 'lightgrey';
            return (
              <div className={cx('mkt-footer__row', { negative: value < 0 })} key={item.label}>
                <div
                  className="mkt-footer__rectangle"
                  style={{ backgroundColor: String(background) }}
                ></div>
                <span>{label}</span>
                <span className="mkt-footer__arrow">
                  {value < 0 ? <IoMdArrowDown /> : <IoMdArrowUp />}
                </span>
                <span>
                  {value > 0 && '+'}
                  {isFinite(value) ? value.toFixed(2) : 'N/A'}%
                </span>
              </div>
            );
          })
      }
    </div>
  );
}
