import React from 'react';
import './styles.scss';

function FooterDesktop() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="footer-desktop">
      <p>
        Rothschild Martin Maurel - {year || '2020'} -{' '}
        <a
          href="mailto:RothschildandcoCMS@rothschildandco.com"
          target="blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </p>
    </footer>
  );
}

export default FooterDesktop;
