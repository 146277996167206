/* eslint-disable prettier/prettier */
import LineChart, { LineChartData, LineChartOptions } from 'components/Charts/LineChart';
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import { MultiChartProps, Legend } from './interfaces';
import BtnLegend from './BtnLegend';
import {
  getChartOptions,
  defaultLineOptions,
  defaultBarOptions,
  rotateChart,
  getMeasures,
} from './actions';
import { useTranslation } from 'react-i18next';
import { addMinutes, compareAsc, setHours, setMinutes } from 'date-fns';
import StockFilter from '../../features/mkt-data/stock/components/StockFilter';
import CustomModal from 'components/CustomModal';
import { shouldShowGraph } from 'utils/data';
import Loader from 'components/Loader';
import BarChart, { BarChartData, BarChartOptions } from 'components/Charts/BarChart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { IoMdArrowDropdown, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { VscFilter, VscFilterFilled } from 'react-icons/vsc';
import { getChart, clearState } from '../../features/mkt-data/stock/core/slice';
import DatePicker from './components/DatePicker';
import MktFooter from './components/MktFooter';
import CustomIcon from 'components/CustomIcon';
import { intervalToDuration } from 'date-fns/esm';
import { AppContext } from 'contexts/AppContext';
import cx from 'classnames';
import { clearDateFilter, addDateFilter } from 'features/mkt-data/stock/core/slice';


type DateType = Date | null;

const COLORS = ['#001E41', '#ffcd00', '#ff6979', '#57ffbf', '#ffcacf'];
function getRemainingValues(lastHourAvailable: string, lastValue: number) {
  let hourIndex = new Date(lastHourAvailable);
  let aimHour = setHours(new Date(lastHourAvailable), 17);
  aimHour = setMinutes(aimHour, 0);
  const emptyDataset = [];
  while (compareAsc(hourIndex, aimHour) === -1) {
    hourIndex = addMinutes(hourIndex, 5);
    emptyDataset.push({
      t: hourIndex.toISOString(),
      y: lastValue,
      yOriginal: lastValue,
      x: new Date(hourIndex.toISOString()).getTime(),
    });
  }
  return emptyDataset;
}

function StockChart({
  legend,
  withoutTabs,
  fullScreen = false,
  setFilterActivate,
  filterActivate,
  screen = {},
  callback,
  range,
  setRange,
}: MultiChartProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pair: pairFilters } = useSelector((state: RootState) => state.stock.filter);
  const [display, setDisplay] = useState<boolean>(false);
  const { isDesktopOrLaptop } = useContext(AppContext);
  const [lineOptions, setLineOptions] = useState<LineChartOptions>(defaultLineOptions);
  const [lineDataset, setLineDataset] = useState<LineChartData>({ datasets: [] });
  const [barDataset, setBarDataset] = useState<BarChartData>({ datasets: [] });
  const [barOptions, setBarOptions] = useState<BarChartOptions>(defaultBarOptions);
  const [activeDateFilter, setActiveDateFilter] = useState<boolean>(false);
  const [activePicker, setActivePicker] = useState<string>('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const { data: pairInfo } = useSelector((state: RootState) => state.stock.pairInfo);
  const { measuresLine, measuresBar } = getMeasures(screen, isDesktopOrLaptop, fullScreen);
  const { data: emitterChartData, isLoading: isEmitterChartDataLoading } = useSelector(
    (state: RootState) => state.stock.chart,
  );
  const { items: indicesChartData, isLoading: areIndicesChartDataLoading } = useSelector(
    (state: RootState) => state.stock.indices,
  )
  const { dateStart, dateEnd } = useSelector((state: RootState) => state.stock.filter.date);

  useEffect(() => {
    if (indicesChartData && emitterChartData && !areIndicesChartDataLoading && !isEmitterChartDataLoading) {
      // Merge of datas
      let allLines = [emitterChartData, ...indicesChartData];
      if (shouldShowGraph(range, allLines)) {
        const firstEmitterValue = emitterChartData?.price?.[0]?.value;

        // Creating Line dataset
        const newLineDataSet: LineChartData = {
          labels: allLines?.[0].price.map(item => new Date(item?.timestamp).getTime()),
          datasets: allLines.map((line, i) => ({
            label: line.ticker,
            data: line.price.map((p) => {
              // showing different value based on emitter one
              let yValue = p.value;
              if (line.ticker !== emitterChartData.ticker) {
                const firstNonEmitterValue = range === '1D' ? line.price?.[0].value : line.price?.[0].close;
                yValue = (p.value * firstEmitterValue) / firstNonEmitterValue;
              }
              return {
                y: yValue,
                x: new Date(p?.timestamp).getTime(),
                yOriginal: range === '1D' ? p.value : p.close,
                yOriginalClose: p.close,
                t: p.timestamp,
                ticker_code: line.ticker_code,
              };
            }),
            fill: false,
            borderColor: COLORS[i],
            backgroundColor: COLORS[i],
            borderWidth: 2,
            tension: 0,
          })),
        };

        if (range === '1D' && !filterActivate.date) {
          const lastValue = allLines[0].price[allLines[0].price.length - 1];
          const emptyDataset = getRemainingValues(lastValue?.timestamp, lastValue?.value);
          newLineDataSet.datasets.push({
            label: '',
            borderColor: 'rgba(220,220,220,0.0)',
            fill: false,
            borderWidth: 0,
            backgroundColor: 'rgba(220,220,220,0.0)',
            pointRadius: 0,
            data: emptyDataset,
          });
          newLineDataSet.labels?.push(...emptyDataset.map(item => new Date(item.t).getTime()));
        }

        // Creating bar dataset
        const newBarDataSet: BarChartData = {
          labels: emitterChartData.price.map(item => item?.timestamp),
          datasets: [
            {
              data: emitterChartData.price.map(item => +(item.volume || 0)),
              barThickness: 1,
              maxBarThickness: 1,
              borderWidth: 1,
              borderColor: '#042c5c',
              backgroundColor: '#0000166c',
              label: 'Volumes',
            },
          ],
        };
        if (range === '1D' && !filterActivate.date) {
          const emptyDatasetBar = getRemainingValues(
            emitterChartData.price?.[emitterChartData.price.length - 1].timestamp,
            0,
          );
          newBarDataSet.datasets[0].data.push(...emptyDatasetBar.map(item => item.y));
          newBarDataSet.labels?.push(...emptyDatasetBar.map(item => new Date(item.t).getTime()));
        }

        // Update options
        const getOptionsGraphB = getChartOptions(range, defaultBarOptions) as BarChartOptions;
        setBarOptions(getOptionsGraphB);

        let getOptionsGraphA = getChartOptions(range, defaultLineOptions) as LineChartOptions;
        setLineOptions(getOptionsGraphA);

        // Update datasets
        setLineDataset(newLineDataSet);
        setBarDataset(newBarDataSet);
      }
    }
  }, [emitterChartData, indicesChartData, range, areIndicesChartDataLoading, isEmitterChartDataLoading]);

  function getChartPairData(pair: Array<string>) {
    let payload = { rics: pair, range: range, start: dateStart, end: dateEnd };
    setFilterActivate({ date: filterActivate.date, pairs: true });
    dispatch(getChart(payload));
  }

  function getChartData(type: string) {
    let payload = { rics: pairFilters, range: type, start: dateStart, end: dateEnd };
    setRange(type);
    dispatch(clearState());
    dispatch(getChart(payload));
  }

  function validation(dateStart: DateType, dateEnd: DateType) {
    if (!dateStart || !dateEnd) {
      return 'Dates should be chosen before searching';
    }
    if (dateStart.toDateString() === dateEnd.toDateString()) {
      return 'Dates cannot be equal';
    }
    return null;
  }

  function handleDateFilter() {
    const text = validation(dateStart, dateEnd);
    if (text) {
      setErrorText(text);
      return
    }
    if (!dateStart || !dateEnd) return null;
    setErrorText(null); // remove error text
    const start = dateStart;
    const end = dateEnd;
    let payload = { start, end, rics: pairFilters };
    if (start > end) {
      payload = { start: end, end: start, rics: pairFilters };
      // swap the dates in redux and hence within the inputs
      dispatch(addDateFilter({ day: end, type: 'dateStart' }));
      dispatch(addDateFilter({ day: start, type: 'dateEnd' }));
    }
    const duration = intervalToDuration({ start: new Date(start), end: new Date(end) })
    const monthDur: number = Number(duration.months)
    const yearDur: number = Number(duration.years)

    if (yearDur >= 5) {
      setRange('3MONTHS');
    } else if (yearDur >= 1) {
      setRange('MONTHS')
    } else if (yearDur < 1) {
      if (monthDur >= 1) {
        setRange('WEEKS')
      } else {
        setRange('DAYS')
      }
    }
    setFilterActivate({ date: true, pairs: filterActivate.pairs });
    dispatch(getChart(payload));
  }

  function resetDateFilter() {
    setErrorText(null);
    setRange('1D');
    let payload = { range: '1D', rics: pairFilters };
    setFilterActivate({ date: false, pairs: filterActivate.pairs });
    setActiveDateFilter(false);
    dispatch(getChart(payload));
    dispatch(clearDateFilter());
  }

  const showGraph = emitterChartData ? shouldShowGraph(range, [emitterChartData]) : false;

  useEffect(() => {
    // if fullscreen mobile
    if (fullScreen && !isDesktopOrLaptop) rotateChart();
  }, [fullScreen]);

  useEffect(() => {
    // when interacting with input then remove the error
    setErrorText(null);
  }, [activePicker]);


  useEffect(() => {
    display ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    display ? document.head.style.position = 'hidden' : document.head.style.overflow = 'unset';
  }, [display]);

  if (areIndicesChartDataLoading) {
    return (
      <div className="stockchart">
        <Loader />
      </div>
    );
  }

  return (
    <div className='stockchart'>
      <div className="filter-container">
        <div className="button-opener-date" >
          <img
            className="icon-calendar-img"
            src={require('./assets/icons/calendar.png')}
            alt="filter"
            onClick={() => {
              setActiveDateFilter(!activeDateFilter)
            }}
          />
          {activeDateFilter && (
            <div
              className={cx('date-picker', {
                desktop: isDesktopOrLaptop,
                mobile: !isDesktopOrLaptop,
                fullscreen: fullScreen
              })}
            >
              <DatePicker date={dateStart} type="dateStart" setActivePicker={setActivePicker} activePicker={activePicker} />
              <IoIosArrowForward className="icon-check" />
              <DatePicker date={dateEnd} type="dateEnd" setActivePicker={setActivePicker} activePicker={activePicker} />
              <AiOutlineCheck className="icon-check" onClick={() => handleDateFilter()} />
            </div>
          )}
          {activeDateFilter ? (
            <AiOutlineClose className="icon-filter-cross" onClick={() => resetDateFilter()} />
          ) : (
            <IoMdArrowDropdown
              className="icon-filter"
              onClick={() => setActiveDateFilter(!activeDateFilter)}
            />
          )}
        </div>
        {
          // not display when fullscreen mobile
          (isDesktopOrLaptop || !fullScreen) && (
            <button className="button-opener" type="button" onClick={() => {
              if (!dateStart || !dateEnd) {
                setActiveDateFilter(false);
                dispatch(clearDateFilter());
              } else if (dateStart && dateEnd) {
                let start = new Date(dateStart);
                let end = new Date(dateStart);
                if ((start?.toDateString() === end?.toDateString())) {
                  setErrorText('Fix or clear dates before continuing');
                  return;
                }
                if (start?.toDateString() !== end?.toDateString()) {
                  handleDateFilter();
                }
              }
              setDisplay(true)
            }}>
              <p className="text-filter">Peers</p>
              {!pairFilters.length ? (
                <VscFilter className="icon-filter" />
              ) : (
                <VscFilterFilled className="icon-filter" />
              )}
            </button>
          )
        }
      </div>
      {
        errorText && <div className="date-picker-error">{errorText}</div>
      }
      <div className="stockchart__legend">
        {legend.map((l: Legend) =>
          withoutTabs ? (
            <span className="stockchart__title">{l.title}</span>
          ) : (
            !filterActivate.date && (
              <BtnLegend
                key={l.title}
                label={l.title}
                selected={range === l.title}
                onClick={() => getChartData(l.title)}
              />
            )
          ),
        )}
        {
          !fullScreen &&
          (
            <button className="legend-enlarge" onClick={() => callback()}>
              <CustomIcon type="enlarge-button" />
            </button>
          )
        }
      </div>
      {showGraph ? (
        <>
          <div className="chart-wrapper">
            {
              !isEmitterChartDataLoading ? (
                <div id="chart-multiline-stock" style={{ ...measuresLine }}>
                  <LineChart options={lineOptions} data={lineDataset} measures={measuresLine} />
                </div>
              ) : (
                <div id="chart-multiline-stock-loader">
                  <Loader />
                </div>
              )
            }
            {
              <div id="chart-multiline-stock-bars" style={{ ...measuresBar }}>
                <BarChart options={barOptions} data={barDataset} measures={measuresBar} />
              </div>
            }
          </div>
          {
            (isDesktopOrLaptop || !fullScreen) && <MktFooter data={lineDataset} range={range} />
          }

        </>
      ) : (
        <div className="stockchart__not-ready">{t('common:p.noData')}</div>
      )}

      {display && (
        <CustomModal onHide={() => setDisplay(false)}>
          <StockFilter
            range={range}
            getChartPair={getChartPairData}
            pairInfo={pairInfo}
            onHide={setDisplay}
            fullScreen={fullScreen}
          />
        </CustomModal>
      )
      }
    </div>
  );
}
export default React.memo(StockChart);
