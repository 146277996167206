import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import './styles.scss';
import { AppContext } from 'contexts/AppContext';
import CustomIcon, { PictoTypesEnum } from 'components/CustomIcon';
import { ReactComponent as Logo } from 'assets/logo-full.svg';

interface Props {
  children: React.ReactNode;
  title?: string;
  icon?: PictoTypesEnum;
}

function Container({ children, title, icon }: Props) {
  const { pathname } = useLocation();
  const isFullSize = ['/statistics', '/daily-report'].includes(pathname);
  const isMidSize = [
    '/statistics/sector',
    '/statistics/valuations',
    '/brokers-note/notes',
    '/brokers-note/valorisation',
    '/brokers-note',
  ].includes(pathname);
  const { isDesktopOrLaptop } = useContext(AppContext);
  const pageClass = pathname.substr(1).split('/')[0];
  const pageClass2 = pathname.substr(1).split('/')[1];

  useEffect(() => {
    setContainerHeaderHeight();
  }, []);

  function setContainerHeaderHeight() {
    const root = document.documentElement;
    const containerHeader = document.querySelector('.app-container-header-wrapper');
    if (containerHeader) {
      const containerHeaderHeight = containerHeader.getBoundingClientRect().height;
      root.style.setProperty('--app-container-header-height', `${containerHeaderHeight}px`);
    }
  }

  return (
    <div
      className={cx(`app-container-wrapper ${pageClass} ${pageClass2 || ''}`, {
        desktop: isDesktopOrLaptop,
        mobile: !isDesktopOrLaptop,
      })}
    >
      {isDesktopOrLaptop && title && icon ? (
        <div
          className={cx('app-container-header-wrapper', {
            'full-size': isFullSize,
            'mid-size': isMidSize,
          })}
        >
          <Logo className="header-logo" />
          <div className="app-container-header">
            <CustomIcon width="46px" height="46px" type={icon} />
            <span className="title">{title}</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        className={cx('app-container', {
          desktop: isDesktopOrLaptop,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Container;
