import React, { useEffect } from 'react';
import { App } from '@capacitor/app';

const InsightsListener: React.FC<any> = () => {
  useEffect(() => {
    return () => {
      App.removeAllListeners();
    };
  }, []);

  return null;
};

export default InsightsListener;
