import React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

export const Button = ({ children, onClick, disabled = false }: Props) => {
  return (
    <button type="button" className="simple-button" disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};
