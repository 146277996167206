/* eslint-disable react/jsx-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IImportedBrokerNotes, IImportedBrokerNote } from '../core/interfaces';

import '../assets/importedBrokerNotesCard.scss';
import { format, parseISO } from 'date-fns';
import CustomIcon from 'components/CustomIcon';
import { getRecommendationType } from 'utils/data';

interface Props {
  notes: IImportedBrokerNotes[];
  loading: boolean;
}

function ImportedBrokerNotesCard({
  broker,
  reco_last: recoLast,
  reco_new: recoNew,
  price_last: priceLast,
  price_new: priceNew,
  currency,
}: IImportedBrokerNote) {
  const bulletClass1 = getRecommendationType(recoLast);
  const bulletClass2 = getRecommendationType(recoNew);
  let arrow;
  if (priceNew && priceLast) {
    const isUp = parseFloat(priceNew) > parseFloat(priceLast);
    arrow = (
      <span className={`recommendation__price-arrow arrow-${isUp ? 'up' : 'down'}`}>
        <CustomIcon type={isUp ? 'up' : 'down'} width={24} />
      </span>
    );
  }
  return (
    <div className="groupByData__card imported-notes__card recommendation">
      <div className="recommendation__header groupByData__header">
        <div className="recommendation__company groupByData__company">{broker}</div>
      </div>
      <div className="recommendation__body">
        <div className="recommendation__prices">
          <div className="recommendation__info recommendation__info--opacity">
            <div
              className={`recommendation__bullets action__bullets recommendation__bullets-${bulletClass1}`}
            >
              <span className={bulletClass1}>{recoLast}</span>
            </div>
            <div className="recommendation__price">
              {parseFloat(priceLast) > 0 ? priceLast : '-'}
              <span>{currency}</span>
            </div>
          </div>
          <div className="recommendation__info-right">
            <div className="recommendation__info_separator">
              <CustomIcon
                type="right"
                className="recommendation__info-arrow"
                width={26}
                height={24}
              />
            </div>
            <div className="recommendation__info">
              <div
                className={`recommendation__bullets action__bullets recommendation__bullets-${bulletClass2}`}
              >
                <span className={bulletClass2}>{recoNew}</span>
              </div>
              <div className="recommendation__price">
                {parseFloat(priceNew) > 0 ? priceNew : '-'}
                <span>{currency}</span>
                {arrow}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ImportedBrokerNotes({ notes, loading }: Props) {
  const { t } = useTranslation();

  if (loading) {
    return <p>Chargement...</p>;
  }

  const notesLength = Object.keys(notes).length;

  if (!notesLength || notesLength === 0) return <></>;
  const lastUpdatedAt = notes[Object.keys(notes)[0]][0].updated_at;

  return (
    <div className="imported-notes">
      <h4>{t('stats:sector.notes.title')}</h4>

      <div className="imported-notes__content">
        {Object.keys(notes).map(group => (
          <div className="imported-notes__group" key={group}>
            <h5>{group}</h5>
            <div className="groupByData imported-notes__group-content">
              {notes[group].map((data: IImportedBrokerNote) => (
                <ImportedBrokerNotesCard key={data.id} {...data} />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="imported-notes__updated">
        <i>
          {lastUpdatedAt && `Last update ${format(parseISO(lastUpdatedAt), 'do MMMM, K:mm b')}`}
        </i>
      </div>
    </div>
  );
}

export default ImportedBrokerNotes;
