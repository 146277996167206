import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import { IChart, IVolumesComparision } from 'components/StockChart/interfaces';
import { IQuote, IChartPayload, IMonitoringBlock } from './interfaces';
import indices from './indices.json';
import {
  getChart,
  getChartFailed,
  getChartSuccess,
  getQuotes,
  getPairInfo,
  getPairInfoSuccess,
  getPairInfoFailed,
  getQuotesFailed,
  getQuotesSuccess,
  getVolumesComparision,
  getVolumesComparisionSuccess,
  getVolumesComparisionFailed,
  getIndicesChartSuccess,
  getIndicesChartFailed,
  getTradedVolumes as getTradedVolumesAction,
  getTradedVolumesSuccess,
  getTradedVolumesFailed,
  getMonitoringBlocks as getMonitoringBlocksAction,
  getMonitoringBlocksSuccess,
  getMonitoringBlocksFailed,
  getMostActiveBrokers as getMostActiveBrokersAction,
  getMostActiveBrokersSuccess,
  getMostActiveBrokersFailed,
} from './slice';
// eslint-disable-next-line import/no-cycle
import { changeTimezone, isSessionOpened, shouldShowGraph } from 'utils/data';
import {
  ITradedVolume,
  IActiveBroker,
  IActiveBrokerMapped,
} from 'features/daily-report/core/interfaces';
import { isToday } from 'date-fns';
import { RootState } from 'app/store';
import { IPairInfo } from 'features/mkt-data/sector/core/interfaces';
import dateFnsFormat from 'date-fns/format';

const FORMAT = 'yyyy-MM-dd';

function mapTickerFromList(r: IChart) {
  if (r.ticker === 'UNKNOWN') {
    if (r.ticker_code) {
      const ticker = indices[r.ticker_code];
      r.ticker = ticker;
    }
  }
  return r;
}

function createChartTable(
  resultMe: IChart[],
  resultIndices: IChart[],
  range: string,
  tickerName: any,
) {
  // S'il y a des unknown, je tente de les récupérer depuis une MAP locale
  const resultsIndicesMaped = resultIndices.map(mapTickerFromList);
  const resultsMeMaped = resultMe.map(mapTickerFromList);

  let resultsIndices = resultsIndicesMaped
    .filter(r => r.ticker !== 'UNKNOWN')
    .map(r => {
      r.price = r.price.filter(p => p.value !== 0);
      return r;
    });

  if (range === '1D') {
    resultsIndices = resultsIndices
      .map(r => {
        r.price = r.price.filter(p =>
          isToday(changeTimezone(new Date(p.timestamp), 'Europe/Paris')),
        );
        return r;
      })
      .filter(r => r.price.length > 0);
  }

  resultsIndices.sort((a, b) => {
    const textA = a.ticker?.toLocaleUpperCase();
    const textB = b.ticker?.toLocaleUpperCase();
    let result;
    if (textA < textB) {
      result = -1;
    } else if (textA > textB) {
      result = 1;
    } else {
      result = 0;
    }
    return result;
  });

  if (resultsMeMaped[0].ticker !== 'UNKNOWN') {
    if (range === '1D') {
      resultsMeMaped[0].price = resultsMeMaped[0].price.reverse();
      resultsIndices.forEach(each => {
        each.price = each.price.reverse();
      });
    }

    if (tickerName) {
      resultsMeMaped[0].ticker = tickerName;
    }
    resultsMeMaped[0].price = resultsMeMaped[0].price
      .filter(m => m.value !== 0)
      .map((p, i) => ({ ...p, value: i !== 0 ? p.close : p.value }));
  }

  const me = resultsMeMaped[0].ticker !== 'UNKNOWN' ? resultsMeMaped[0] : null;
  if (me !== null) {
    me.price = me.price.map((p, i) => ({ ...p, value: i !== 0 ? p.close : p.value }));
  }

  return { me: me, resultsIndices: resultsIndices };
}

function* quotes() {
  const requestURL = '/api/quotes/me';
  try {
    const result: IQuote = yield call(request, requestURL, { method: 'GET' });
    result.indices = result.indices.filter(i => i.name !== null);
    result.inSession = isSessionOpened();
    yield put(getQuotesSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getQuotesFailed({ error: err.responseJSON }));
  }
}

function* charts(chartPayload: IChartPayload) {
  const range = chartPayload.payload.range;
  let start = chartPayload.payload.start;
  let end = chartPayload.payload.end;
  const ricsArray = chartPayload.payload.rics?.join(',');

  let requestURLMe, requestURLIndices;
  if (start && end) {
    start = dateFnsFormat(new Date(start), FORMAT);
    end = dateFnsFormat(new Date(end), FORMAT);
  }
  requestURLMe = `/api/chart/me?range=${range}`;
  if (start && end) requestURLMe = `/api/chart/me?range=date&from=${start}&to=${end}`;

  requestURLIndices = `/api/chart/indices?range=${range}`;
  if (start && end) requestURLIndices = `/api/chart/indices?range=date&from=${start}&to=${end}`;
  if (ricsArray) requestURLIndices = `/api/chart/indices?rics=${ricsArray}&range=${range}`;
  if (ricsArray && start && end)
    requestURLIndices = `/api/chart/indices?rics=${ricsArray}&range=date&from=${start}&to=${end}`;

  try {
    let resultMe: IChart[] = yield call(request, requestURLMe, { method: 'GET' });
    const resultIndices: IChart[] = yield call(request, requestURLIndices, { method: 'GET' });
    const tickerName: string | undefined = yield select(
      (store: RootState) => store.session.user.data?.emitter.name,
    );

    if (shouldShowGraph(range, resultMe)) {
      let { me, resultsIndices } = createChartTable(resultMe, resultIndices, range, tickerName);
      if (!me?.errorOccured) {
        yield put(getChartSuccess(me));
      } else {
        resultMe = yield call(request, requestURLMe, { method: 'GET' });
        let me2 = createChartTable(resultMe, resultIndices, range, tickerName).me;
        if (!me2?.errorOccured) {
          yield put(getChartSuccess(me2));
        } else {
          resultMe = yield call(request, requestURLMe, { method: 'GET' });
          let me3 = createChartTable(resultMe, resultIndices, range, tickerName).me;
          if (!me3?.errorOccured) {
            yield put(getChartSuccess(me3));
          }
        }
      }
      yield put(getIndicesChartSuccess(resultsIndices));
    } else {
      yield put(getChartSuccess([]));
      yield put(getIndicesChartSuccess([]));
    }
  } catch (err) {
    console.error('err', err, err.responseJSON);
    yield put(getChartFailed({ error: err.responseJSON }));
    yield put(getIndicesChartFailed({ error: err.responseJSON }));
  }
}

function* getTradedVolumes() {
  const requestURL = '/api/reports/volumes';
  try {
    const result: Array<ITradedVolume> = yield call(request, requestURL, { method: 'GET' });
    yield put(getTradedVolumesSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getTradedVolumesFailed({ error: err.responseJSON }));
  }
}
function* getMonitoringBlocks() {
  const requestURL = '/api/reports/monitoring-blocks';
  try {
    const result: IMonitoringBlock[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getMonitoringBlocksSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getMonitoringBlocksFailed({ error: err.responseJSON }));
  }
}

function* getMostActiveBrokers() {
  const requestURL = '/api/reports/statistics/brokers';
  try {
    const result: Array<IActiveBroker> = yield call(request, requestURL, { method: 'GET' });
    const mappedResult: Array<IActiveBrokerMapped> = result.map((item: IActiveBroker) => ({
      detail: { name: item.name, comments: item.comments },
      volume: item.volume,
      updatedAt: item.updatedAt,
    }));
    yield put(getMostActiveBrokersSuccess(mappedResult));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getMostActiveBrokersFailed({ error: err.responseJSON }));
  }
}

function* volumesComparision() {
  const requestURL = '/api/cumulated-volumes';
  try {
    const result: IVolumesComparision[] = yield call(request, requestURL, { method: 'GET' });
    const resultWithDate = result.map(r => {
      const volumes = r.volumes.map(v => ({
        ...v,
        timestamp: v.timestamp.replace('.000+0000', '+00:00'),
        updatedAt: v.updatedAt.replace('.000+0000', '+00:00'),
      }));
      return {
        ...r,
        volumes,
      };
    });
    yield put(getVolumesComparisionSuccess(resultWithDate));
  } catch (err) {
    console.error('err', err, err.responseJSON);
    yield put(getVolumesComparisionFailed({ error: err.responseJSON }));
  }
}
function* pairInfo() {
  const requestURL = '/api/indices/tickers';
  try {
    const result: IPairInfo[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getPairInfoSuccess(result));
  } catch (err) {
    console.error('err', err, err.responseJSON);
    yield put(getPairInfoFailed({ error: err.responseJSON }));
  }
}

export default [
  takeLatest(getQuotes.type, quotes),
  takeLatest(getChart.type, charts),
  takeLatest(getPairInfo.type, pairInfo),
  // takeLatest(getChartPair.type, getChartPairFilter),
  takeLatest(getVolumesComparision.type, volumesComparision),
  takeLatest(getTradedVolumesAction.type, getTradedVolumes),
  takeLatest(getMostActiveBrokersAction.type, getMostActiveBrokers),
  takeLatest(getMonitoringBlocksAction.type, getMonitoringBlocks),
];
