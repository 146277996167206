import RequireAuth from 'app/RequireAuth';
import cx from 'classnames';
import Container from 'components/Container';
import { AppContext } from 'contexts/AppContext';
import React, { useContext } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Sector from './sector';
import Stock from './stock';
import './styles.scss';
import Valuations from './valuations';

function Statistics() {
  const classes = 'subheader__element';
  const { isDesktopOrLaptop } = useContext(AppContext);
  const location = useLocation();

  return (
    <Container title="Market Data" icon="market-data-white">
      <div
        className={cx('subheader', {
          desktop: isDesktopOrLaptop,
        })}
      >
        <Link
          to="/statistics"
          className={cx(classes, {
            'subheader__element--active': location.pathname === '/statistics',
          })}
        >
          Stock
          <div className="arrow-down" />
        </Link>
        <Link
          to="/statistics/sector"
          className={cx(classes, {
            'subheader__element--active': location.pathname === '/statistics/sector',
          })}
        >
          Sector / Indices
          <div className="arrow-down" />
        </Link>
      </div>

      <Routes>
        <Route
          index
          element={
            <RequireAuth>
              <Stock />
            </RequireAuth>
          }
        />
        <Route
          path="/sector"
          element={
            <RequireAuth>
              <Sector />
            </RequireAuth>
          }
        />
        <Route
          path="/valuations"
          element={
            <RequireAuth>
              <Valuations />
            </RequireAuth>
          }
        />
      </Routes>
    </Container>
  );
}

export default Statistics;
