import { BloomData } from 'features/brokers-note/valorisation/core/types';
import request from './request';

export async function getPeRatioTimeSeriesDataForList(backdata: BloomData) {
  const peerIds = backdata.peersBloomIds.map(p => p.factsetId);
  const emitterBloomId = backdata.emitterBloomId;
  const allIds = [emitterBloomId, ...peerIds].join(',');
  const API_URL = `${process.env.REACT_APP_FACTSET_URL}?ids=${allIds}&keys=${backdata.multiples
    .map(m => m.key)
    .join(',')}`;
  const results = await request(API_URL);
  return results as unknown as Response;
}

interface ITimeSeriesData {
  dates: Date[];
  values: Array<number | null>;
  id: string;
  originalId: string;
}

export type ITimeSeriesDataResponse = {
  graphData: ITimeSeriesData[];
  graphKey: string;
  originalId: string;
};

export type Response = ITimeSeriesDataResponse[];
