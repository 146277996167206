import React from 'react';
import CustomIcon from 'components/CustomIcon';
import { dateFormatting } from 'utils/data';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
const FLASH = 'FLASH';

interface ItemProps {
  id: string;
  flashCompany?: string;
  flashAction?: string;
  flashTitle?: string;
  description: string;
  type: string;
  createdAt: string;
}

const typeTable = (type: string) => {
  switch (type) {
    case 'FLASH':
      return 'Flash';
    case 'ANALYSIS':
      return 'Daily Report';
    default:
      return '';
  }
};

export const CardSearch = (item: ItemProps) => {
  const { id, type, createdAt, flashCompany, flashAction, flashTitle } = item;
  const isFlash = type === FLASH;
  const date = format(new Date(createdAt), 'yyyy-MM-dd');
  const url = isFlash ? `/flashs/${id}` : `/daily-report/${date}`;
  return (
    <Link to={url} className="card-search">
      <CustomIcon width={24} type={isFlash ? 'flash' : 'daily-report'} />
      <div>
        <div className="type">{typeTable(type)}</div>
        <div className="date">{dateFormatting(createdAt)}</div>
      </div>
      {isFlash ? (
        <div className="card-search__bottom">
          <div>
            {flashCompany && (
              <span
                className="company"
                dangerouslySetInnerHTML={{
                  __html: flashCompany,
                }}
              />
            )}
            {flashAction && (
              <span
                className="action"
                dangerouslySetInnerHTML={{
                  __html: ` - ${flashAction}`,
                }}
              />
            )}
          </div>
          {flashTitle && (
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: flashTitle,
              }}
            />
          )}
        </div>
      ) : null}
    </Link>
  );
};
