import cx from 'classnames';
import VolumesChart from 'components/StockChart/VolumesChart';
import MultiStockChart from 'components/StockChart/MultiStockChart';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Indices from './components/Indices';
import Infos from './components/Infos';
import Price from './components/Price';
import { App } from '@capacitor/app';
import {
  getChart,
  getQuotes,
  getTradedVolumes,
  getMostActiveBrokers,
  clearState,
  getVolumesComparision,
  getPairInfo,
} from './core/slice';
import { AppContext } from 'contexts/AppContext';
import { Helmet } from 'react-helmet';
import TradedVolumes from 'features/daily-report/components/TradedVolumes';
import MostActiveBrokers from 'features/daily-report/components/MostActiveBrokers';
import { isAfter, parseISO, startOfDay } from 'date-fns'; // eslint-disable-line
import { format } from 'date-fns-tz'; // eslint-disable-line
import { enGB, fr } from 'date-fns/locale'; // eslint-disable-line
import './assets/styles.scss';
import RefreshPortal from 'components/RefreshPortal';
import FullScreenChart from 'components/FullScreenChart';
import { VOLUME_CHART } from './constants';
import useRefinitivWS from 'utils/useRefinitivWS';
import { IMarketCap, IQuote } from './core/interfaces';
import Loader from 'components/Loader';
import GetStoreData from './tools/getStoreData';
import dispatchStream from './tools/dispatchStream';
import MonitoringBlocks from './components/MonitoringBlocks';
import { setRangeFilter } from './core/slice';

const intervals = ['1D', '1M', 'YTD', '1Y', '5Y'];

interface ButtonType {
  type: string;
}

interface UpdateProps {
  date: string;
}

function Stock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState({ stockZoom: false, volumeZoom: false });
  const { range: currentRange } = useSelector((state: RootState) => state.stock.filter);
  const [filterActivate, setFilterActivate] = useState({ date: false, pairs: false });
  const [mktCap, setMktCap] = useState<IMarketCap>({ value: 0, currency: '€', separator: 'M' });
  const [euroVol, setEuroVol] = useState(0);
  const { isDesktopOrLaptop } = useContext(AppContext);

  const {
    quotesData,
    isQuoteDataLoading,
    user,
    emitterChartData,
    indicesChartData,
    tradedVolumes,
    isTradedVolumesLoading,
    mostActiveBrokers,
    isMostActiveBrokersLoading,
    volumesComparision,
    isVolumesComparisionDataLoading,
  } = GetStoreData();

  const lastUpdate =
    volumesComparision && volumesComparision.length > 0
      ? volumesComparision[0].volumes[0].updatedAt
      : undefined;

  const [emitterCurrentPrice, setEmitterCurrentPrice] = useState<IQuote>();
  if (!emitterCurrentPrice && quotesData) {
    setEmitterCurrentPrice(quotesData);
  }

  const indiceArr: string[] = [
    user?.emitter.ric || '',
    user?.emitter?.primaryIndice1?.codeRIC || '',
    user?.emitter?.primaryIndice2?.codeRIC || '',
    user?.emitter?.primaryIndice3?.codeRIC || '',
  ];

  const { quotesValues } = useRefinitivWS(indiceArr);

  App.addListener('appStateChange', state => {
    if (state.isActive && process.env.NODE_ENV !== 'development') {
      window.location.reload();
    }
  });

  function dispatchAll() {
    dispatch(getQuotes());
    dispatch(getChart({ range: '1D' }));
    dispatch(getVolumesComparision('1D'));
    dispatch(getPairInfo());
    dispatch(getTradedVolumes());
    dispatch(getMostActiveBrokers());
  }

  useEffect(() => {
    dispatchStream({
      quotesValues,
      filterActivate,
      emitterCurrentPrice,
      emitterChartData,
      indicesChartData,
      range: currentRange,
      setMktCap,
      setEuroVol,
      setEmitterCurrentPrice,
    });
  }, [quotesValues]);

  useEffect(() => {
    dispatchAll();
  }, []);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    [],
  );

  function closeAction() {
    //setFilters(filters)
    setZoom({ stockZoom: false, volumeZoom: false });
  }

  function renderTables() {
    return (
      <div className="tables__container">
        <h4>{t('stats:report.volumes.title')}</h4>
        <TradedVolumes volumes={tradedVolumes} loading={isTradedVolumesLoading} />
        <h4>{t('stats:report.brokers.title')}</h4>
        <MostActiveBrokers brokers={mostActiveBrokers} loading={isMostActiveBrokersLoading} />
        {/* TO ACTIVATE WHEN READY */}
        {false ? (
          <>
            <h4>{t('monitoringBlocks:report.title')}</h4>
            <MonitoringBlocks />
          </>
        ) : null}
      </div>
    );
  }

  const MultiStockComponent = (
    <MultiStockChart
      emitterName={user!.emitter.name}
      setFilterActivate={setFilterActivate}
      filterActivate={filterActivate}
      legend={intervals.map(i => ({ title: i }))}
      // execute when enlarge
      callback={() => {
        setZoom({ stockZoom: true, volumeZoom: false });
      }}
      // range={range}
      // setRange={setRange}
      range={currentRange}
      setRange={(type: string) => dispatch(setRangeFilter({ range: type }))}
    />
  );

  const VolumesComponent = (
    <VolumesChart
      loading={isVolumesComparisionDataLoading}
      chartData={volumesComparision}
      legend={[{ title: 'K(€) traded on Euronext vs 3M' }]}
      withoutTabs
      emitterName={user!.emitter.name}
      onLegendClick={type => dispatch(getVolumesComparision(type))}
    />
  );

  const InfoComponent = () =>
    quotesData ? (
      <Infos
        open={quotesData.open}
        prevClose={quotesData.previousClose}
        volEnx={euroVol || quotesData.volEnx}
        marketCap={(mktCap.value !== 0 && mktCap) || quotesData.marketCap}
        dayRange={quotesData.dayRange}
        yearRange={quotesData.yearRange}
        loading={isQuoteDataLoading}
      />
    ) : null;

  const Button = ({ type }: ButtonType) => {
    let action;
    let text = '';
    const className = 'stock-enlarge-button';
    if (type === VOLUME_CHART) {
      action = () => setZoom({ stockZoom: false, volumeZoom: true });
      text = 'Enlarge Chart';
    }
    return (
      <button className={className} type="button" onClick={action}>
        {text}
      </button>
    );
  };

  const LastUpdate = ({ date }: UpdateProps) => (
    <p className="price-cumlulated-updated">
      Last update {format(parseISO(date), 'do MMMM, K:mm b', { locale: enGB })}
    </p>
  );

  return (
    <div>
      <RefreshPortal action={dispatchAll} />
      <Helmet>
        <title>RMM - Stock</title>
      </Helmet>

      {/* When DESKTOP */}
      {isDesktopOrLaptop && user && (
        <>
          <div className="desktop-price-wrapper">
            <Price
              price={emitterCurrentPrice?.price || quotesData?.price}
              name={user!.emitter.name}
              loading={isQuoteDataLoading}
            />
            <Indices
              indices={emitterCurrentPrice?.indices || quotesData?.indices}
              loading={isQuoteDataLoading}
            />
          </div>

          <div className="desktop-content-wrapper">
            <div className="desktop-infos-stock">
              <InfoComponent />
              {!zoom.stockZoom ? (
                MultiStockComponent
              ) : (
                <FullScreenChart closeAction={closeAction} rotate={!isDesktopOrLaptop}>
                  {MultiStockComponent}
                </FullScreenChart>
              )}
            </div>
            <div className="desktop-stock-volumes">
              {!zoom.volumeZoom && VolumesComponent ? (
                VolumesComponent
              ) : (
                <FullScreenChart closeAction={closeAction}>{VolumesComponent} </FullScreenChart>
              )}
              {lastUpdate && <LastUpdate date={lastUpdate} />}
            </div>
            {!isTradedVolumesLoading || !isMostActiveBrokersLoading ? (
              renderTables()
            ) : (
              <div className="tables__container">
                {' '}
                <Loader />
              </div>
            )}
          </div>
        </>
      )}

      {/* WHEN MOBILE */}
      {!isDesktopOrLaptop && user ? (
        <>
          <Indices
            indices={emitterCurrentPrice?.indices || quotesData?.indices}
            loading={isQuoteDataLoading}
          />
          <div className={cx('price-info-stock')}>
            <div className={cx('price-info')}>
              <Price
                price={emitterCurrentPrice?.price || quotesData?.price}
                name={user!.emitter.name}
                loading={isQuoteDataLoading}
              />
              <InfoComponent />
              {!zoom.stockZoom && MultiStockComponent}
              {!zoom.volumeZoom && VolumesComponent}
              {lastUpdate && <LastUpdate date={lastUpdate} />}
              <Button type={VOLUME_CHART} />
            </div>
            {zoom.stockZoom && (
              <FullScreenChart closeAction={closeAction} rotate={!isDesktopOrLaptop}>
                {MultiStockComponent}
              </FullScreenChart>
            )}
            {zoom.volumeZoom && (
              <FullScreenChart closeAction={closeAction}>{VolumesComponent}</FullScreenChart>
            )}
            {!isTradedVolumesLoading || !isMostActiveBrokersLoading ? (
              renderTables()
            ) : (
              <div className="tables__container">
                {' '}
                <Loader />
              </div>
            )}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default Stock;
