import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SendEmail from './send-email';
import SendPassword from './send-password';
import { ReactComponent as Logo } from 'assets/logo.svg';

import './styles.scss';
import RequireNotAuth from 'app/RequireNotAuth';

function ResetPassword() {
  return (
    <>
      <Logo className="login-logo" />
      <Routes>
        <Route
          path="/"
          element={
            <RequireNotAuth>
              <SendEmail />
            </RequireNotAuth>
          }
        />
        <Route
          path=":resetKey"
          element={
            <RequireNotAuth>
              <SendPassword />
            </RequireNotAuth>
          }
        />
      </Routes>
    </>
  );
}

export default ResetPassword;
