import { createSlice } from '@reduxjs/toolkit';
import { IChart } from 'components/StockChart/interfaces';
import { IIndicesPrice, IPairsInformation, IImportedBrokerNotes, IList } from './interfaces';

/** **** INTERFACES */

export interface ISectorState {
  indices: {
    chart: {
      isLoading: boolean;
      items: [] | IChart[];
      error: Record<string, unknown> | null;
    };
    prices: {
      isLoading: boolean;
      items: [] | IIndicesPrice[];
      error: Record<string, unknown> | null;
    };
  };
  pairs: {
    isLoading: boolean;
    items: [] | IPairsInformation[];
    error: Record<string, unknown> | null;
  };
  brokerNotes: {
    isLoading: boolean;
    items: [] | IImportedBrokerNotes[];
    error: Record<string, unknown> | null;
  };
  lists: {
    isLoading: boolean;
    items: null | IList[];
    error: Record<string, unknown> | null;
    timeIntrl: string | null;
  };
}

/** **** INITIAL STATE */

const initialState: ISectorState = {
  indices: {
    chart: {
      isLoading: true,
      items: [],
      error: null,
    },
    prices: {
      isLoading: true,
      items: [],
      error: null,
    },
  },
  pairs: {
    isLoading: true,
    items: [],
    error: null,
  },
  brokerNotes: {
    isLoading: true,
    items: [],
    error: null,
  },
  lists: {
    isLoading: true,
    items: null,
    error: null,
    timeIntrl: null,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'sector',
  initialState,
  reducers: {
    getPairsInformations: state => {
      state.pairs.isLoading = true;
    },
    getPairsInformationsSuccess: (state, action) => {
      state.pairs.items = action.payload;
      state.pairs.isLoading = false;
    },
    getPairsInformationsFailed: (state, action) => {
      state.pairs.error = action.payload.error;
      state.pairs.isLoading = false;
    },
    getImportedBrokerNotes: state => {
      state.brokerNotes.isLoading = true;
    },
    getImportedBrokerNotesSuccess: (state, action) => {
      state.brokerNotes.items = action.payload;
      state.brokerNotes.isLoading = false;
    },
    getImportedBrokerNotesFailed: (state, action) => {
      state.brokerNotes.error = action.payload.error;
      state.brokerNotes.isLoading = false;
    },
    getLists: (state, action) => {
      state.lists.timeIntrl = action.payload;
      state.lists.isLoading = true;
    },
    getListsSuccess: (state, action) => {
      state.lists.items = action.payload;
      state.lists.isLoading = false;
    },
    getListsFailed: (state, action) => {
      state.lists.error = action.payload.error;
      state.lists.isLoading = false;
    },
  },
});

export const {
  getPairsInformations,
  getPairsInformationsSuccess,
  getPairsInformationsFailed,
  getImportedBrokerNotes,
  getImportedBrokerNotesSuccess,
  getImportedBrokerNotesFailed,
  getLists,
  getListsSuccess,
  getListsFailed,
} = slice.actions;

export default slice.reducer;
