import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState, SendMessage } from 'react-use-websocket';
import { IWSRefinitivMEssage, Fields } from '../types/ws';
import { getRefinitivToken } from './refinitiv';

const WS_URL = process.env.REACT_APP_WS_URL || 'wss://streaming.rkd.refinitiv.com/WebSocket';
const APP_ID = process.env.REACT_APP_WS_APP_ID || 'FrancoisDeroussyRothschildCom';

let pingSendTime = 0;
let pingTimeoutInterval = 30000;

interface IWSQuoteValues extends Fields {
  ric: string;
}

function parseWS(data?: IWSRefinitivMEssage[]): IWSQuoteValues[] {
  if (!data) return [];

  const allData = data.map(d => {
    if (d.Fields && d.Key.Name) {
      return {
        ...d.Fields,
        ric: d.Key.Name,
      };
    }
    return false;
  });

  const dataWithoutFalse = allData.filter(d => d !== false);

  // @ts-ignore
  return dataWithoutFalse;
}

function checkPing(status: ReadyState, sendMessage: SendMessage) {
  if (status !== ReadyState.CLOSED && pingSendTime !== 0 && Date.now() > pingSendTime) {
    var msg = '{"Type":"Ping"}';
    sendMessage(msg);
    pingSendTime = 0;
  }
}

export default function useRefinitivWS(rics: string[]) {
  const [token, setToken] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [quotesValues, setQuotesValues] = useState<IWSQuoteValues[]>([]);

  // 1. Get a Refinitiv Token
  useEffect(() => {
    async function setLocalToken() {
      const token = await getRefinitivToken();
      if (token) {
        setToken(token);
      }
    }
    setLocalToken();
  }, []);

  // 2. When token is ready, open a connection
  const { sendJsonMessage, sendMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      protocols: 'tr_json2',
      onOpen,
      onClose,
      onError: error => {
        console.error('WebSocket error:', error);
      },
      shouldReconnect: () => false,
    },
    !!token,
  );

  // 3. When connection is Open
  function onOpen() {
    var msg = '{"Type":"Ping"}';
    sendMessage(msg);
    pingSendTime = Date.now() + pingTimeoutInterval / 3;

    setInterval(() => checkPing(readyState, sendMessage), 1000);

    const payload = {
      ID: 1,
      Domain: 'Login',
      Key: {
        NameType: 'AuthnToken',
        Elements: {
          AuthenticationToken: token,
          ApplicationId: APP_ID,
          Position: '0.0.0.0/net',
        },
      },
    };
    sendJsonMessage(payload);
  }

  // 4. handle onClose
  function onClose() {
    console.log('WebSocket Closed');
  }

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage[0].Type === 'Refresh') {
        setIsReady(true);
        subscribeToMarketPlace();
      }
      if (lastJsonMessage[0].Type === 'Ping') {
        sendMessage('{"Type":"Pong"}');
      }
      if (lastJsonMessage[0].Type === 'Pong') {
        console.log('Server sucessfully pinged');
      }
    }
  }, [lastJsonMessage]);

  function subscribeToMarketPlace() {
    const payload = {
      ID: 2,
      Domain: 'MarketPrice',
      Key: {
        Name: rics,
      },
      View: ['TRDPRC_1', 'ACVOL_1', 'PCTCHNG', 'CF_NETCHNG', 'TRDVOL_1', 'MKT_CAP'],
    };
    sendMessage(JSON.stringify(payload));
  }

  useEffect(() => {
    const values = parseWS(lastJsonMessage as unknown as IWSRefinitivMEssage[]);
    if (values && values.length) {
      setQuotesValues(values);
    }
  }, [lastJsonMessage]);

  return { isReady, lastJsonMessage, quotesValues };
}
