import { call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import { IValuation } from './interfaces';

import { getLists, getListsSuccess, getListsFailed } from './slice';

function* lists() {
  const requestURL = '/api/import/valuation';
  try {
    const result: IValuation[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getListsSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getListsFailed({ error: err.responseJSON }));
  }
}

export default [takeLatest(getLists.type, lists)];
