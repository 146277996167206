import { createSlice } from '@reduxjs/toolkit';
import { WSFlash } from '../../interfaces';

/** **** INTERFACES */

export interface IFlashState {
  flash?: WSFlash;
  isLoading: boolean;
  error: Object | null;
}

/** **** INITIAL STATE */

const initialState: IFlashState = {
  flash: undefined,
  isLoading: false,
  error: null,
};

/** **** SLICE */

export const slice = createSlice({
  name: 'flashs',
  initialState,
  reducers: {
    getFlash: (state, action) => {
      state.isLoading = true;
    },
    getFlashSuccess: (state, action) => {
      state.flash = action.payload;
      state.isLoading = false;
    },
    getFlashFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
  },
});

export const { getFlash, getFlashSuccess, getFlashFailed } = slice.actions;

export default slice.reducer;
