import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { sendEmail as sendEmailAction, sendEmailSuccess, sendEmailFailed } from './slice';

interface SendEmailPayload {
  email: string;
}

function* sendEmail(sendEmailPayload: PayloadAction<SendEmailPayload>) {
  const requestURL = '/api/auth/reset-password/mail';
  try {
    yield call(request, requestURL, {
      method: 'POST',
      body: { ...sendEmailPayload.payload },
    });
    yield put(sendEmailSuccess());
  } catch (err) {
    console.error('err', err);
    yield put(sendEmailFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(sendEmailAction.type, sendEmail)];
