import React from 'react';
import { LineChartData } from 'components/Charts/LineChart';

interface Props {
  data: LineChartData;
}

export function LendingFooter({ data }: Props) {
  const { datasets } = data;
  const lendable = datasets[0]?.data?.pop();
  const shared = datasets[1]?.data?.pop();
  const lendableColor = String(datasets[0]?.backgroundColor);
  const sharedColor = String(datasets[1]?.backgroundColor);
  return (
    <div className="chart-footer">
      <div>
        <div className="chart-footer__rectangle" style={{ backgroundColor: lendableColor }}></div>
        <span>Lendable as %</span>
        <span>{lendable || '0'}</span>%
      </div>
      <div>
        <div className="chart-footer__rectangle" style={{ background: sharedColor }}></div>
        <span>% Shared Outstanding On Loan</span>
        <span>{shared || '0'}</span>%
      </div>
    </div>
  );
}
