import type { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
} from 'chart.js';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ScreenProps } from 'components/FullScreenChart/interfaces';
import React from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
);

export const defaultOptions: ChartOptions<'bar'> = {};

export type BarChartData = ChartData<'bar'>;
export type BarChartOptions = ChartOptions<'bar'>;

interface BarChartProps extends Omit<ChartProps, 'type'> {
  options?: BarChartOptions;
  data: BarChartData;
  className?: string;
  measures?: ScreenProps;
}
function BarChart({ options, data, className, measures, ...rest }: BarChartProps) {
  const optionsMerged = { ...defaultOptions, ...options };
  return <Bar {...rest} className={className} options={optionsMerged} data={data} {...measures} />;
}
export default BarChart;
