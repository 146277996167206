import { createSlice } from '@reduxjs/toolkit';
import { LendableDataGroup, StockDataGroup, ISellersRequest } from './interfaces';

/** **** INTERFACES */

export interface IStockState {
  lending: {
    lendables: LendableDataGroup | null;
    shares: LendableDataGroup | null;
    isLoading: boolean;
    error?: Record<string, unknown>;
  };
  stock: {
    stocks: StockDataGroup | null;
    isLoading: boolean;
    error?: Record<string, unknown>;
  };
  sellers: {
    sellers: ISellersRequest[] | [];
    isLoading: boolean;
    error?: Record<string, unknown>;
  };
}

/** **** INITIAL STATE */

const initialState: IStockState = {
  lending: {
    lendables: null,
    shares: null,
    isLoading: false,
    error: undefined,
  },
  stock: {
    stocks: null,
    isLoading: false,
    error: undefined,
  },
  sellers: {
    sellers: [],
    isLoading: false,
    error: undefined,
  },
};

/** **** SLICE */
export const slice = createSlice({
  name: 'short',
  initialState,
  reducers: {
    getLendings: state => {
      state.lending.isLoading = true;
    },
    getLendingsSuccess: (state, action) => {
      state.lending.lendables = action.payload.lendables;
      state.lending.shares = action.payload.shares;
      state.lending.isLoading = false;
    },
    getLendingsFailed: (state, action) => {
      state.lending.error = action.payload.error;
      state.lending.isLoading = false;
    },
    getStock: state => {
      state.stock.isLoading = true;
    },
    getStockSuccess: (state, action) => {
      state.stock.stocks = action.payload;
      state.stock.isLoading = false;
    },
    getStockFailed: (state, action) => {
      state.stock.error = action.payload.error;
      state.stock.isLoading = false;
    },
    getSellers: state => {
      state.sellers.isLoading = true;
    },
    getSellersSuccess: (state, action) => {
      state.sellers.sellers = action.payload;
      state.sellers.isLoading = false;
    },
    getSellersFailed: (state, action) => {
      state.sellers.error = action.payload.error;
      state.sellers.isLoading = false;
    },
    clearState: state => {
      state.lending = initialState.lending;
      state.stock = initialState.stock;
      state.sellers = initialState.sellers;
    },
  },
});

export const {
  getLendings,
  getLendingsSuccess,
  getLendingsFailed,
  getStock,
  getStockSuccess,
  getStockFailed,
  getSellers,
  getSellersSuccess,
  getSellersFailed,
  clearState,
} = slice.actions;

export default slice.reducer;
