import GoBack from 'components/GoBack';
import React from 'react';
import './styles.scss';
import { TopBarProps } from './interfaces';
import CustomIcon from 'components/CustomIcon';
import { ReactComponent as Logo } from 'assets/logo-full.svg';
import cx from 'classnames';
import { Link } from 'react-router-dom';

function TopBarMobile({ showHeader, onUserButtonClick, isFullSize, isMidSize }: TopBarProps) {
  return (
    <header
      className={cx({
        'mobile-header': showHeader,
        'empty-header': !showHeader,
        'full-size': isFullSize,
        'mid-size': isMidSize,
      })}
    >
      <div className="menu-items">
        <div className="menu-items__left">
          <GoBack className="mobile-top-menu-item" />
          <Link to={`/search/`} className="search btn__rm-styles">
            <CustomIcon className="mobile-top-menu-item" type="search-white" />
            <span>Search</span>
          </Link>
        </div>
        <button
          aria-label="Open user page"
          className="user btn__rm-styles"
          type="button"
          onClick={onUserButtonClick}
        >
          <CustomIcon className="mobile-top-menu-item" type="user" />
        </button>
      </div>
      <div className="mobile-logo-wrapper">
        <Logo className="mobile-logo" />
      </div>
    </header>
  );
}

export default TopBarMobile;
