/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { UrbanAirShip } from '@awesome-cordova-plugins/urbanairship';
import { history } from '../app/store';
import { App, AppState } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

export const registerNotification = async function registerNotification() {
  try {
    await UrbanAirShip.takeOff({
      development: {
        appKey: process.env.REACT_APP_UA_DEVELOPMENT_APPKEY,
        appSecret: process.env.REACT_APP_UA_DEVELOPMENT_APPSECRET,
      },
      production: {
        appKey: process.env.REACT_APP_UA_PRODUCTION_APPKEY,
        appSecret: process.env.REACT_APP_UA_PRODUCTION_APPSECRET,
      },
    });
    await UrbanAirShip.setUserNotificationsEnabled(true);
  } catch (err) {
    console.error(err);
  }
  return null;
};

const AppUrlListener: React.FC<any> = () => {
  const [appState, setAppState] = useState<AppState>();
  const [link, setLink] = useState<string>();

  useEffect(() => {
    document.addEventListener(
      'urbanairship.registration',
      event => {
        console.log('event', event);
      },
      false,
    );

    document.addEventListener(
      'urbanairship.push',
      event => {
        console.log('Incoming push', event);
      },
      false,
    );

    document.addEventListener(
      'urbanairship.notification_opened',
      event => {
        console.log('Notification opened', event);
      },
      false,
    );

    document.addEventListener(
      'urbanairship.deep_link',
      async (event: any) => {
        console.log('Deep link event', event);
        console.log('Deep link state', appState);
        if (appState && appState.isActive) {
          history.push(event.deepLink);
        } else {
          setLink(event.deepLink);
        }
      },
      false,
    );

    App.addListener('appStateChange', state => {
      setAppState(state);
    });

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      PushNotifications.addListener('registration', token => {
        localStorage.setItem('push_registration_token', token.value);
        console.log(`Push registration success, token: ${token.value}`);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: unknown) => {
        console.log(`Error on registration: ${JSON.stringify(error)}`);
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', async notification => {
        console.log(`Push received: ${JSON.stringify(notification)}`);
        console.log('PUSH NOTIFICATIONS', UrbanAirShip.getDeepLink(false));
        console.log('CHANNEL ID', UrbanAirShip.getChannelID());
      });
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
        console.log(`Push action performed: ${JSON.stringify(notification)}`);
        if (notification.notification.data['^d']) {
          history.push(notification.notification.data['^d'].split('app:/').pop());
        }
      });
    }

    App.addListener('appUrlOpen', data => {
      console.log('appUrlOpen EVENT', data);
    });
    return () => {
      App.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (link && link !== '') {
      history.push(link);
    }
  }, [link]);

  return null;
};

export default AppUrlListener;
