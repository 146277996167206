/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { IMonitoringBlock } from '../core/interfaces';
import { useTable, useSortBy, Cell, Row, HeaderGroup, Column } from 'react-table';
import cx from 'classnames';

import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getMonitoringBlocks } from '../core/slice';
import { RootState } from 'app/store';

function MonitoringBlocks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: blocks, isLoading } = useSelector(
    (state: RootState) => state.stock.monitoringBlocks,
  );

  useEffect(() => {
    dispatch(getMonitoringBlocks());
  }, []);

  const columns: Column<IMonitoringBlock>[] = React.useMemo(
    () => [
      {
        Header: t('monitoringBlocks:report.time') as string,
        accessor: 'time',
        sortType: 'basic',
      },
      {
        Header: t('monitoringBlocks:report.price') as string,
        accessor: 'price',
      },
      {
        Header: t('monitoringBlocks:report.size') as string,
        accessor: 'size',
        sortType: 'basic',
      },
      {
        Header: t('monitoringBlocks:report.platformFullName') as string,
        accessor: 'platformFullName',
        sortType: 'basic',
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [{ id: 'platform', desc: false }],
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: blocks,
      initialState,
    },
    useSortBy,
  );

  if (isLoading) {
    return <p>Chargement...</p>;
  }

  if (!blocks || blocks.length === 0) {
    return <p className="no-data">{t('monitoringBlocks:report.noData')}</p>;
  }

  const lastUpdatedAt = blocks && blocks[0] && blocks[0].updatedAt;

  return (
    <div className="indices-prices">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<IMonitoringBlock>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={cx({ selected: column.isSorted })}
                >
                  <div className="th-wrapper">
                    <div className="header-name">{column.render('Header')}</div>
                    {column.isSorted ? (
                      <div className="arrow">
                        <span>{column.isSortedDesc ? <IoMdArrowDown /> : <IoMdArrowUp />}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<IMonitoringBlock>) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell<IMonitoringBlock>) => (
                  <td {...cell.getCellProps()} className={cell.column.isSorted ? 'sorted' : ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pairs-legend">
        <i>
          {lastUpdatedAt && `Last update ${format(parseISO(lastUpdatedAt), 'do MMMM, K:mm b')}`}
        </i>
      </div>
    </div>
  );
}

export default MonitoringBlocks;
