import { RootState } from 'app/store';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sendPassword, checkKey } from './core/slice';
import ErrorsContent from 'components/FormErrors';
import CustomIcon from 'components/CustomIcon';

interface IForm {
  password: string;
}

function SendPassword() {
  const { t } = useTranslation();
  const { resetKey } = useParams<{ resetKey: string }>();
  const { register, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
  const { validKey, success, isLoading, error } = useSelector(
    (state: RootState) => state.sendPassword,
  );
  const dispatch = useDispatch();
  const passwordInput = watch('password');
  const passwordConfirmationInput = watch('passwordConfirmation');

  useEffect(() => {
    dispatch(checkKey({ resetKey }));
  }, [resetKey]);

  function onSubmit(data: IForm) {
    dispatch(sendPassword({ newPassword: data.password, resetKey }));
  }

  if (success) {
    return (
      <div className="app-container-wrapper background nooverflow">
        <div className="app-container app-container__login">
          <div className="center">
            <div className="success">{t('emails:password.success')}</div>
            <div className="login__links">
              <Link to="/">Return Home</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (validKey) {
    return (
      <div className="app-container-wrapper background">
        <div className="app-container app-container__login">
          <form onSubmit={handleSubmit(onSubmit)} className="login__container no-logo" noValidate>
            <div className="title__container">Change your password</div>
            <div className="login__input-container">
              <label htmlFor="password">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className=""
                  placeholder=" "
                  {...register('password', { required: true, minLength: 8, maxLength: 20 })}
                />
                <span>Password</span>
                <button
                  type="button"
                  className="icon-input"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <CustomIcon type={showPassword ? 'show' : 'hide'} />
                </button>
              </label>
            </div>
            <div className="login__input-container">
              <label htmlFor="passwordConfirmation">
                <input
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  id="passwordConfirmation"
                  className=""
                  placeholder=" "
                  {...register('passwordConfirmation', {
                    required: true,
                    validate: value => value === passwordInput,
                  })}
                />
                <span>Confirm password</span>
                <button
                  type="button"
                  className="icon-input"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                >
                  <CustomIcon type={showPasswordConfirmation ? 'show' : 'hide'} />
                </button>
              </label>
              {passwordConfirmationInput !== '' && (
                <div className="icon-input icon-input--right">
                  <CustomIcon
                    key={1}
                    type={passwordConfirmationInput !== passwordInput ? 'wrong' : 'correct'}
                  />
                </div>
              )}
            </div>
            <ErrorsContent error={error} />
            <div className="login__button-container">
              <button disabled={!formState.isValid} type="submit">
                {isLoading ? 'Loading...' : 'Send new password'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container-wrapper background">
      <div className="app-container app-container__login">
        <div className="center">
          <div className="error">{t('emails:password.key')}</div>
          <div className="login__links">
            <Link to="/reset-password">Send new email</Link> or return <Link to="/">Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendPassword;
