import { RootState } from 'app/store';
import cx from 'classnames';
import React from 'react';
import { IoMdArrowBack, IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import CustomIcon from 'components/CustomIcon';

interface Props {
  className?: string;
}

function GoBack({ className }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const show = useSelector((state: RootState) => state.goback.show);
  const passwordChangeRequired = useSelector(
    (state: RootState) => state.session.user.data?.passwordChangeRequired,
  );

  const currentPath = location.pathname;

  function goToParent() {
    navigate(-1);
  }

  if (!show || currentPath === '/') {
    return (
      <div className={cx('button__email-container', className)}>
        <a
          href="mailto:RothschildandcoCMS@rothschildandco.com"
          target="blank"
          rel="noopener noreferrer"
          className="button__email"
        >
          <CustomIcon type="contact" />
        </a>
      </div>
    );
  }

  let icon = <IoMdArrowBack size={32} />;
  if (show === 'close') icon = <IoMdClose size={32} />;

  return (
    <button
      aria-label="Go Back"
      className={cx('goback btn__rm-styles', className, {
        hide: !show || currentPath === '/' || passwordChangeRequired,
      })}
      type="button"
      onClick={goToParent}
    >
      {icon}
    </button>
  );
}

export default GoBack;
