import React from 'react';
import cx from 'classnames';
import { Attachments } from 'features/brokers-note/core/interfaces';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'components/CustomIcon';

interface AttachmentProps extends Attachments {
  cssClasses?: Array<string>;
  page?: string;
}

function CardAttachment({ id, filename, cssClasses, type, page }: AttachmentProps) {
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_API_URL}/api/attachment/${id}/content`;

  function onDownloadClick(e: React.MouseEvent | React.KeyboardEvent, urlToOpen: string) {
    e.preventDefault();
    e.stopPropagation();
    window.open(urlToOpen, '_blank');
  }
  if (type === 'application/pdf' && page !== 'flash') {
    return (
      <div
        className={cx('attachments', cssClasses)}
        onClick={e => onDownloadClick(e, url)}
        onKeyDown={e => onDownloadClick(e, url)}
        role="button"
        tabIndex={0}
      >
        <CustomIcon width={24} type="attachment" />
        <span>{t('flashs:p.download')}</span>
      </div>
    );
  }
  return (
    <div className={cx('attachments', cssClasses)}>
      <CustomIcon width={24} type="attachment" /> {filename}
    </div>
  );
}

export default CardAttachment;
