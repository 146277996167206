import React from 'react';
import ReactDOM from 'react-dom';
import Loader from './index';

function PortalLoader() {
  const portalContainer = document.getElementById('loader-portal');
  if (portalContainer) {
    return ReactDOM.createPortal(
      /* @ts-ignore */
      <div className="portal__container">
        <div className="portal__overlay" />
        <Loader text="Loading..." />
      </div>,
      portalContainer,
    );
  }
  return <div />;
}

export default PortalLoader;
