import React, { useContext } from 'react';
import '../assets/stockFilter.scss';
import { FilterList } from './templates';
import PortalLoader from 'components/Loader/PortalLoader';
import { IPairInfo } from 'features/mkt-data/sector/core/interfaces';
import { getChart } from '../core/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'contexts/AppContext';
import { RootState } from 'app/store';
import cx from 'classnames';
import { resetPairFilter, cancelPairFilter } from '../core/slice';

interface PropsStockFilter {
  pairInfo?: IPairInfo[];
  onHide: Function;
  getChartPair: Function;
  range: string;
  fullScreen: boolean;
}

function StockFilter({
  pairInfo,
  onHide,
  getChartPair,
  range,
  fullScreen = false,
}: PropsStockFilter) {
  const dispatch = useDispatch();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { tempPair: pairFilters } = useSelector((state: RootState) => state.stock.filter);
  const { dateStart, dateEnd } = useSelector((state: RootState) => state.stock.filter.date);

  if (!pairInfo) return <PortalLoader />;

  function resetFilter() {
    dispatch(resetPairFilter());
    dispatch(getChart({ range: range, start: dateStart, end: dateEnd }));
  }

  function handleCancel() {
    onHide();
    dispatch(cancelPairFilter());
  }

  return (
    <div
      className={cx('stock-filter', {
        mobile: !isDesktopOrLaptop,
      })}
    >
      <div className="container-header">
        <div className="container-header-filter">
          <span className="filter-close" onClick={handleCancel}>
            Cancel
          </span>
          <span className="stock-filter-main-title">Peers</span>
        </div>
        <div className="container-header-filter-feature">
          <div>{`${pairFilters.length} out of 6 selected`}</div>
          <div className="reset-filter-button" onClick={resetFilter}>
            Reset Filter
          </div>
        </div>
      </div>

      {pairInfo && (
        <FilterList
          pairInfo={pairInfo}
          onHide={onHide}
          getChartPair={getChartPair}
          fullScreen={fullScreen}
          resetFilter={resetFilter}
        />
      )}
    </div>
  );
}

export default StockFilter;
