import { createSlice } from '@reduxjs/toolkit';
import { IValuation } from './interfaces';

/** **** INTERFACES */

export interface ISectorState {
  lists: {
    isLoading: boolean;
    items: null | IValuation[];
    error: {} | null;
  };
}

/** **** INITIAL STATE */

const initialState: ISectorState = {
  lists: {
    isLoading: true,
    items: null,
    error: null,
  },
};

/** **** SLICE */

export const slice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {
    getLists: state => {
      state.lists.isLoading = true;
    },
    getListsSuccess: (state, action) => {
      state.lists.items = action.payload;
      state.lists.isLoading = false;
    },
    getListsFailed: (state, action) => {
      state.lists.error = action.payload.error;
      state.lists.isLoading = false;
    },
  },
});

export const { getLists, getListsSuccess, getListsFailed } = slice.actions;

export default slice.reducer;
