import React from 'react';
import { dateFormatting, getRecommendationType } from 'utils/data';
import { IRecommendations, Attachments } from '../core/interfaces';
import cx from 'classnames';
import CardAttachment from 'components/CardAttachment';
import CustomIcon, { PictoTypesEnum } from 'components/CustomIcon';

import '../assets/recommendation-card.scss';

interface RecommendationProps {
  previous?: boolean;
  currentObjective: string;
  previousObjective: string;
  currency: string;
  recommendation: string;
}

const types = ['Sector', 'Initiation', 'Change'];

function RecommendationInfo({
  previous,
  currentObjective,
  previousObjective,
  currency,
  recommendation,
}: RecommendationProps) {
  const result = getRecommendationType(recommendation);
  let arrowPrice;
  if (!previous && previousObjective) {
    if (parseFloat(currentObjective) > parseFloat(previousObjective)) {
      arrowPrice = (
        <span className="recommendation__price-arrow arrow-up">
          <CustomIcon type="up" width={22} />
        </span>
      );
    } else if (parseFloat(currentObjective) < parseFloat(previousObjective)) {
      arrowPrice = (
        <span className="recommendation__price-arrow arrow-down">
          <CustomIcon type="down" width={22} />
        </span>
      );
    }
  }

  const previousObjectiveFormatted =
    parseFloat(previousObjective) === 0 ? '-' : parseFloat(previousObjective).toFixed(2);
  const currentObjectiveFormatted =
    parseFloat(currentObjective) === 0 ? '-' : parseFloat(currentObjective).toFixed(2);
  return (
    <>
      <div
        className={cx('recommendation__info', {
          'recommendation__info--opacity': previous,
        })}
      >
        <div
          className={`recommendation__bullets action__bullets recommendation__bullets-${recommendation.toLowerCase()}`}
        >
          <span className={result}>{recommendation}</span>
        </div>
        <div className="recommendation__price">
          {previous ? previousObjectiveFormatted : currentObjectiveFormatted}
          <span>{currency}</span>
          {arrowPrice}
        </div>
      </div>
      {previous ? (
        <div className="recommendation__info_separator">
          <CustomIcon type="right" width={22} />
        </div>
      ) : (
        ''
      )}
    </>
  );
}

function getRecommendationTypeIcon(name: string | undefined) {
  let icon: PictoTypesEnum = 'comment';
  switch (name) {
    case 'change':
    case 'Change':
      icon = 'broker-change';
      break;
    case 'init':
    case 'Initiation':
      icon = 'broker-initiation';
      break;
    case 'Sector':
    case 'secteur':
    case 'Secteur':
      icon = 'broker-sectornote';
      break;
  }
  return icon;
}

function RecommendationCard(props: IRecommendations) {
  const date = props.createdAt ? props.createdAt : props.dateTime;
  const dateString = dateFormatting(date);

  let sectorNote;
  let initiateNote;
  let recoChange;
  if (props.sectorNote) {
    const iconSector = getRecommendationTypeIcon(types[0]);
    sectorNote = (
      <div className="recommendation__type">
        <CustomIcon type={iconSector} /> {types[0]}
      </div>
    );
  }
  if (props.initiationNote) {
    const iconInitiate = getRecommendationTypeIcon(types[1]);
    initiateNote = (
      <div className="recommendation__type">
        <CustomIcon type={iconInitiate} /> {types[1]}
      </div>
    );
  }
  if (props.recoChange) {
    const iconChange = getRecommendationTypeIcon(types[2]);
    recoChange = (
      <div className="recommendation__type">
        <CustomIcon type={iconChange} /> {types[2]}
      </div>
    );
  }

  let previousRecommendation;
  if (props.previousObjective && props.previousRecommendation) {
    previousRecommendation = (
      <RecommendationInfo
        previous={true}
        currentObjective={props.currentObjective}
        previousObjective={props.previousObjective}
        currency={props.currency}
        recommendation={props.previousRecommendation}
      />
    );
  }

  const currentRecommendations = (
    <RecommendationInfo
      previous={false}
      currentObjective={props.currentObjective}
      previousObjective={props.previousObjective}
      currency={props.currency}
      recommendation={props.currentRecommendation}
    />
  );
  let attachments;
  if (props.attachments) {
    attachments = props.attachments.map((attachment: Attachments) => {
      const cssClasses = ['recommendation__attachments'];
      // if (props.previousObjective) cssClasses.push('right');
      return (
        <CardAttachment
          key={attachment.filename}
          {...attachment}
          cssClasses={cssClasses}
          filename={props.filename || attachment.filename}
        />
      );
    });
  }

  return (
    <div className="groupByData__card recommendation">
      <div className="recommendation__header groupByData__header">
        <div className="recommendation__company groupByData__company">{props.broker}</div>
        <div className="recommendation__date groupByData__date_card">{dateString}</div>
      </div>
      <div className="recommendation__body">
        {props.sectorNote || props.initiationNote || props.recoChange ? (
          <div className="recommendation__types">
            {sectorNote}
            {initiateNote}
            {recoChange}
          </div>
        ) : (
          ''
        )}
        {props.title && <p>{props.title}</p>}
        <div className="recommendation__prices">
          {previousRecommendation}
          {currentRecommendations}
        </div>
        {attachments}
      </div>
    </div>
  );
}

export default RecommendationCard;
