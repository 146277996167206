import React, { useState, useContext, useEffect } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import { AiOutlineClose } from 'react-icons/ai';
import { AppContext } from 'contexts/AppContext';
import { useDispatch } from 'react-redux';
import { addDateFilter } from 'features/mkt-data/stock/core/slice';

const FORMAT = 'dd/MM/yyyy';
const PLACEHOLDER = 'DD/MM/YYYY';

interface PickerProps {
  date: Date | null;
  setActivePicker: Function;
  type: string;
  activePicker: string;
}

interface MonthProps {
  date: Date;
  localeUtils: any;
  onChange: Function;
}

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 20, 0);
const currentMonth = new Date(currentYear, new Date().getMonth());
const toMonth = new Date(currentYear, 11);

function YearMonthForm({ date, localeUtils, onChange }: MonthProps) {
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }
  const handleChange = function handleChange(e: any) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={e => handleChange(e)} value={date.getMonth()}>
        {months.map((month: string, i: number) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

export const Picker = ({ date, setActivePicker, type, activePicker }: PickerProps) => {
  const dispatch = useDispatch();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const [month, setMonth] = useState(currentMonth);
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState(date ? formatDate(date, FORMAT) : '');

  function formatDate(date: Date, format: string) {
    return dateFnsFormat(date, format);
  }
  function handleYearMonthChange(month: Date) {
    setMonth(month);
  }
  function handleDayClick(day: Date) {
    const date = formatDate(day, FORMAT);
    setInputValue(date);
    setShow(false);
    dispatch(addDateFilter({ day, type }));
  }
  function manageClick() {
    setShow(true);
    setActivePicker(type);
    !isDesktopOrLaptop && toggleMainClose(false);
  }
  function manageClose() {
    setShow(false);
    setActivePicker('');
    !isDesktopOrLaptop && toggleMainClose(true);
  }

  function toggleMainClose(value: Boolean) {
    // X fullScreen button is only toggled when mobile
    const mainCloseButton = document.querySelector('.close-fullscreen');
    if (mainCloseButton) {
      value ? mainCloseButton.classList.remove('hide') : mainCloseButton.classList.add('hide');
    }
  }

  useEffect(() => {
    date && setInputValue(formatDate(date, FORMAT));
  }, [date]);

  return (
    <div className="input-calendar">
      <input
        className="calendar"
        placeholder={PLACEHOLDER}
        onClick={() => manageClick()}
        value={inputValue}
        readOnly
      />
      {show && activePicker === type && (
        <div className="datepicker-popup">
          <AiOutlineClose className="close-datepicker-button" onClick={manageClose} />
          <DayPicker
            onDayClick={handleDayClick}
            disabledDays={days => days >= new Date()}
            // stuff for month / year dropdowns
            month={month}
            fromMonth={fromMonth}
            toMonth={toMonth}
            captionElement={({ date, localeUtils }) => (
              <YearMonthForm
                date={date}
                localeUtils={localeUtils}
                onChange={handleYearMonthChange}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Picker;
