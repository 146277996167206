import { shouldShowGraph } from 'utils/data';
import _ from 'lodash';
import { fr } from 'date-fns/locale'; // eslint-disable-line
import { IChart } from 'components/StockChart/interfaces';
import { getChartSuccess, getIndicesChartSuccess } from '../core/slice';
import { IQuote } from '../core/interfaces';
import { format } from 'date-fns';
import { Fields } from 'types/ws';
import { store } from 'app/store';

interface IWSQuoteValues extends Fields {
  ric: string;
}

interface IStreamDispatcher {
  quotesValues: IWSQuoteValues[];
  emitterCurrentPrice?: IQuote;
  emitterChartData?: IChart | null;
  indicesChartData?: IChart[] | null;
  range: string;
  setMktCap: Function;
  setEuroVol: Function;
  setEmitterCurrentPrice: Function;
  filterActivate: { pairs: boolean; date: boolean };
}

function dispatchStream({
  quotesValues,
  emitterCurrentPrice,
  emitterChartData,
  indicesChartData,
  range,
  setMktCap,
  setEuroVol,
  setEmitterCurrentPrice,
  filterActivate,
}: IStreamDispatcher) {
  const showGraph = emitterChartData ? shouldShowGraph(range, [emitterChartData]) : false;

  quotesValues.forEach(refQuote => {
    const currentPriceCopy = _.cloneDeep(emitterCurrentPrice);

    const value = Number(refQuote.TRDPRC_1);
    const date = format(new Date(Date()), "yyyy-MM-dd'T'HH:mm:ssxxx", { locale: fr });
    const tradePrice = Number(refQuote.TRDPRC_1);
    const percentChange = Number(refQuote.PCTCHNG);
    const netPriceChange = Number(refQuote.CF_NETCHNG);
    const euronextVolume = Number(refQuote.ACVOL_1);
    const actualVolume = Number(refQuote.TRDVOL_1);

    if (refQuote.ric === emitterChartData?.ticker_code) {
      if (refQuote?.MKT_CAP) {
        setMktCap({ value: Number(refQuote.MKT_CAP), currency: '€', separator: 'M' });
      }

      if (refQuote?.ACVOL_1) {
        setEuroVol(euronextVolume);
      }
      // For the main informations
      if (currentPriceCopy) {
        if (refQuote.TRDPRC_1) {
          currentPriceCopy.price.current = tradePrice;
        }
        if (refQuote.PCTCHNG) {
          currentPriceCopy.price.variation.percent = percentChange;
        }
        if (refQuote.CF_NETCHNG) {
          currentPriceCopy.price.variation.volume = netPriceChange;
        }
        setEmitterCurrentPrice(currentPriceCopy);
      }

      // for the chart
      if (range === '1D' && showGraph && !filterActivate.pairs && !filterActivate.date) {
        const emitterChartDataCopy = _.cloneDeep(emitterChartData);
        const newQuoteData = {
          value,
          timestamp: date,
          close: value,
          mockedStartOfDay: false,
        };
        const newPrices = [...emitterChartDataCopy.price, newQuoteData];
        emitterChartDataCopy.price = newPrices;
        store.dispatch(getChartSuccess(emitterChartDataCopy));
      }
    }

    // 2. if we receive indices quote
    else {
      const indiceChartDataCopy = _.cloneDeep(indicesChartData);
      if (
        indiceChartDataCopy &&
        range === '1D' &&
        showGraph &&
        !filterActivate.pairs &&
        !filterActivate.date
      ) {
        // If it's a new price
        if (refQuote.TRDPRC_1) {
          const ricChartItem = indiceChartDataCopy.find(icd => refQuote.ric === icd.ticker_code);

          if (ricChartItem?.price) {
            const newPriceToChart = {
              value,
              timestamp: date,
              volume: actualVolume,
              close: value,
              mockedStartOfDay: false,
            };
            const newPrice = [...ricChartItem.price, newPriceToChart];
            ricChartItem.price = newPrice;
            const indexToRemove = indiceChartDataCopy.findIndex(
              icd => refQuote.ric === icd.ticker_code,
            );
            indiceChartDataCopy[indexToRemove] = ricChartItem;
            store.dispatch(getIndicesChartSuccess(indiceChartDataCopy));
          }
        }
      }

      if (currentPriceCopy && indicesChartData) {
        indicesChartData?.forEach((indice: IChart) => {
          if (refQuote.ric === indice.ticker_code) {
            const name = indice.ticker;
            const index = currentPriceCopy.indices.findIndex(indi => indi.name.includes(name));
            if (refQuote.PCTCHNG && currentPriceCopy.indices[index]?.variation)
              currentPriceCopy.indices[index].variation = percentChange;
            if (refQuote.ACVOL_1 && currentPriceCopy.indices[index]?.current)
              currentPriceCopy.indices[index].current = actualVolume;
            setEmitterCurrentPrice(currentPriceCopy);
          }
        });
      }
      return;
    }
  });
}

export default dispatchStream;
