import React, { useEffect, useState, useContext } from 'react';
import LineChart, { LineChartData, LineChartOptions } from 'components/Charts/LineChart';
import PortalLoader from 'components/Loader/PortalLoader';
import { useTranslation } from 'react-i18next';
import { StockDataGroup } from '../core/interfaces';
import { getMeasures } from '../actions';
import { AppContext } from 'contexts/AppContext';
import { ShortPositionFooter } from './ShortPositionFooter';

interface StockProps {
  stockData: StockDataGroup | null;
  loading?: boolean;
  id?: string;
  withoutTabs?: boolean;
  kind?: string;
  fullScreen?: boolean;
}

const option: LineChartOptions = {
  scales: {
    x: {
      type: 'timeseries',
      time: {
        tooltipFormat: 'dd/MM/yyyy',
      },
    },
    y: {
      ticks: {
        callback: val => {
          let valTemp = val;
          if (typeof val === 'number') {
            valTemp = val.toFixed(2);
          }
          return `${valTemp}%`;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => `${tooltipItem.raw}%`,
      },
    },
  },
};

function ShortStockChart({ stockData, loading, id, withoutTabs, kind, fullScreen }: StockProps) {
  const { t } = useTranslation();
  const [data, setData] = useState<LineChartData>({ datasets: [] });
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { measures } = getMeasures(isDesktopOrLaptop, fullScreen);

  useEffect(() => {
    if (stockData && stockData.data && stockData.data.length > 0) {
      const newdatasets: LineChartData = {
        labels: stockData.data.map(d => d.date),
        datasets: [
          {
            data: stockData.data.map(d => +d.value),
            fill: false,
            borderColor: '#001E41',
            backgroundColor: '#001E41',
            borderWidth: 2,
            tension: 0,
          },
        ],
      };
      setData(newdatasets);
    }
  }, [stockData]);

  if (loading) {
    return <PortalLoader />;
  }

  return (
    <div className="short__stock">
      <h2>{t('short:stock.title')}</h2>
      <div className="stockchart" style={{ ...measures }}>
        {!stockData || !stockData.data || stockData.data.length === 0 ? (
          <p>{t('common:p.noData')}</p>
        ) : (
          <LineChart
            data={data}
            options={option}
            className="stockchart-linechart"
            measures={measures}
          />
        )}
      </div>
      <ShortPositionFooter data={data} />
    </div>
  );
}

export default React.memo(ShortStockChart);
