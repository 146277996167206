/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { IEconomicStatistic } from '../core/interfaces';
import PortalLoader from 'components/Loader/PortalLoader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { format, parseISO } from 'date-fns';

function groupBy(array: Array<object>, key: string) {
  return array.reduce((result: object, currentValue: any) => {
    const resultGroup: any = result;
    (resultGroup[currentValue[key]] = resultGroup[currentValue[key]] || []).push(currentValue);
    return resultGroup;
  }, {});
}

function Statistic({ title, value }: { title: string; value: string }) {
  return (
    <div className="values__container-detail">
      <span className="title">{title}</span>
      <span className="value">{value}</span>
    </div>
  );
}

function EconomicStatistics() {
  const { t } = useTranslation();
  const [statisticsGroupedByZone, setStatisticsGroupedByZone] = useState<
    Array<[string, Array<IEconomicStatistic>]>
  >([]);
  const { data: economicStatistics, isLoading: isEconomicStatisticsLoading } = useSelector(
    (state: RootState) => state.report.economicStatistics,
  );

  useEffect(() => {
    if (economicStatistics.length > 0) {
      setStatisticsGroupedByZone(Object.entries(groupBy(economicStatistics, 'zone')));
    } else {
      setStatisticsGroupedByZone([]);
    }
  }, [economicStatistics]);

  if (isEconomicStatisticsLoading) {
    return <PortalLoader />;
  }

  const lastUpdate = (economicStatistics[0] && economicStatistics[0].updated_at) || null;

  return (
    <div className="economic-statistics">
      <h4 className="main-title">{t('stats:report.statistics.title')}</h4>
      <div>
        {statisticsGroupedByZone.length !== 0 ? (
          statisticsGroupedByZone.map(([zone, value], i: number) => {
            const countries = Object.entries(groupBy(value, 'country'));
            return countries.map(([country, data]) => (
              <div
                key={country}
                className={i % 2 === 0 ? 'zone__container even' : 'zone__container odd'}
              >
                <h4 className="zone-title">
                  {zone} / {country}
                </h4>
                {data.map((economicStatistic: IEconomicStatistic) => (
                  <div
                    key={`${economicStatistic.country}_${Math.random().toString(36).substr(2, 9)}`}
                    className="country__container"
                  >
                    <div className="header__container">
                      <span>{economicStatistic.object}</span>
                      <span>{`${economicStatistic.period} (${economicStatistic.released} CET)`}</span>
                    </div>
                    <hr />
                    <div className="values__container">
                      <Statistic title="ACTUAL" value={economicStatistic.actual} />
                      <Statistic title="EXPECTED" value={economicStatistic.expected} />
                      <Statistic title="PREVIOUS" value={economicStatistic.previous} />
                    </div>
                  </div>
                ))}
              </div>
            ));
          })
        ) : (
          <p className="no-data">{t('daily:report.noStatistics')}</p>
        )}
      </div>
      {lastUpdate ? (
        <p className="statistics__container-updated">
          Last update {format(parseISO(lastUpdate), 'do MMMM, K:mm b')}{' '}
        </p>
      ) : (
        ''
      )}
    </div>
  );
}

export default EconomicStatistics;
