import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Response } from 'utils/factset';
import { BloomData, IValorisationState } from './types';

/** **** SLICE */
export const initialState: IValorisationState = {
  valorisation: {
    isLoading: true,
    items: null,
    error: null,
  },
};

export const slice = createSlice({
  name: 'valorisation',
  initialState,
  reducers: {
    getValorisation: (state, action: PayloadAction<{ emitterName: string }>) => {
      state.valorisation.isLoading = true;
    },
    getValorisationSuccess: (
      state,
      action: PayloadAction<{
        backdata: BloomData;
        ratios: Response;
      }>,
    ) => {
      state.valorisation.items = action.payload;
      state.valorisation.isLoading = false;
    },
    getValorisationFailed: (state, action) => {
      state.valorisation.error = action.payload.error;
      state.valorisation.isLoading = false;
    },
  },
});

export const { getValorisation, getValorisationFailed, getValorisationSuccess } = slice.actions;

export default slice.reducer;
