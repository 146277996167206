/** * SAGAS */
import flashSaga from 'features/flashs/detail/core/saga';
import flashsSaga from 'features/flashs/home/core/saga';
import passwordSaga from 'features/user/password-change/core/saga';
import cgvSaga from 'features/user/cgv-cgu/core/saga';
import loginSaga from 'features/login/Login.saga';
import stockSaga from 'features/mkt-data/stock/core/saga';
import shortSaga from 'features/short/core/saga';
import sendEmailSaga from 'features/reset-password/send-email/core/saga';
import sendPasswordSaga from 'features/reset-password/send-password/core/saga';
import sectorSaga from 'features/mkt-data/sector/core/saga';
import valuationSaga from 'features/mkt-data/valuations/core/saga';
import brokersSaga from 'features/brokers-note/core/saga';
import consensusSaga from 'features/brokers-note/consensus/core/saga';
import valorisationSaga from 'features/brokers-note/valorisation/core/saga';
import reportSaga from 'features/daily-report/core/saga';
import searchSaga from 'features/search/core/saga';
import appSaga from './core/saga';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...flashsSaga,
    ...flashSaga,
    ...passwordSaga,
    ...cgvSaga,
    ...stockSaga,
    ...shortSaga,
    ...appSaga,
    ...sendEmailSaga,
    ...sendPasswordSaga,
    ...valorisationSaga,
    ...sectorSaga,
    ...valuationSaga,
    ...brokersSaga,
    ...consensusSaga,
    ...reportSaga,
    ...searchSaga,
  ]);
}
