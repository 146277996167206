import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import {
  getRecommendations,
  getRecommendationsSuccess,
  getRecommendationsFailed,
  getRecoTpBrokersSuccess,
  getRecoTpBrokersFailed,
  getRecoTpBrokers as getRecoTpBrokersAction,
} from './slice';
import { IRecommendations, IRecoTpBroker } from '../core/interfaces';

interface Payload {
  page: number;
}
interface Props {
  payload: Payload;
  type: string;
}

function* recommendations(props: Props) {
  const requestURL = `/api/broker-note?page=${props.payload.page}&sort=createdAt,DESC`;
  try {
    const result: IRecommendations[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getRecommendationsSuccess(result));
  } catch (err) {
    console.error('err', err);
    yield put(getRecommendationsFailed({ error: err.responseJSON }));
  }
}

function* getRecoTpBrokers() {
  const requestURL = '/api/reports/reco-tp-brokers';
  try {
    const result: IRecoTpBroker[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getRecoTpBrokersSuccess(result));
  } catch (err) {
    console.error('err', err.responseJSON);
    yield put(getRecoTpBrokersFailed({ error: err.responseJSON }));
  }
}

export default [
  takeEvery(getRecommendations.type, recommendations),
  takeLatest(getRecoTpBrokersAction.type, getRecoTpBrokers),
];
