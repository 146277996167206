import React, { useState, useEffect, useContext } from 'react';
import { RootState } from 'app/store';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from './core/slice';
import cx from 'classnames';
import './styles.scss';
import CustomIcon from 'components/CustomIcon';
import { AppContext } from 'contexts/AppContext';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/logo.svg';

interface ResultsProps {
  updated: boolean;
  error?: {
    status: number;
    message: string;
  } | null;
}

interface IForm {
  newPassword: string;
}

function ResultContent({ updated, error }: ResultsProps) {
  if (updated === false && !error) return null;
  let message;
  if (updated) {
    message = 'Updated';
  } else if (error && error.status === 400) {
    message = error.message;
  }
  return (
    <div
      className={cx('password-change__result', {
        result__error: error,
        result__ok: updated,
      })}
    >
      {message}
    </div>
  );
}

function PasswordChange() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState<boolean>(false);
  const { updated, clearForm, isLoading, error } = useSelector(
    (state: RootState) => state.password,
  );
  const passwordChangeRequired = useSelector(
    (state: RootState) => state.session.user.data?.passwordChangeRequired,
  );

  const newPasswordInput = watch('newPassword');

  useEffect(() => {
    if (clearForm) reset();
  }, [clearForm]);

  function onSubmit(data: IForm) {
    dispatch(setPassword({ newPassword: data.newPassword }));
  }

  return (
    <div className="app-container-wrapper background">
      <Logo className="login-logo" />
      <div className="app-container app-container__login">
        <div className="logo-full__container">
          <CustomIcon type="logo-full" />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={cx('login__container', {
            desktop: isDesktopOrLaptop,
          })}
        >
          {passwordChangeRequired ? (
            <div className="notice__message">
              Welcome! Please change your password after the first login.
            </div>
          ) : (
            ''
          )}
          <div className="login__input-container">
            {errors.newPassword && errors.newPassword.type === 'validate' ? (
              <p className="error" style={{ marginTop: '1rem' }}>
                {t('errors:password.validate')}
              </p>
            ) : (
              ''
            )}
            {errors.newPassword && errors.newPassword.type === 'minLength' ? (
              <p className="error" style={{ marginTop: '1rem' }}>
                {t('errors:password.length')}
              </p>
            ) : (
              ''
            )}
            <label htmlFor="newPassword">
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                className={error?.message || errors.newPassword ? 'error' : ''}
                placeholder=" "
                {...register('newPassword', {
                  required: true,
                  minLength: 8,
                  maxLength: 20,
                })}
              />
              <span>New Password</span>
              <button
                type="button"
                className="icon-input"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <CustomIcon type={showNewPassword ? 'show' : 'hide'} />
              </button>
            </label>
          </div>
          <div className="login__input-container">
            {errors.newPasswordConfirmation ? (
              <p className="error" style={{ marginTop: '1rem' }}>
                {t('errors:password.match')}
              </p>
            ) : (
              ''
            )}
            <label htmlFor="newPasswordConfirmation">
              <input
                type={showNewPasswordConfirmation ? 'text' : 'password'}
                id="newPasswordConfirmation"
                className={error?.message || errors.newPasswordConfirmation ? 'error' : ''}
                placeholder=" "
                {...register('newPasswordConfirmation', {
                  required: true,
                  validate: value => value === newPasswordInput,
                })}
              />
              <span>New Password Confirmation</span>
              <button
                type="button"
                className="icon-input"
                onClick={() => setShowNewPasswordConfirmation(!showNewPasswordConfirmation)}
              >
                <CustomIcon type={showNewPasswordConfirmation ? 'show' : 'hide'} />
              </button>
            </label>
          </div>
          {isLoading ? (
            <div className="isLoading isLoading__login_form">
              <Loader />
            </div>
          ) : (
            ''
          )}
          <ResultContent updated={updated} error={error} />
          <div className="password__button-container">
            <button disabled={!isValid} type="submit">
              Change password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordChange;
