import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { setPassword as setPasswordAction, setPasswordSuccess, setPasswordFailed } from './slice';
import { push } from 'redux-first-history';

interface PasswordPayload {
  newPassword: string;
  oldPassword: string;
}

function* setPassword(passwordPayload: PayloadAction<PasswordPayload>) {
  const requestURL = '/api/user/self/change-password';
  try {
    yield call(request, requestURL, { method: 'PUT', body: { ...passwordPayload.payload } });
    yield put(setPasswordSuccess());
    yield put(push('/'));
  } catch (err) {
    yield put(setPasswordFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(setPasswordAction.type, setPassword)];
