import { call, put, takeEvery } from 'redux-saga/effects';
import request from '../../../../utils/request';
import { parseISO } from 'date-fns'; // eslint-disable-line
import { WSFlash } from '../../interfaces';
import { getFlashs, getFlashsFailed, getFlashsSuccess } from './slice';

interface Payload {
  page: number;
}
interface Props {
  payload: Payload;
  type: string;
}

function* flashs(props: Props) {
  const requestURL = `/api/flash?page=${props.payload.page}&sort=createdAt,DESC`;
  try {
    const result: WSFlash[] = yield call(request, requestURL, { method: 'GET' });
    yield put(getFlashsSuccess(result));
  } catch (err) {
    console.error('err', err);
    yield put(getFlashsFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(getFlashs.type, flashs)];
