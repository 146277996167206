import React, { useState, useContext, useEffect, useRef } from 'react';
import { RootState } from 'app/store';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import Container from 'components/Container';
import { AppContext } from 'contexts/AppContext';
import { Button } from 'components/Button';
import Loader from 'components/Loader';
import { CardSearch } from './components/CardSearch';
import { SearchInput } from './components/SearchInput';
import { Filters } from './components/Filters';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearch, getSearch, setWord, setType } from './core/slice';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.scss';

function Search() {
  const dispatch = useDispatch();
  const page = useRef(0);
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { search, isLastPage, isLoading, word, type, badge } = useSelector(
    (state: RootState) => state.search,
  );

  const [showResults, setShowResults] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const loader = !isLastPage && isScrolled ? <Loader text="Loading more..." /> : null;

  const submitSearch = () => {
    dispatch(getSearch({ page: 0, word, type }));
    setShowResults(true);
    setDisabled(true);
  };

  const manageInput = (word: string) => {
    dispatch(setType({ type: 'ALL' })); // type by default
    setShowResults(false); // clear list when typing
    dispatch(setWord({ word })); // update the word
    setDisabled(false); // when typing then enable
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && word && !disabled) {
      submitSearch();
    }
  };

  const fetchMoreData = () => {
    !isLastPage && dispatch(getSearch({ page: ++page.current, word, type }));
    setIsScrolled(true);
    setDisabled(true);
  };

  const reset = () => {
    page.current = 0;
    setShowResults(false);
    dispatch(clearSearch());
  };

  useEffect(() => {
    reset(); // when changing the type we reset everything
    if (word === '' || !showResults) return;
    submitSearch();
  }, [type]);
  useEffect(() => {
    if (search && search.length > 0 && word && word.length > 0) {
      submitSearch();
    }
  }, []);

  return (
    <Container title="Search" icon="search-white">
      <Helmet>
        <title>RMM - Search</title>
      </Helmet>
      <div
        className={cx('search__content', {
          flashs__desktop: isDesktopOrLaptop,
        })}
      >
        <SearchInput callback={manageInput} onKeyDown={handleKeyDown} value={word} />
        <Button onClick={submitSearch} disabled={!word || disabled}>
          Search
        </Button>
        {showResults && (
          <>
            <div className="search-results">{`${
              badge.flash + badge.report
            } results for "${word}"`}</div>
            <Filters
              callback={(type: string) => dispatch(setType(type))}
              selected={type}
              badge={badge}
            />
            {/* First loading before scrolling */}
            {isLoading && !isScrolled && <Loader text="Loading..." />}
            <InfiniteScroll
              dataLength={search.length}
              next={fetchMoreData}
              hasMore={true}
              loader={loader}
            >
              {search.map(item => {
                return <CardSearch key={item.id} {...item}></CardSearch>;
              })}
            </InfiniteScroll>
          </>
        )}
      </div>
    </Container>
  );
}

export default Search;
