import React from 'react';
import cx from 'classnames';
import './styles.scss';
import { numbersSpaceSeparator, getLevel, TypeEnum } from 'utils/data';
import CustomIcon from 'components/CustomIcon';

interface Props {
  value: string;
  type: TypeEnum;
  extraText?: string;
  className?: string;
  isVariation?: boolean;
  hasArrow?: boolean;
}

function QuoteValue({ value, type, extraText, className, hasArrow, isVariation = true }: Props) {
  let variation = value;
  const hasPercent = value.indexOf('%') !== -1;
  if (hasPercent) {
    variation = value.replace(/%/, '');
  }
  const isUpper = +variation > 0;

  const style = getLevel(+variation, type);
  let text = '0,00%';
  if (+variation && +variation !== null) {
    text = `${+variation > 0 && isVariation ? '+' : ''}${numbersSpaceSeparator(+variation)}${
      isVariation ? '%' : ''
    } ${extraText || ''}`;
  }
  const isZero = text === '0,00%';
  const showArrow = typeof hasArrow !== 'undefined' && hasArrow && !isZero;

  return (
    <span className={cx('quotevalue', className)} style={{ color: `var(${style})` }}>
      {showArrow && (
        <CustomIcon
          className={`quote-arrow ${isUpper ? 'up' : 'down'}`}
          type={isUpper ? 'up' : 'down'}
        />
      )}
      {type !== 'mktCap' ? text : value !== 'null' ? `${Number(value) / 1000}k` : 'N/A'}
    </span>
  );
}

export default QuoteValue;
