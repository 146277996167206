/* eslint-disable react/no-danger */
/* eslint-disable import/no-duplicates */
import { RootState } from 'app/store';
import cx from 'classnames';
import CustomIcon from 'components/CustomIcon';
import PortalLoader from 'components/Loader/PortalLoader';
import RefreshPortal from 'components/RefreshPortal';
import { AppContext } from 'contexts/AppContext';
import { format, formatRelative, isAfter, isToday, parseISO, startOfDay } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import Indices from 'features/mkt-data/stock/components/Indices';
import Price from 'features/mkt-data/stock/components/Price';
import { getChart } from 'features/mkt-data/stock/core/slice';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketHolidays, isSessionOpened } from 'utils/data';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from 'utils/localisation';
import Container from '../../components/Container';
import './assets/styles.scss';
import Agenda from './components/Agenda';
import Analysis from './components/Analysis';
import EconomicStatistics from './components/EconomicStatistics';
import { getAgenda, getAnalysis, getEconomicStatistics, getQuotes } from './core/slice';
import { getLists } from '../mkt-data/sector/core/slice';
import List from '../mkt-data/sector/components/List';
import { useParams } from 'react-router-dom';
import GetStoreData from '../mkt-data/stock/tools/getStoreData';
import TradedVolumes from 'features/daily-report/components/TradedVolumes';
import MostActiveBrokers from 'features/daily-report/components/MostActiveBrokers';
import MultiStockChart from 'components/StockChart/MultiStockChart';
import { setRangeFilter } from '../mkt-data/stock/core/slice';
import FullScreenChart from 'components/FullScreenChart';

import {
  getTradedVolumes,
  getMostActiveBrokers,
  getVolumesComparision,
  getPairInfo,
} from '../mkt-data/stock/core/slice';
const intervals = ['1D', '1M', 'YTD', '1Y', '5Y'];

function DailyReport() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const dateParam = Object.values(useParams())[0];
  const [filterActivate, setFilterActivate] = useState({ date: false, pairs: false });
  const [zoom, setZoom] = useState({ stockZoom: false, volumeZoom: false });
  const { range: currentRange } = useSelector((state: RootState) => state.stock.filter);
  const { isDesktopOrLaptop } = useContext(AppContext);
  const [snapshot, setSnapshot] = useState<string>('');

  const { isTabletOrMobileDevice } = useContext(AppContext);
  const { data: analysis, isLoading: isAnalysisLoading } = useSelector(
    (state: RootState) => state.report.analysis,
  );
  const { data: user } = useSelector((state: RootState) => state.session.user);
  const { data: quotesData, isLoading: isQuoteDataLoading } = useSelector(
    (state: RootState) => state.report.quotes,
  );
  const { items: lists, isLoading: areListsLoading } = useSelector(
    (state: RootState) => state.sector.lists,
  );
  const peerList = useMemo(() => lists?.find(item => item.name === 'x_daily_report_x'), [lists]);
  const [date, setDate] = useState<Date>();
  const [currency, setCurrency] = useState<string>('EUR');
  const [holidays, setHolidays] = useState<Array<Date>>([]);
  const fallback = i18n.options.fallbackLng ? i18n.options.fallbackLng[0] : 'en';

  const { tradedVolumes, isTradedVolumesLoading, mostActiveBrokers, isMostActiveBrokersLoading } =
    GetStoreData();

  function dispatchAll() {
    dispatch(getTradedVolumes());
    dispatch(getMostActiveBrokers());
    dispatch(getLists('Today'));
  }

  useEffect(() => {
    dispatchAll();
  }, []);

  useEffect(() => {
    if (lists?.length) {
      const snapshotDate = lists.reduce(
        (p1, curr) => p1 || curr.tickers.find(p => p.isSnapshot === true)?.createdAt || '',
        '',
      );
      setSnapshot(snapshotDate);
    }
  }, [lists]);

  const formatRelativeLocale = {
    lastWeek: `${t('dates:last')} (d LLLL yyyy)`,
    yesterday: `'${t('dates:yesterday')}' (EEEE d LLLL yyyy)`,
    today: `'${t('dates:today')}' (EEEE d LLLL yyyy)`,
    tomorrow: `'${t('dates:tomorrow')}'`,
    nextWeek: `'${t('dates:next')}' eeee`,
    other: 'EEEE d LLLL yyyy',
  };

  const locale = {
    ...enGB,
    formatRelative: (token: string) => formatRelativeLocale[token],
  };

  const FORMAT = 'dd/mm/yyyy';

  const now = new Date();

  function fetchDatas() {
    if (date) {
      const getDate = format(date, 'yyyy-MM-dd');
      dispatch(getEconomicStatistics(getDate));
      dispatch(getAnalysis(getDate));
      dispatch(getQuotes(getDate));
      dispatch(getAgenda(getDate));
      dispatch(getChart({ range: '1D' }));
    }
  }

  useEffect(() => {
    dispatch(getAnalysis(null));
    getYearHolidays(now);
    dispatch(getChart({ range: '1D' }));
    dispatch(getVolumesComparision('1D'));
    dispatch(getPairInfo());
  }, []);

  useEffect(() => {
    if (analysis && analysis.updated_at && !date) {
      setDate(parseISO(analysis.updated_at));
    }
  }, [analysis]);

  useEffect(() => {
    fetchDatas();
  }, [date]);

  useEffect(() => {
    if (quotesData && quotesData.price && quotesData?.price.currency) {
      setCurrency(quotesData.price.currency);
    }
  }, [quotesData]);

  useEffect(() => {
    if (dateParam) {
      setDate(new Date(dateParam));
    }
  }, [dateParam]);

  function closeAction() {
    setZoom({ stockZoom: false, volumeZoom: false });
  }

  function getYearHolidays(calendarDate: Date) {
    const year = calendarDate.getFullYear();
    const yearHolidays = getMarketHolidays(year);
    // Map on date, and filter the one which are partially open (24/12 -> 14:30)
    const dates = yearHolidays
      .map(day => new Date(parseISO(day.date)))
      .filter(d => !isAfter(d, startOfDay(d)));
    setHolidays(dates);
  }

  const TradedComponent = useMemo(() => {
    return (
      <div>
        <h4>{t('stats:report.volumes.title')}</h4>
        <TradedVolumes volumes={tradedVolumes} loading={isTradedVolumesLoading} />
      </div>
    );
  }, [tradedVolumes, isTradedVolumesLoading]);

  const MostActiveComponent = useMemo(() => {
    return (
      <div>
        <h4>{t('stats:report.brokers.title')}</h4>
        <MostActiveBrokers brokers={mostActiveBrokers} loading={isMostActiveBrokersLoading} />
      </div>
    );
  }, [mostActiveBrokers, isMostActiveBrokersLoading]);

  const PeerList = () => {
    if (!peerList) return null;
    const newPeerList = { ...peerList, name: 'Peers' };
    return (
      <List
        list={newPeerList}
        isLast={true}
        loading={areListsLoading}
        key={newPeerList.id}
        snapshot={snapshot}
        emitterName={user?.emitter?.name}
        timeIntrvl={'Today'}
        isMobile={!isDesktopOrLaptop}
      />
    );
  };
  const MultiStockComponent = () => {
    return (
      <MultiStockChart
        emitterName={user!.emitter.name}
        setFilterActivate={setFilterActivate}
        filterActivate={filterActivate}
        legend={intervals.map(i => ({ title: i }))}
        callback={() => {
          setZoom({ stockZoom: true, volumeZoom: false });
        }}
        range={currentRange}
        setRange={(type: string) => dispatch(setRangeFilter({ range: type }))}
      />
    );
  };

  if (!date) {
    return <PortalLoader />;
  }

  return (
    <Container title={t('daily:p.title')} icon="daily-report-white">
      <Helmet>
        <title>RMM - Daily report</title>
      </Helmet>
      <RefreshPortal action={() => fetchDatas()} />
      <div className={isTabletOrMobileDevice ? 'report__page mobile' : 'report__page desktop'}>
        <div className="daypicker__container">
          <DayPickerInput
            value={formatRelative(date, new Date(), { locale, weekStartsOn: 0 })}
            format={FORMAT}
            onDayChange={setDate}
            component={(props: React.InputHTMLAttributes<HTMLInputElement>) => (
              <>
                <CustomIcon type="date" />
                <input
                  {...props}
                  style={{
                    width: formatRelative(date, new Date(), { locale, weekStartsOn: 0 }).length * 8,
                  }}
                />
                <div className="arrow-down" />
              </>
            )}
            dayPickerProps={{
              firstDayOfWeek: 1,
              locale: fallback,
              weekdaysLong: WEEKDAYS_LONG[fallback],
              weekdaysShort: WEEKDAYS_SHORT[fallback],
              months: MONTHS[fallback],
              selectedDays: date || new Date(),
              onMonthChange: (month: Date) => getYearHolidays(month),
              modifiers: {
                disabled: [
                  ...holidays,
                  {
                    daysOfWeek: [0, 6],
                  },
                  {
                    after: new Date(),
                  },
                ],
              },
            }}
          />
        </div>
        {!isQuoteDataLoading && quotesData && user?.emitter.name && date ? (
          <div className="quotes__container">
            <Indices
              closed={!isToday(date) || !isSessionOpened()}
              indices={quotesData.indices.filter(i => i.name !== quotesData.name)}
            />
            <div
              className={cx('price-info', {
                desktop: !isTabletOrMobileDevice,
              })}
            >
              {user ? (
                <Price
                  closed
                  updatedAt={
                    analysis?.updated_at
                      ? format(new Date(parseISO(analysis.updated_at)), 'yyyy-MM-dd')
                      : format(date, 'yyyy-MM-dd')
                  }
                  price={quotesData.price}
                  name={user.emitter.name}
                  currency={currency}
                  previousClose={quotesData.previousClose}
                  date={date}
                  loading={false}
                />
              ) : null}
            </div>
          </div>
        ) : (
          ''
        )}
        {isTabletOrMobileDevice ? (
          <div className="data__container">
            <Analysis analysis={analysis} isAnalysisLoading={isAnalysisLoading} />
            {!zoom.stockZoom ? (
              <MultiStockComponent />
            ) : (
              <FullScreenChart closeAction={closeAction} rotate={!isDesktopOrLaptop}>
                <MultiStockComponent />
              </FullScreenChart>
            )}
            {TradedComponent}
            {MostActiveComponent}
            <PeerList />
            <EconomicStatistics />
            <Agenda />
          </div>
        ) : (
          <div className="data__container">
            <Analysis analysis={analysis} isAnalysisLoading={isAnalysisLoading} />
            <div className="data__container-right__column">
              {!zoom.stockZoom ? (
                <MultiStockComponent />
              ) : (
                <FullScreenChart closeAction={closeAction} rotate={!isDesktopOrLaptop}>
                  <MultiStockComponent />
                </FullScreenChart>
              )}
              {TradedComponent}
              {MostActiveComponent}
              <PeerList />
              <EconomicStatistics />
              <Agenda />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default DailyReport;
