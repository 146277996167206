import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';

interface Props {}

function Detail(props: Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <p>{t('inbox:p.welcome')}</p>
    </Container>
  );
}

export default Detail;
