import RequireAuth from 'app/RequireAuth';
import cx from 'classnames';
import Container from 'components/Container';
import { AppContext } from 'contexts/AppContext';
import React, { useContext } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Consensus from './consensus';
import Note from './note';
import './assets/styles.scss';
import Valorisation from './valorisation';

function BrokersNote() {
  const classes = 'subheader__element';
  const { isDesktopOrLaptop } = useContext(AppContext);
  const location = useLocation();

  return (
    <Container title="Cons./Brokers" icon="broker-white">
      <div
        className={cx('subheader', {
          desktop: isDesktopOrLaptop,
        })}
      >
        <Link
          to="/brokers-note"
          className={cx(classes, {
            'subheader__element--active': location.pathname === '/brokers-note',
          })}
        >
          Consensus
          <div className="arrow-down" />
        </Link>
        <Link
          to="/brokers-note/notes"
          className={cx(classes, {
            'subheader__element--active': location.pathname === '/brokers-note/notes',
          })}
        >
          Notes
          <div className="arrow-down" />
        </Link>
        <Link
          to="/brokers-note/valorisation"
          className={cx(classes, {
            'subheader__element--active': location.pathname === '/brokers-note/valorisation',
          })}
        >
          Valuation
          <div className="arrow-down" />
        </Link>
      </div>

      <Routes>
        <Route
          path={`/`}
          element={
            <RequireAuth>
              <Consensus />
            </RequireAuth>
          }
        />
        <Route
          path={`/notes`}
          element={
            <RequireAuth>
              <Note />
            </RequireAuth>
          }
        />
        <Route
          path={`/valorisation`}
          element={
            <RequireAuth>
              <Valorisation />
            </RequireAuth>
          }
        />
      </Routes>
    </Container>
  );
}

export default BrokersNote;
