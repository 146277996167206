export const marketsHolidays = [
  {
    name: ['XMAD', 'MCE'],
    country: 'Spain',
    country_code: 'ES',
    flag: '🇪🇸',
    region: 'Europe',
    timezone: 'Europe/Madrid',
    timezone_abbr: 'CET',
    utc_offset: '+0100',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '09:00',
    close_time: '17:30',
    early_close_time: '14:00',
    holiday_rules: ['01-01', 'easter -2', 'easter', '05-01', '12-25'],
  },
  {
    name: ['XCBF', 'CMES', 'IEPA', 'NYQ', 'XNYS', 'NYS'],
    country: 'United States',
    country_code: 'US',
    flag: '🇺🇸',
    region: 'North America',
    timezone: 'America/Chicago',
    timezone_abbr: 'CST',
    utc_offset: '-0600',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '08:30',
    close_time: '15:15',
    holiday_rules: [
      '01-01 and if sunday then next monday if saturday then previous friday',
      '3rd monday in January',
      '3rd monday in February',
      'easter -2',
      'monday before 06-01',
      '06-19 and if sunday then next monday if saturday then previous friday since 2021',
      '07-04 and if sunday then next monday if saturday then previous friday',
      '1st monday in September',
      '4th thursday in November',
      '12-25 and if sunday then next monday if saturday then previous friday',
    ],
  },
  {
    name: ['XETR', 'XFRA'],
    country: 'Germany',
    country_code: 'DE',
    flag: '🇩🇪',
    region: 'Europe',
    timezone: 'CET',
    timezone_abbr: 'CET',
    utc_offset: '+0100',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '09:00',
    close_time: '17:30',
    early_close_time: '14:00',
    holiday_rules: [
      '01-01',
      'easter -2',
      'easter',
      '05-01',
      'easter 50',
      '10-03',
      '12-25',
      '12-31 14:00 if sunday then 00:00',
    ],
  },
  {
    name: ['XBRU'],
    country: 'Belgium',
    country_code: 'BE',
    flag: '🇧🇪',
    region: 'Europe',
    timezone: 'Europe/Brussels',
    timezone_abbr: 'CET',
    utc_offset: '+0100',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '09:00',
    close_time: '17:30',
    early_close_time: '14:05',
    holiday_rules: ['01-01', 'easter -2', 'easter', '05-01', '12-25'],
  },
  {
    name: ['XPAR', 'PAR'],
    country: 'France',
    country_code: 'FR',
    flag: '🇫🇷',
    region: 'Europe',
    timezone: 'Europe/Paris',
    timezone_abbr: 'CET',
    utc_offset: '+0100',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '09:00',
    close_time: '17:30',
    early_close_time: '14:05',
    holiday_rules: ['01-01', 'easter -2', 'easter', '05-01', '12-25'],
  },
  {
    name: ['XLON', 'LSE'],
    country: 'United Kingdom',
    country_code: 'GB',
    flag: '🇬🇧',
    region: 'Europe',
    timezone: 'Europe/London',
    timezone_abbr: 'GMT',
    utc_offset: '+0000',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    open_time: '08:00',
    close_time: '16:30',
    early_close_time: '12:30',
    holiday_rules: [
      '01-01',
      'substitutes 01-01 if saturday then next monday',
      'substitutes 12-25 if saturday then next monday if sunday then next tuesday',
      'easter -2',
      'easter',
      '1st monday in May',
      '1st monday before 06-01',
      '12-26',
      '12-25',
      'substitutes 12-25 if saturday then next monday if sunday then next tuesday',
    ],
  },
];
