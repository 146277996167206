import { call, put, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { setCgvCgu as setCgvCguAction, setCgvCguSuccess, setCgvCguFailed } from './slice';
import { push } from 'redux-first-history';

function* setCgvCgu() {
  const requestURL = '/api/user/self/cgu';
  try {
    yield call(request, requestURL, { method: 'PUT' });
    yield put(setCgvCguSuccess());
    yield put(push('/'));
  } catch (err) {
    yield put(setCgvCguFailed({ error: err.responseJSON }));
  }
}

export default [takeEvery(setCgvCguAction.type, setCgvCgu)];
