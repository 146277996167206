import React from 'react';
import CustomIcon from 'components/CustomIcon';
import { User } from 'app/core/interfaces';

interface Props {
  onHide: () => void;
  user: User | null;
  onSignOut: () => void;
}

function UserModale({ onHide, onSignOut, user }: Props) {
  return (
    <>
      <div className="user-modal" onClick={onHide} role="presentation" />
      <div className="user-content">
        <div className="user" role="button" tabIndex={0} onClick={onHide} onKeyDown={onHide}>
          {user ? (
            <>
              <div className="user-top">
                <CustomIcon type="user" />{' '}
                <span className="name">
                  {user.firstname} {user.lastname}
                </span>
                <div className="arrow-up" />
              </div>
              <p className="role">{user.jobFunction}</p>
            </>
          ) : (
            ''
          )}
        </div>
        <hr />
        {user ? (
          <>
            <p className="email">{user.email}</p>
            <p className="phone">{user.phone}</p>
          </>
        ) : (
          ''
        )}
        <button onClick={onSignOut} className="user-modal-btn" type="button">
          Sign-out
        </button>
      </div>
    </>
  );
}

export default UserModale;
