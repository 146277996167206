/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useContext } from 'react';
import './styles.scss';
import { VolumesComparisionProps, Legend } from './interfaces';
import BtnLegend from './BtnLegend';
import Loader from 'components/Loader';
import BarChart, { BarChartData, BarChartOptions } from 'components/Charts/BarChart';
import { getMeasuresVolumes } from './actions';
import { AppContext } from 'contexts/AppContext';
import { sortBy } from 'lodash';

const yellow = '#ffcd00';
const blue = '#001E41';

const options: BarChartOptions = {
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
      beginAtZero: true,
      ticks: {
        callback: value => `${value}%`,
        padding: 12,
      },
    },
    x: {
      type: 'timeseries',
      time: {
        unit: 'minute',
        stepSize: 30,
      },
      ticks: {
        callback(value) {
          const label = `${value}`.split(':');
          if (label.length > 1) return `${label[0]}h${label[1]}`;
          return value;
        },
        font: {
          family: 'Montserrat',
        },
        color: '#1d3041',
        backdropColor: '#FFF',
      },
      grid: {
        display: true,
        drawOnChartArea: true,
        drawBorder: true,
        borderDash: [3, 1],
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      align: 'start',
      fullSize: true,
      position: 'top',
      labels: {
        boxWidth: 12,
        font: {
          family: 'Montserrat',
        },
      },
    },
  },
};

function VolumesChart({
  onLegendClick,
  chartData,
  loading,
  legend,
  id,
  withoutTabs,
  emitterName,
  fullScreen = false,
}: VolumesComparisionProps) {
  const [currentLegend, setCurrentLegend] = useState<string>(legend[0].title);
  const [datasets, setDatasets] = useState<BarChartData>({ datasets: [] });
  const { isDesktopOrLaptop } = useContext(AppContext);
  const { measures } = getMeasuresVolumes(isDesktopOrLaptop, fullScreen);

  useEffect(() => {
    if (chartData && chartData.length) {
      const newdatasets: BarChartData = {
        labels: sortBy(chartData[0].volumes, 'timestamp').map(d => d.timestamp),
        datasets: chartData.map(volume => ({
          borderColor: volume.ticker === emitterName ? blue : yellow,
          backgroundColor: volume.ticker === emitterName ? blue : yellow,
          data: sortBy(volume.volumes, 'timestamp').map(d => +d.percent),
          label: volume.ticker,
        })),
      };
      setDatasets(newdatasets);
    }
  }, [chartData]);

  if (!chartData || chartData.length === 0) {
    return (
      <div className="stockchart volumeschart">
        <div className="stockchart__legend">
          <span className="stockchart__title">{currentLegend}</span>
        </div>
        <p className="no-data">No data provided</p>
      </div>
    );
  }

  function onInnerLegendClick(legendTitle: string) {
    setCurrentLegend(legendTitle);
    onLegendClick(legendTitle);
  }
  if (loading) {
    return (
      <div className="stockchart volumeschart">
        <Loader />
      </div>
    );
  }
  return (
    <div className="stockchart volumeschart">
      <div className="stockchart__legend">
        {legend.map((l: Legend) =>
          withoutTabs ? (
            <span className="stockchart__title" key={l.title}>
              {l.title}
            </span>
          ) : (
            <BtnLegend
              key={l.title}
              label={l.title}
              selected={currentLegend === l.title}
              onClick={() => onInnerLegendClick(l.title)}
            />
          ),
        )}
      </div>
      <BarChart
        id="my-bar-chart-volumes"
        options={options}
        data={datasets}
        measures={{ ...measures }}
      />
    </div>
  );
}

export default React.memo(VolumesChart);
