import { RootState } from 'app/store';
import cx from 'classnames';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './LoginSlice';
import ErrorsContent from 'components/FormErrors';
import './styles.scss';
import CustomIcon from 'components/CustomIcon';
import { AppContext } from 'contexts/AppContext';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
// import { NativeBiometric } from 'capacitor-native-biometric';
import { Helmet } from 'react-helmet';

interface IForm {
  email: string;
  password: string;
}

function Login() {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });

  const { isDesktopOrLaptop } = useContext(AppContext);
  const { isLoading, error } = useSelector((state: RootState) => state.login.login);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showKeyboard, setShowKeyboard] = useState<number>(0);
  const dispatch = useDispatch();
  let vid: HTMLVideoElement | null = null;

  App.addListener('appStateChange', state => {
    if (state.isActive && vid) {
      vid.play();
    }
  });

  if (Keyboard && Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      setShowKeyboard(info.keyboardHeight);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      setShowKeyboard(0);
    });
  }

  useEffect(() => {
    SplashScreen.hide();
    vid = document.querySelector('video');
    if (vid) {
      vid.play();
    }
  }, []);

  // // First time, prompt and later login because not credentials yet stored
  // // Second time, promt and autologin because credentials are already stored
  // async function performBiometricVerificatin() {
  //   const result = await NativeBiometric.isAvailable();
  //   if (!result.isAvailable) {
  //     console.log('Not available for FACE ID');
  //     return;
  //   }
  //   // const isFaceID = result.biometryType === BiometryType.FACE_ID;
  //   // Mobile handle the biometricType to be used
  //   const isVerified = await NativeBiometric.verifyIdentity({
  //     reason: 'Log in',
  //     title: 'Log in',
  //     subtitle: 'Subtitle',
  //     description: 'Some description',
  //     maxAttempts: 5,
  //     useFallback: true,
  //     negativeButtonText: 'Cancel',
  //   })
  //     .then(() => true)
  //     .catch(() => false);

  //   if (!isVerified) {
  //     console.log('Identity Not Verified !');
  //     return;
  //   }
  //   const credentials = await NativeBiometric.getCredentials({
  //     server: 'www.example.com',
  //   });
  //   if (credentials) {
  //     const { username: email, password } = credentials;
  //     dispatch(login({ email, password }));
  //   }
  // }

  // useEffect(() => {
  //   if (localStorage.getItem('allowBiometricAuth') === 'true') {
  //     performBiometricVerificatin();
  //   }
  // }, []);

  function onSubmit(data: IForm) {
    const { email, password } = data;
    dispatch(login({ email, password }));
  }

  return (
    <>
      <Helmet>
        <title>RMM - Login Page</title>
      </Helmet>
      <div className="login-container app-container-wrapper">
        <video webkit-playsinline playsInline muted id="myVideo" poster="/video_poster.jpg">
          <source src="/video_desktop.mp4" type="video/mp4" />
          <source src="/video_desktop.webm" type="video/webm" />
        </video>
        <div className="app-container app-container__login">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={cx('login__container', {
              desktop: isDesktopOrLaptop,
            })}
            style={
              showKeyboard > 0
                ? { paddingTop: `${showKeyboard / 1.5}px`, paddingBottom: `${showKeyboard / 3}px` }
                : {}
            }
            noValidate
          >
            <div className="title__container" />
            <div className="login__input-container">
              <label htmlFor="email">
                <input
                  id="email"
                  type="email"
                  placeholder=" "
                  {...register('email', {
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
                <span>Email address</span>
              </label>
            </div>
            <div className="login__input-container">
              <label htmlFor="password">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className=""
                  placeholder=" "
                  {...register('password', { required: true, minLength: 6, maxLength: 20 })}
                />
                <span>Password</span>
                <button
                  type="button"
                  className="icon-input"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <CustomIcon type={showPassword ? 'show' : 'hide'} />
                </button>
              </label>
            </div>
            <ErrorsContent error={error} />
            <div className="login__forgotten">
              <Link to="/reset-password">Forgotten?</Link>
            </div>
            <div className="login__button-container">
              <button disabled={!formState.isValid} type="submit">
                {' '}
                {isLoading ? 'Loading...' : 'Sign-In'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
